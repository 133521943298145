/*eslint-disable eqeqeq*/
import React from "react";
import AssetDownloadReportList from "./assetDownloadReportList";
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import * as AppConstants from "../../../../config/AppConstant";

class AssetDownloadReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 250, 500, 1000, "All"],
        list: [],
        from: 0,
        to: 50,
        downloadlist: [],
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
    };

    componentDidMount() {
        this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", this.state.currentPage, this.state.rowsPerPage);
    }

    wsAssetDownloadReport(from, to, DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, CurrentPage, RecordPerPage) {
        this.setState({ progress: true, });
        APIController.AssetDownloadReport("1", DownloadedAt, "", ShipToNo, "", PracticeName,
            "", "", "", "", "", "", "", "", "", "", "", "", "", "",
            VeevaCode, "", "", "", "", FullName, AssetCategory, AssetName, CSRFirstName, CSRLastName, CurrentPage, RecordPerPage).then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    let downloaddata = []
                    response.object.data.map((item) =>
                        AppConstants.GetMainClassName() == "app-main" ? downloaddata.push({
                            // DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode
                            "Dowloaded at": item.DownloadedAt, "User": item.FullName, "Practice Name": item.PracticeName, "Ship To No": item.ShipToNo,
                            "Asset Category": item.AssetCategory, "Asset Name": item.AssetName, "VTM First Name": item.CSRFirstName,
                            "VTM Last Name": item.CSRLastName, "Veeva Code": item.VeevaCode
                        }) : downloaddata.push({
                            "Dowloaded at": item.DownloadedAt, "Coontact Name": item.FullName, "Practice Name": item.PracticeName, "Netsuite ID": item.ShipToNo,
                            "Asset Category": item.AssetCategory, "Asset Name": item.AssetName, "CSR First Name": item.CSRFirstName,
                            "CSR Last Name": item.CSRLastName
                        })
                    )
                    this.setState({
                        downloadlist: downloaddata,
                        to: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ list: [], progress: false }) }
            });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>

                    <div className='button-field button-field-country'>
                        <CsvDownload data={this.state.downloadlist} filename={AppConstants.GetMainClassName() == "app-main" ? "REACH Asset Download Report.csv" : "Yomi Download Report.csv"}>Download to CSV</CsvDownload>
                    </div>

                    <AssetDownloadReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        handleApplyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                    {/*  */}
                </section>
            </>
        );
    }

    handleRowsPerPageChange = (value, DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsAssetDownloadReport(from, to, DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value);
    }

    handlePageChange = (values, DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsAssetDownloadReport(from, to, DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, Number(values), this.state.rowsPerPage);
    }

    handleApplyFilter = (DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode) => {
        this.wsAssetDownloadReport(this.state.from, this.state.to, DownloadedAt, FullName, PracticeName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, this.state.currentPage, this.state.rowsPerPage);
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default AssetDownloadReport;

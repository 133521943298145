import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import "font-awesome/css/font-awesome.css";
import { Doughnut } from "react-chartjs-2";
import CountUp from "react-countup";
import themeStyles from "../analytics/components/doughnut-chart-widget/doughnut-chart-widget.theme.style";
import scss from "../analytics/components/doughnut-chart-widget/doughnut-chart-widget.module.scss";
import styles from "./SocialDonutChartStyle";
import { CircularProgress } from "@material-ui/core";
import './DashboardStyle.css';

function shadeColor(color, percent) {
  /* eslint no-bitwise: ["error", { "allow": [">>", "&"] }] */
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  ); // eslint-disable-line
}

class SocialDonutChart extends React.Component {
  state = {

    intervalId: null,
    doughnutChartData: {
      labels: ["Desktop", "Tablet", "Mobile"],
      datasets: [
        {
          data: [124, 88, 172],
          borderWidth: 0,
          backgroundColor: [
            "#1588ba",
            shadeColor("#1588ba", -1 * 0.1),
            shadeColor("#1588ba", -3 * 0.1),
          ],
          hoverBackgroundColor: "red",
        },
      ],
    },
    doughnutChartOptions: {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        enabled: true,
        backgroundColor: "#1588ba",
        titleFontColor: "#fff",
        bodyFontColor: "#fff",
        xPadding: 20,
        yPadding: 20,
        displayColors: false,
      },
    },
  };


  render() {
    const { classes, data } = this.props;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container justify="flex-start" >
          {this.props.IsLoadingFbInst && this.props.IsLoadingGoogle ?
            <>
              {this.props.data.map((obj, index) => {
                return (
                  <>
                    <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4}>
                      {index == 0 ?
                        <Paper style={{ padding: "20px", margin: '5px' }}>
                          <Typography className={'title-main'} variant="subheading">{'Campaign Budget Spent'}</Typography>
                          <Grid style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                          <Grid
                            container
                            direction={"column"}
                            style={{
                              width: '100%',
                              height: '165px',
                              justifyContent: "center",
                              alignItems: "center",
                            }}  >
                            <CircularProgress style={{ color: '#0a5c7f' }} />

                          </Grid>
                        </Paper> :
                        <Paper style={{ padding: "20px", margin: '5px' }}>
                          <Typography className={'title-main'} variant="subheading">{obj.Title}</Typography>
                          <Grid style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                          <Grid
                            container
                            direction={"column"}
                            style={{
                              width: '100%',
                              height: '165px',
                              justifyContent: "center",
                              alignItems: "center",
                            }}  >
                            <CircularProgress style={{ color: '#0a5c7f' }} />
                          </Grid>
                        </Paper>
                      }
                    </Grid></>
                )
              })}
            </>

            :
            <>
              {this.props.FinalSocialReports.map((obj, index) => {
                return (
                  <>
                    <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4} >
                      {index == 0 ?
                        <Paper style={{ padding: "20px", margin: '5px' }}>
                          <Typography className={'title-main'} variant="subheading">{'Campaign Budget Spent'}</Typography>
                          <Grid style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                          <Grid
                            container
                            direction={"column"}
                            style={{
                              width: '100%',
                              height: '165px',
                              justifyContent: "center",
                              alignItems: "center",
                            }}  >
                            <Doughnut
                              height={70}
                              data={{
                                datasets: [
                                  {
                                    weight: 0.5,
                                    data: [this.props.TotalCost, this.props.TotalAmount],
                                    borderWidth: 0,
                                    backgroundColor: ["#02ABAE", '#EAF2FD'],
                                    hoverBackgroundColor: "#52ABAE",
                                  },
                                ],
                              }}
                              options={{
                                weight: 0.5,
                                rotation: 1 * Math.PI,
                                circumference: 1 * Math.PI,
                                legend: { display: false, position: "bottom" },
                                datalabels: {
                                  display: false,
                                  color: "white"
                                },
                                tooltips: {
                                  backgroundColor: "#5a6e7f",
                                },
                              }}
                            />
                            <Grid container style={{ alignItems: 'center', justifyContent: 'space-between', width: '82%' }}>
                              <Grid container direction={'row'} style={{ width: '25%', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{fontSize:'15px'}} className={'title-content'} >{'$' + this.props.TotalCost*(1.3)}</Typography>
                              </Grid>
                              <Grid container direction={'row'} style={{ width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                                <CountUp separator={','} duration={3} className={'title'} end={(this.props.TotalCost * 100) / (this.props.TotalAmount)} />
                                <Typography className={'title'} >{'%'}</Typography>
                              </Grid>
                              <Grid container direction={'row'} style={{ width: '25%', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{fontSize:'15px'}} className={'title-content'}>{'$' + (this.props.TotalAmount)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper> :
                        <Paper style={{ padding: "20px", margin: '5px' }}>
                          <Typography className={'title-main'} variant="subheading">{obj.Title}</Typography>
                          <Grid style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                          <Grid
                            container
                            direction={"row"}
                            style={{
                              width: '100%',
                              height: '165px',
                              justifyContent: "space-between", alignItems: "center"
                            }}>
                            <Grid
                              container
                              direction={"column"}
                              style={{
                                width: '39%', justifyContent: "flex-start", alignItems: "flex-start"
                              }}  >
                              <Typography style={{fontSize:'15px'}} className={'title-content'}>Impressions </Typography>
                              <CountUp separator={','} style={{marginBottom:'10px'}} duration={3} className={'title'} end={obj.Impressions} />
                              <Typography style={{fontSize:'15px'}}className={'title-content'} >Clicks </Typography>
                              <CountUp separator={','} style={{marginBottom:'10px'}} duration={3} className={'title'} end={obj.Clicks} />
                              <Typography style={{fontSize:'15px'}} className={'title-content'} >Total Conversions</Typography>
                              <CountUp separator={','} className={'title'} end={parseInt(obj.Certificate_Downloads) + parseInt(obj.Certificate_Emails) + parseInt(obj.Appointment_Clicks)} />
                            </Grid>
                            <Grid
                              container
                              direction={"column"}
                              style={{
                                width: '60%',
                                borderLeft: '3px solid #F5F7FA',
                                justifyContent: "center",
                                alignItems: "center",
                              }}  >
                              <Doughnut
                                height={200}
                                // width={340}
                                data={{
                                  labels: ["Certificate Download", "Appointment Clicks  ", "Certificate Email        ",],
                                  datasets: [
                                    {
                                      weight: 0.5,
                                      data: [obj.Certificate_Downloads, obj.Certificate_Emails, obj.Appointment_Clicks],
                                      borderWidth: 0,
                                      backgroundColor: [
                                        '#689AB0',
                                        '#0A5C7F',
                                        '#C6D8E1',
                                        // '#EAF2FD'
                                      ],
                                      hoverBackgroundColor: "#3588ba",
                                    },
                                  ],
                                }}
                                options={{
                                  animateRotate: true,
                                  animateScale: false,
                                  animation: {
                                    duration: 2000,
                                  },
                                  legend: {
                                    position: "bottom", align: "center", labels: {
                                      boxWidth: 30,
                                      fontSize: 12,
                                      fontColor: '#646464'
                                    }
                                  },
                                  datalabels: {
                                    display: true,
                                    color: "white",
                                  },
                                  tooltips: {
                                    backgroundColor: "#5a6e7f",
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      }
                    </Grid></>
                )
              })}
            </>
          }
        </Grid>
      </Grid>
    );
  }
}

SocialDonutChart.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        dark: PropTypes.string,
        main: PropTypes.string,
        contrastText: PropTypes.string,
      }),
      secondary: PropTypes.shape({
        main: PropTypes.string,
      }),
      common: PropTypes.shape({
        white: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(SocialDonutChart);

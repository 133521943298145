/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from "../../../api/APIController";
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import double_left_arrow from "../../../assets/images/left-doble-arow.svg";
import left_arrow from "../../../assets/images/left-arrow.svg";
import double_right_arrow from "../../../assets/images/right-doble-arow.svg";
import right_arrow from "../../../assets/images/right-arrow.svg";
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";
import filtericon from "../../../assets/images/ic_feather_filter.svg";

import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

import Modal from '../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../ConfirmDialog/ConfirmationDialog';
import Notification from '../../../shared/components/notification/notification';

const UserList = (props) => {
	const headerList = ["action", "full Name", "Email Address", "Phone No", "Role", "Branch", "Position", "Department", "Area", "Region", "Territory", "Is active ?",];
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from, } = props;
	let userList = list;
	userList = list.slice(from, to);
	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState("");

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	};
	const wsDeleteUser = (Id) => {
		APIController.DeleteUser(Id).then((response) => {
			if (response.error == null) {
				setLoginDialog(false);
				if (response.object.status == 0) {
					handleNotification(response.object.message, true);
				} else {
					props.handlebind()
					handleNotification(response.object.message);
				}
			} else if (response.error.message && response.error.message.length > 0) {
				handleNotification(response.error.message);
			}
		});
	};
	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message);
		setShowNotification(true);
		setIsError(isError);

		setTimeout(() => {
			handleClearNotification();
		}, 4000);
	};

	const handleClearNotification = () => {
		setMessage("");
		setShowNotification(false);
		setIsError(false);
	};
	return (
		<>
			<h1>View Users</h1>
			<div className='table-main-wrap users-class'>
				<PerfectScrollbar component='div' style={{ width: '1900px' }}>
					<Table className=''>
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => {
									return <TableCell className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow>
								<TableCell>
									<span> <img src={filtericon} alt='delete' /> </span>
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Full Name' onChange={(e) => props.applyFilter("FullName", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Email Address' onChange={(e) => props.applyFilter("EmailAddress", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Phone No' onChange={(e) => props.applyFilter("PhoneNo", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Role' onChange={(e) => props.applyFilter("Role", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Branch' onChange={(e) => props.applyFilter("BranchName", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Position' onChange={(e) => props.applyFilter("Position", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Department' onChange={(e) => props.applyFilter("Department", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Area' onChange={(e) => props.applyFilter("Area", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Region' onChange={(e) => props.applyFilter("Region", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder='Search Territory' onChange={(e) => props.applyFilter("Territory", e.target.value, "input")} />
								</TableCell>
								<TableCell style={{ width: '8%' }}>
									<select onChange={(e) => props.applyFilter("IsActiveLABEL", e.target.value, "select")}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!userList.length ? (
								userList.map((obj, idnex) => {
									return (
										<TableRow key={idnex}>
											<TableCell>
												<span>
													<img src={editIcon} alt='edit' style={{ cursor: 'pointer', marginRight: '3px' }}
														onClick={() => {
															props.history.push({
																pathname: '/users/add',
																state: {
																	usersIdEdit: obj.ID,
																	usersFullNameEdit: obj.FullName,
																	usersEmailEdit: obj.EmailAddress,
																	usersPhoneNoEdit: obj.PhoneNo,
																	usersActiveEdit: obj.IsActiveLABEL === "YES" ? 1 : 0
																}
															})
														}} />
													<img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '3px' }}
														onClick={() => {
															setDeleteId(obj.ID)
															setLoginDialog(true)
														}} />
												</span>
											</TableCell>
											<TableCell> {obj.FullName}</TableCell>
											<TableCell >{obj.EmailAddress}</TableCell>
											<TableCell >{obj.PhoneNo}</TableCell>
											<TableCell >{obj.Role}</TableCell>
											<TableCell >{obj.BranchName}</TableCell>
											<TableCell >{obj.Position}</TableCell>
											<TableCell>{obj.Department}</TableCell>
											<TableCell >{obj.Area}</TableCell>
											<TableCell >{obj.Region}</TableCell>
											<TableCell >{obj.Territory}</TableCell>
											<TableCell className={obj.IsActiveLABEL === "YES" ? 'green' : 'red'}>
												<span>{obj.IsActiveLABEL === "YES" ? 'Yes' : 'No'}</span>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow>
									<TableCell colSpan={headerList.length} className='not_found'>
										{props.progress ?
											<CircularProgress /> :
											<label>Not Currently Available</label>}
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
			{LoginDialog && (
				<Modal
					show={LoginDialog}
					handleClose={() => setLoginDialog(false)}>
					<ConfirmtionDialog
						title={"Delete Country"}
						description={"Are you sure you want to delete this Country?"}
						deletePress={() => wsDeleteUser(DeleteId)}
						closeModal={() => setLoginDialog(false)}
					/>
				</Modal>
			)}
			<div className='table-pagination'>
				<div className='pagination-info'>
					<p>
						Showing {from + 1} - {to > count ? count : to} of {count}
					</p>
				</div>
				<div className='pagination-control'>
					<a
						href='#!'
						title=''
						className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? "disabled" : ""}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1);
						}}>
						<img src={double_left_arrow} alt='left' />
					</a>
					<a
						href='#!'
						title=''
						className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? "disabled" : ""
							}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1);
						}}>
						<img src={left_arrow} alt='single_left' />
					</a>
					<span>Page</span>
					<input
						type='text'
						name='textbox'
						value={currentPage === 0 ? currentPage + 1 : currentPage}
						onChange={(e) => handleChangeInCurrentPage(e.target.value)}
					/>
					<span>of {page}</span>
					<a
						href='#!'
						title=''
						className={`simple-right-arrow ${currentPage === page ? "disabled" : ""
							}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1);
						}}>
						<img src={right_arrow} alt='right' />
					</a>
					<a
						href='#!'
						title=''
						className={`double-right-arrow ${currentPage === page ? "disabled" : ""
							}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page);
						}}>
						<img src={double_right_arrow} alt='single_right' />
					</a>
					<select
						onChange={(e) =>
							props.handleChangeRowsPerPage(parseInt(e.target.value))
						}
						defaultValue={rowsPerPage}>
						{rowsPerPageOptions.map((obj, index) => {
							return (
								<option value={obj} key={index}>
									{obj}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		</>
	);
};

export default UserList;

/*eslint-disable eqeqeq*/
import React from 'react';
import MyiFrame from "../../../features/campaign/components/myiFrame";
import './adbreak.css';
import Notification from '../../../shared/components/notification/notification';
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import ModalComponent from "../../../shared/components/modal/modal";
import CustomModalComponent from "../../../shared/components/modal/custommodal";
import close_icon from "../../../assets/images/gray-close-ic.svg";
import SvgIcon from '@material-ui/core/SvgIcon';
import Visibility from '@material-ui/icons/Visibility';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PopoverComponent from "../../../shared/components/popover/popover";
import PerfectScrollbar from "react-perfect-scrollbar";
import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";

class AdBreak extends React.Component {
    state = {
        activeStep: 1,
        active: 0,
        campaignAssets: [],
        campaignAssets1: [],
        campaignAssetsIamge: [],
        AssetTypeID: '',
        CampaignReach: JSON.parse(AppConstants.GetLocalStorage('localStorageCampaignReach')),
        CampaignThemes: [],
        ThemesProgress: false,
        IsLoading: false,
        progress: false,
        CampaignBusinessHours: [],
        CampaignBusinessDetails: [],
        message: '',
        showNotification: false,
        isError: false,
        PracticeIDs: JSON.parse(AppConstants.GetLocalStorage('localStoragePracticeIDs')),
        Size72890: '',
        Size160600: '',
        Size300600: '',
        Size300250: '',
        Size320100: '',
        Size336280: '',
        isLoading: true,
        downloadloading: false,
        loading: true,
        isFacbook: false,
        modalOpen: false,
        AdContentCopy: '',
        selectValue: 'Standard',
        anchorEl: null,
        anchorElLocation: null,
        PracticeID: JSON.parse(AppConstants.GetLocalStorage('localStoragePracticeIDs'))[0],
        CampaignID: AppConstants.GetLocalStorage('localStorageCampaignID'),
        CampaignThemesID: AppConstants.GetLocalStorage('localStorageThemesID'),
        ProductID: AppConstants.GetLocalStorage('localStorageProductID'),
        FromCart: AppConstants.GetLocalStorage('localStorageFromCart'),
        ShowCopyText: AppConstants.GetLocalStorage('localStorageShowCopyText'),
        ShowAddToCart: AppConstants.GetLocalStorage('localStorageShowAddToCart'),
        OrderDetailsID: AppConstants.GetLocalStorage('localStorageOrderDetailsID'),
        BudgetDetail: JSON.parse(AppConstants.GetLocalStorage('localStorageBudgetDetail')),
        ISDirectMail: AppConstants.GetLocalStorage('localStorageISDirectMail'),
        FreeAssetID: AppConstants.GetLocalStorage('localStorageFreeAssetID'),
        LocationsName: '',
        CampaignAssetID: ''
    };

    componentDidMount() {
        this.wsGetCampaignAssets(this.state.CampaignID, this.state.CampaignThemesID, this.state.PracticeID, this.state.ProductID, this.state.CampaignReach.length, this.state.FreeAssetID);
        var data = this.state.PracticeIDs;
        var data1 = data.join(",");
        this.setState({ PracticeIDs: data1 })
        var locationName = [];
        if (AppConstants.GetLocalStorage('localStorageCampaignReach')) {
            JSON.parse(AppConstants.GetLocalStorage('localStorageCampaignReach')).map((obj, index) => {
                return (locationName.push(obj.Location))
            })
        }
        this.handleFirebaseEvents("Ad Builder", locationName)
        this.setState({ LocationsName: locationName })
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };

    wsGetCampaignAssets = (CampaignID, CampaignThemeID, PracticeID, ProductID, TotalLocations, FreeAssetID) => {
        this.setState({ IsLoading: true })
        APIController.GetCampaignAssets(CampaignID, CampaignThemeID, PracticeID, ProductID, TotalLocations, FreeAssetID)
            .then((response) => {
                let campaignAssets = [];
                if (response.error == null) {
                    response.object.data.map((obj, index) => {

                        if (obj.IsMultiLocation && this.state.CampaignReach != undefined && this.state.CampaignReach.length != 1) {
                            campaignAssets.push(obj)
                        } else if (!obj.IsMultiLocation) {
                            campaignAssets.push(obj)
                        }
                        this.setState({ campaignAssets: campaignAssets, activeStep: 1 })
                    })
                    this.setState({ AssetTypeID: response.object.data.length ? response.object.data[0].ID : '' })
                    if (FreeAssetID == "" || FreeAssetID == undefined || FreeAssetID == null) {
                        this.wsGetCampaignAssets1(CampaignID, CampaignThemeID, response.object.data.length ? response.object.data[0].ID : '', this.state.PracticeID, this.state.CampaignReach.length, FreeAssetID)
                        this.wsGetCampaignThemes(this.state.CampaignID, this.state.ProductID)
                    } else {
                        this.setState({ campaignAssetsIamge: response.object.data })
                        this.setState({ loading: false, isLoading: false })
                    }
                }
                else {
                    this.handleNotification(response.object.message, true);
                }
                this.setState({ IsLoading: false })
            })
    }

    wsGetCampaignThemes = (CampaignID, ProductID) => {
        this.setState({ ThemesProgress: true })
        APIController.GetCampaignThemes(CampaignID, ProductID)
            .then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    this.setState({ CampaignThemes: response.object.data })
                } else {
                    this.handleNotification("Currently Theme Not Available", true);
                }
                this.setState({ ThemesProgress: false })
            })
    }

    wsGetCampaignAssets1 = (CampaignID, CampaignThemeID, AssetTypeID, PracticeID, TotalLocations, FreeAssetID) => {
        this.setState({ IsLoading: true })
        APIController.GetCampaignAssetsTypeByID(CampaignID, CampaignThemeID, AssetTypeID, PracticeID, TotalLocations, FreeAssetID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ campaignAssetsIamge: response.object.data })
                    if (response.object.data.length) {
                        this.setState({
                            CampaignAssetID: response.object.data[0].CampaignAssetID,
                            AdContentCopy: response.object.data[0].AdContent
                        })
                    }
                    response.object.data.map((obj, index) => {
                        return (
                            <>
                                {obj.Width == 160 && obj.Height == 600 ?
                                    this.setState({ Size160600: obj.AdContent, })
                                    : null}
                                {obj.Width == 300 && obj.Height == 600 ?
                                    this.setState({ Size300600: obj.AdContent, })
                                    : null}
                                {obj.Width == 728 && obj.Height == 90 ?
                                    this.setState({ Size72890: obj.AdContent, })
                                    : null}
                                {obj.Width == 300 && obj.Height == 250 ?
                                    this.setState({ Size300250: obj.AdContent, })
                                    : null}
                                {obj.Width == 320 && obj.Height == 100 ?
                                    this.setState({ Size320100: obj.AdContent, })
                                    : null}
                                {obj.Width == 336 && obj.Height == 280 ?
                                    this.setState({ Size336280: obj.AdContent, })
                                    : null}
                            </>
                        )
                    })
                    this.setState({ loading: false, isLoading: false })
                }
                else if (response.object.message && response.object.message.length > 0) {
                    this.handleNotification(response.error.message, true);
                }
                this.setState({ IsLoading: false, anchorElLocation: null })
            })
    }

    //Add To Cart Process
    //OrderDetailsID 0 for Add -> Id for Edit

    wsAddEditCart = (OrderDetailsID, Budget, CampaignDurationInMonths, CampaignID, ProductID, BrandID, CampaignThemeID, PracticeIDs, CampaignReach) => {
        this.setState({ progress: true })
        APIController.AddEditCart(OrderDetailsID, Budget, CampaignDurationInMonths, CampaignID, ProductID, BrandID, CampaignThemeID, PracticeIDs, CampaignReach)
            .then((response) => {
                this.setState({ progress: false })
                if (response.object.status == 1) {
                    this.props.history.push('/cart')
                    this.handleNotification(response.object.message);
                    this.handleFirebaseEvents("Add To Cart", this.state.LocationsName)
                } else {
                    this.handleNotification(response.object.message, true);
                }
            })
    }

    wsDownloadPostcard = (CampaignID, PracticeID, CampaignThemeID, TotalLocations, AssetTypeID, FreeAssetID) => {
        this.setState({ downloadloading: true })
        APIController.DownloadPostcard(CampaignID, PracticeID, CampaignThemeID, TotalLocations, AssetTypeID, FreeAssetID)
            .then((response) => {
                this.setState({ downloadloading: false })
                console.log(response)
            }).catch((err) => console.log(err))
    }

    handleStep(ID, Name) {
        this.setState({ AssetTypeID: ID })
        this.wsGetCampaignAssets1(this.state.CampaignID, this.state.CampaignThemesID, ID, this.state.PracticeID, this.state.CampaignReach.length)
        var fname = Name.split(" ")[0];
        if (fname == "FACEBOOK") {
            this.setState({ isFacbook: true })
        }
        else {
            this.setState({ isFacbook: false })
        }
    }

    handleSelectChange = (value) => {
        this.setState({ selectValue: value, PracticeID: value })
        this.wsGetCampaignAssets1(this.state.CampaignID, this.state.CampaignThemesID, this.state.AssetTypeID, value, this.state.CampaignReach.length)
    };

    handleChangeTheme(ThemesID) {
        this.wsGetCampaignAssets(this.state.CampaignID, ThemesID, this.state.PracticeID, this.state.ProductID, this.state.CampaignReach.length, this.state.FreeAssetID)
        this.setState({ CampaignThemesID: ThemesID, anchorEl: null })
    }

    handleCloseTheme = () => {
        this.setState({ anchorEl: null })
    }

    handleCloseLocation = () => {
        this.setState({ anchorElLocation: null })
    }

    copyToClipboard = (Description) => {
        this.setState({ modalOpen: false })
        navigator.clipboard.writeText(Description)
        this.handleNotification("Content Copied to Clipboard");

        AppConstants.LogEvent('Copy Text', AppConstants.CreateLogEventObject("",
            AppConstants.GetLocalStorage('localStorageBrandName'),
            AppConstants.GetLocalStorage('localStorageCampaignName'),
            AppConstants.GetLocalStorage('localStorageProductName'), "", "", "", "", "", "EmailMarketing", "", ""))
    }

    wsReportEvent = (CampaignAssetID, PracticeID, ReportTypeID) => {
        APIController.ReportEvent(CampaignAssetID, PracticeID, ReportTypeID).then((response) => { })
    }

    handleFirebaseEvents = (EventName, locationName) => {
        // Practice, Brand, Campaign, Product, Location, Theme, Category, Subcategory, FreeAsset, EmailMarketing, SocialAutomation, Amount 
        AppConstants.LogEvent(EventName,
            AppConstants.CreateLogEventObject("",
                AppConstants.GetLocalStorage('localStorageBrandName'),
                AppConstants.GetLocalStorage('localStorageCampaignName'),
                AppConstants.GetLocalStorage('localStorageProductName'),
                locationName.toString(),
                AppConstants.GetLocalStorage('localStorageThemesName'), "", "", "", "", "", ""))
    }

    render() {
        return (
            <section className="content-right-main">
                <div className="content-heading-view">
                    {AppConstants.GetMainClassName() == "app-main" ?
                        this.state.BudgetDetail[0].budget == "1" ? <PageTitle Title={AppConstants.ContactDetais.Step3Artwork} AdBilder={true} /> :
                            <PageTitle Title={AppConstants.ContactDetais.Step4Artwork} AdBilder={true} />
                        : this.state.BudgetDetail[0].duration == "1" ? <PageTitle Title={AppConstants.ContactDetais.Step3Yomi} AdBilder={true} />
                            : <PageTitle Title={AppConstants.ContactDetais.Step3ADYomi} AdBilder={true} />}

                    {/* <div className="theme-option">
                        <select style={{ padding: '7px', height: '35px', border: '2px solid #555555', color: '#555555', fontSize: '14px', marginBottom: "5px" }}
                            id={'segment'}
                            name="reportType"
                            defaultValue={this.state.selectValue}
                            value={this.state.selectValue}
                            onChange={(e) => this.handleSelectChange(e.target.value)}>
                            {this.state.CampaignReach.map((obj, index) => {
                                return (<option selected={obj} value={this.state.FromCart == '0' ? obj.ID : obj.PracticeID} key={index}>{obj.Location}</option>)
                            })}
                        </select>
                    </div> */}

                    <div className="content-heading-dropdown">
                        {AppConstants.GetMainClassName() == "app-main" &&
                            <CustomModalComponent DetailsData={this.state.campaignAssets} />}
                        {/* {console.log('this.state.CampaignReach:', this.state.CampaignReach)} */}

                        {this.state.CampaignReach != undefined &&
                            this.state.CampaignReach.length != 1 &&
                            <div className="theme-option" onClick={(e) => this.setState({ anchorElLocation: e.currentTarget })}>
                                {this.state.anchorElLocation == null ? <div style={{ textAlign: 'right' }}> <SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon>{"CHANGE LOCATION"}</div>
                                    : <div style={{ textAlign: 'right' }}><SvgIcon fontSize="large"><ArrowDropUpIcon /></SvgIcon>{"CHANGE LOCATION"}</div>}
                            </div>}

                        <PopoverComponent
                            anchorEl={this.state.anchorElLocation}
                            handleClose={this.handleCloseLocation}
                            id={"location-popover"}>
                            <div className="digital-welcome" style={{ padding: '15px', maxHeight: '530px' }}>
                                {!!this.state.CampaignReach.length &&
                                    this.state.CampaignReach.map((obj, index) => {
                                        return (<div onClick={() => this.handleSelectChange(obj.PracticeID)} className={this.state.PracticeID == obj.PracticeID ? "one-col-content-box" : "one-col-content"} key={index}>
                                            <div className="box-content-info" >
                                                <label htmlFor={obj.PracticeID} style={{ fontSize: '16px' }}>{obj.Location}</label>
                                            </div>
                                        </div>)
                                    })}
                            </div>
                        </PopoverComponent>

                        {this.state.CampaignThemes.length > 1 &&
                            <div className="theme-option" onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
                                {this.state.anchorEl == null ? <div style={{ textAlign: 'right' }}> <SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon>{"SHOW THEME OPTIONS"}</div>
                                    : <div style={{ textAlign: 'right' }}><SvgIcon fontSize="large"><ArrowDropUpIcon /></SvgIcon>{"HIDE THEME OPTIONS"}</div>}
                            </div>}

                        <PopoverComponent
                            anchorEl={this.state.anchorEl}
                            handleClose={this.handleCloseTheme}
                            id={"theme-popover"}>
                            <div className="digital-welcome" style={{ padding: '20px', maxHeight: '530px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                {!!this.state.CampaignThemes.length
                                    ? this.state.CampaignThemes.map((obj, index) => {
                                        return (<div onClick={() => this.handleChangeTheme(obj.ID)} className={this.state.CampaignThemesID == obj.ID ? "one-col-content-box" : "one-col-content"} key={index}>
                                            <div className="box-image"
                                                style={{
                                                    backgroundImage: `url(${obj.PreviewURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} )`,
                                                    backgroundOrigin: 'padding-box', height: '200px',
                                                }}
                                            >
                                            </div>
                                            <div className="box-content-info" >
                                                <p>	{AppConstants.GetLocalStorage('localStorageBrandName')}</p>
                                                <label htmlFor={obj.ID} style={{ fontSize: '16px' }}>{obj.Name}</label>
                                            </div>
                                        </div>)
                                    })
                                    : <div style={{ alignSelf: 'center', alignContent: 'center' }}>
                                        {this.state.ThemesProgress ?
                                            <CircularProgress /> :
                                            <label>Not Currently Available</label>}
                                    </div>}
                            </div>
                        </PopoverComponent>
                    </div>
                </div >
                <div className="white-box-content">
                    <div className="step-row-wrapper">
                        <div className="step-row">
                            {!!this.state.campaignAssets.length && this.state.campaignAssets.length != 1 ? this.state.campaignAssets.map((obj, index) => {
                                return (
                                    <div className={`step-col  ${this.state.activeStep == index + 1 ? 'active' : this.state.activeStep > index ? 'active-step' : ''}`}>
                                        <h3>{obj.Name}</h3>
                                    </div>
                                )
                            }) : <div style={{ alignSelf: 'center', alignContent: 'center' }}>
                                {this.state.IsLoading ?
                                    <CircularProgress /> :
                                    <label>{this.state.campaignAssets.length < 1 && "Not Currently Available"}</label>}
                            </div>}
                        </div>
                    </div>

                    {
                        this.state.campaignAssetsIamge.length > 1 ?
                            <div className="asset-img">
                                {this.state.loading ?
                                    <CircularProgress /> :
                                    <div className="white-box-image" >
                                        <div className="content_img_row">
                                            <div className="content_img_row1" style={{ width: '730px', height: '130PX', marginRight: '3%' }}>
                                                <div className="image_content">
                                                    <MyiFrame HTMLContent={this.state.Size72890} />
                                                </div>
                                                <div className="content-info">
                                                    <h6>Leaderboard</h6>
                                                    <p>728X90</p>
                                                </div>
                                            </div>

                                            <div className="content_img_col content_col_middle" style={{ width: '322px' }}>
                                                <div className="image_content">
                                                    <MyiFrame HTMLContent={this.state.Size320100} />
                                                </div>
                                                <div className="content-info">
                                                    <h6>Inline Rectangle</h6>
                                                    <p>320X100</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="content_img_row" >

                                            <div className="content_img_col content_col_small" style={{ width: '162px' }}>
                                                <div className="image_content">
                                                    <MyiFrame HTMLContent={this.state.Size160600} />
                                                </div>
                                                <div className="content-info">
                                                    <h6>Wide Skyscaraper</h6>
                                                    <p>160X600</p>
                                                </div>
                                            </div>

                                            <div className="content_img_col content_col_middle" style={{ width: '302px' }}>
                                                <div className="image_content">
                                                    <MyiFrame HTMLContent={this.state.Size300600} />
                                                </div>
                                                <div className="content-info">
                                                    <h6>Half page Ad</h6>
                                                    <p>300X600</p>
                                                </div>
                                            </div>

                                            <div className="content_img_col" style={{ width: '53%', border: 'none' }}>
                                                <div className="content_img_col content_col_middle" style={{ width: '338px', height: '320px' }}>
                                                    <div className="image_content">
                                                        <MyiFrame HTMLContent={this.state.Size336280} />
                                                    </div>
                                                    <div className="content-info">
                                                        <h6>Inline Rectangle</h6>
                                                        <p>336X280</p>
                                                    </div>
                                                </div>

                                                <div className="content_img_col content_col_middle" style={{ width: '302px', height: '290px' }}>
                                                    <div className="image_content">
                                                        <MyiFrame HTMLContent={this.state.Size300250} />
                                                    </div>
                                                    <div className="content-info">
                                                        <h6>Inline Rectangle</h6>
                                                        <p>300X250</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                {this.state.campaignAssetsIamge.map((obj, index) => {
                                    return (<div className="asset-img">
                                        <div className="image_content" style={{ textAlign: 'center' }}>
                                            {this.state.isLoading ? <CircularProgress />
                                                : <MyiFrame HTMLContent={obj.AdContent} isFacbook={this.state.isFacbook} Height={obj.Height} Width={obj.Width} />}
                                        </div>
                                    </div>)
                                })}
                            </div>
                    }

                    {/* this.state.campaignAssets.length <= this.state.activeStep && */}

                    {this.state.BudgetDetail[0].budget == "1" &&
                        <div style={{ textAlign: 'center', borderBottom: '2px solid #f5f7fa', marginBottom: "10px", paddingBottom: '10px' }}>
                            {this.state.FreeAssetID != "" ?
                                <div className={this.state.downloadloading ? "button-field disable-btn" : "button-field"}>
                                    <input type='button' name='button' value={this.state.downloadloading ? AppConstants.ButtonText.Downloading : AppConstants.ButtonText.Download} onClick={() => {
                                        this.wsDownloadPostcard(this.state.CampaignID, this.state.PracticeID, this.state.CampaignThemesID, this.state.CampaignReach.length, this.state.AssetTypeID, this.state.FreeAssetID)
                                    }} />
                                </div>
                                : this.state.ISDirectMail == '1' ?
                                    <>
                                        <div className={this.state.downloadloading ? "button-field disable-btn" : "button-field"}>
                                            <input type='button' name='button' value={this.state.downloadloading ? AppConstants.ButtonText.Downloading : AppConstants.ButtonText.DownloadPostcard} onClick={() => {
                                                this.wsDownloadPostcard(this.state.CampaignID, this.state.PracticeID, this.state.CampaignThemesID, this.state.CampaignReach.length, this.state.AssetTypeID, "")
                                            }} />
                                        </div>
                                        <div className="button-field">
                                            <input type='button' name='button' value={AppConstants.ButtonText.SendToPrinter}
                                                onClick={(e) => this.props.history.push('/digital-product/selection/direct-mail')} />
                                        </div>
                                    </>
                                    : <div className="button-field">
                                        <input type='button' name='button' value={AppConstants.ButtonText.CopyHTML} onClick={() => this.setState({ modalOpen: true })} />
                                    </div>}
                        </div>}


                    <div className="right-continue-btn">
                        <div className="button-field">
                            <input type='button' name='button' value={AppConstants.ButtonText.Back} onClick={() => {
                                window.scrollTo(0, 0);
                                if (this.state.activeStep === 1) {
                                    this.props.history.goBack();
                                } else {
                                    this.setState({ activeStep: this.state.activeStep - 1 });
                                    !!this.state.campaignAssets.length && this.state.campaignAssets.map((obj, index) => {
                                        return (
                                            this.state.activeStep - 2 == index ? this.handleStep(obj.ID, obj.Name) : <div />
                                        )
                                    })
                                }
                            }} />
                        </div>

                        {this.state.ShowAddToCart == undefined || this.state.ShowAddToCart == 1 ?
                            <div className={this.state.progress ? 'button-field next-step-btn disable-btn' : 'button-field next-step-btn'}>
                                <input type='button' name='button' value={
                                    this.state.activeStep < this.state.campaignAssets.length ? AppConstants.ButtonText.Continue :
                                        this.state.campaignAssets.length == 0 ? AppConstants.ButtonText.Continue :
                                            this.state.FromCart == '0' ? AppConstants.ButtonText.BackToCart :
                                                this.state.progress && this.state.OrderDetailsID == '0' ? AppConstants.ButtonText.AddingtoCart :
                                                    this.state.progress && this.state.OrderDetailsID != '0' ? AppConstants.ButtonText.UpdatingCart :
                                                        this.state.OrderDetailsID != '0' ? AppConstants.ButtonText.UpdateCart :
                                                            this.state.BudgetDetail[0].budget == "1" ? AppConstants.ButtonText.Exit :
                                                                AppConstants.ButtonText.AddToCart}

                                    onClick={() => {
                                        if (this.state.activeStep < this.state.campaignAssets.length) {
                                            window.scrollTo(0, 0);
                                            this.setState({ activeStep: this.state.activeStep + 1 });
                                            !!this.state.campaignAssets.length && this.state.campaignAssets.map((obj, index) => {
                                                return (
                                                    this.state.activeStep == index ? this.handleStep(obj.ID, obj.Name) : <div />
                                                )
                                            })
                                        }
                                        else if (this.state.FromCart == '0') {
                                            this.props.history.push('/cart')
                                        } else if (this.state.BudgetDetail[0].budget == "1") {
                                            this.props.history.push(AppConstants.HomepageURL)
                                        } else if (this.state.ISDirectMail == '1') {
                                            this.props.history.push('/digital-product/selection/direct-mail')
                                        } else {
                                            this.wsAddEditCart(
                                                this.state.OrderDetailsID,
                                                this.state.BudgetDetail[0].budget,
                                                this.state.BudgetDetail[0].duration,
                                                this.state.CampaignID,
                                                this.state.ProductID,
                                                AppConstants.GetLocalStorage('localStorageBrandID'),
                                                this.state.CampaignThemesID,
                                                this.state.PracticeIDs,//PracticeIDs
                                                this.state.CampaignReach, // CampaignReach,
                                            )
                                        }
                                    }} />
                            </div>
                            : <div className="button-field next-step-btn">
                                <input type='button' name='button' value={AppConstants.ButtonText.Done} onClick={() => { this.props.history.push(AppConstants.HomepageURL) }} />
                            </div>}
                    </div>
                </div>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    closeNotification={this.handleClearNotification}
                />
                {
                    this.state.modalOpen && (
                        <ModalComponent
                            show={this.state.modalOpen}
                            handleClose={() => this.setState({ modalOpen: false })}>
                            {this.wsReportEvent(this.state.CampaignAssetID, this.state.PracticeID, "6")}
                            <div className="popup-wrap" style={{ paddingBottom: '10px', display: 'grid' }}>
                                <div style={{ marginBottom: '10px', padding: '10px', alignSelf: 'center', height: '450px', width: '900px', border: '1px solid #cccccc', justifyContent: 'center', alignItems: 'center', }}>
                                    <PerfectScrollbar>
                                        <p style={{ whiteSpace: 'pre-line' }}>{this.state.AdContentCopy}</p>
                                    </PerfectScrollbar>
                                </div>
                                <a title="" className="gray-close-ic">
                                    <img src={close_icon} alt="icon" onClick={() => this.setState({ modalOpen: false })} />
                                </a>
                                <div style={{ textAlign: 'center' }}>
                                    <button
                                        className="button-field"
                                        onClick={(e) => this.copyToClipboard(this.state.AdContentCopy)}>{AppConstants.ButtonText.CopytoClipboard}</button>
                                </div>
                            </div>
                        </ModalComponent>)
                }
            </section >
        );
    }
}

export default AdBreak;
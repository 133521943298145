/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const CityForm = props => {
    const initialValues = { countryId: props.history.location.state.countryIdEdit, stateId: props.history.location.state.stateIdEdit, cityname: props.history.location.state.cityNameEdit, active: props.history.location.state.cityActiveEdit }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [Loading, setLoading] = React.useState(true)
    const [LoadingState, setLoadingState] = React.useState(false)
    const [IsLoading, setIsLoading] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);
    
    const [dataSourceState, setdataSourceState] = React.useState([]);

     React.useEffect(() => {
        props.history.location.state.cityIdEdit != 0 ? wsGetCityByID(props.history.location.state.cityIdEdit)
            : wsGetCountryForDropdown();
    },[props])

    const wsGetCityByID = (ID) => {
        APIController.GetCityByID(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    initialValues.countryId = response.object.data[0].CountryID
                    initialValues.stateId = response.object.data[0].StateID

                    setdataSource(JSON.parse(response.object.data[0].Countries));
                    setdataSourceState(JSON.parse(response.object.data[0].States));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetCountryForDropdown = (ID) => {
        APIController.GetCountryForDropdown(ID)
            .then((response) => {
                setLoading(false)
                setLoadingState(false)
                if (response.error == null) {
                    setdataSource(response.object.data);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetStateForDropdown = (countryId) => {
        setLoadingState(true)
        APIController.GetStateForDropdown(countryId)
            .then((response) => {
                setLoadingState(false)
                if (response.error == null) {
                    setdataSourceState(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsAddEditCity = (ID, CountryID, StateID, Name, IsActive) => {
        setIsLoading(true)
        APIController.AddEditCity(ID, CountryID, StateID, Name, IsActive)
            .then((response) => {
                if (response.error == null) {
                    setIsLoading(false)
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/city', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.cityIdEdit == 0 ? <h1>Add City</h1> : <h1>Edit City</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateCityForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditCity(props.history.location.state.cityIdEdit, values.countryId, values.stateId, values.cityname, values.active)
                        if (isButtonValue === false) {
                            values.cityname = "";
                            values.active = true;
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">

                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.countryId &&
                                                errors.countryId &&
                                                errors.countryId !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Country</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                :
                                                <select name='countryId'
                                                    defaultValue={values.countryId}
                                                    value={values.countryId}
                                                    onChange={(e, index) => {
                                                        wsGetStateForDropdown(e.target.value)
                                                        setFieldValue('countryId', e.target.value);
                                                    }}>
                                                    <option value={''}>Select Country</option>
                                                    {
                                                        dataSource.map((obj, index) => {
                                                            return <option key={index} selected={obj.ID == values.countryId ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.countryId &&
                                                errors.countryId &&
                                                errors.countryId !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='countryId'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.stateId &&
                                                errors.stateId &&
                                                errors.stateId !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>State</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingState ? <CircularProgress style={{ alignItems: 'center' }} />
                                                :
                                                <select name='stateId'
                                                    defaultValue={values.stateId}
                                                    value={values.stateId}
                                                    onChange={(e) => {
                                                        setFieldValue('stateId', e.target.value);
                                                    }}>
                                                    <option value={''}>Select State</option>
                                                    {
                                                        dataSourceState.map((obj, index) => {
                                                            return <option key={index} selected={obj.ID == values.stateId ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.stateId &&
                                                errors.stateId &&
                                                errors.stateId !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='stateId'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.cityname &&
                                                errors.cityname &&
                                                errors.cityname !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>City</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter City" name="cityname" value={values.cityname} maxLength="50" />
                                            {errors &&
                                                touched.cityname &&
                                                errors.cityname &&
                                                errors.cityname !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='cityname'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.cityIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateCityForm = yup.object().shape({
    countryId: yup.string().required('Country is required'),
    stateId: yup.string().required('State is required'),
    cityname: yup.string().required('City is required'),
});


export default CityForm;
import React from 'react';
import forgot_user_ic from '../../assets/images/forgot-username-ic.svg';
import close_ic from '../../assets/images/close-ic.svg';
import { Grid, } from '@material-ui/core';

const TermsAndConditionDialog = props => {

    const { title, description } = props;

    return (
        <div className="popup-box forgot-popup-box" style={{ width: '10%' }}>
            <div className="box-heading">
                {/* <img src={delete_ic} alt="icon" /> */}
                <img src={forgot_user_ic} alt="icon" />
                <h2 className="h1">{title}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <Grid container justify='center' alignItems='center' style={{ marginTop: '5%' }}>
                {/* <h2>Are you sure you want to delete?</h2> */}
                <h2>{description}</h2>
            </Grid>
            <Grid container justify='center' alignItems='center' style={{ padding: '1%', }}>
                <div className="button-field">
                    <input type="button" value="close" name="Ok" onClick={props.closeModal} />
                </div>
            </Grid>
        </div>
    )
}

export default TermsAndConditionDialog;
/*eslint-disable eqeqeq*/
import React from 'react';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import * as APIController from "../../../../api/APIController.js";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import { Typography, } from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import PageTitle from "../../../../config/pageTitle";
import * as AppConsatnt from "../../../../config/AppConstant";

class Order extends React.Component {
    state = {
        list: [],
        headerList: ["Order Date", "Order #", "Products Ordered", "Product Cost"],
        progress: false,
    };
    componentDidMount() {
        this.wsGetOrderList();
    }

    wsGetOrderList() {
        this.setState({ progress: true, });
        APIController.GetOrderList().then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data,
                    progress: false,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                // this.handleNotification(response.error.message, true);
            }
        });
    }
    render() {
        return (
            <>
                <section className='content-right-main'>
                    <PageTitle Title={AppConsatnt.ContactDetais.YourOrders} />
                    <div className="table-main-wrap countrys-class">
                        <PerfectScrollbar component='div' style={{ width: '100%' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {this.state.headerList.map((obj, index) => {
                                            return <TableCell style={index == 0 ?
                                                { width: '15%', textAlign: 'start' } : index == this.state.headerList.length - 1 ?
                                                    { width: '25%', textAlign: 'end' } : index == this.state.headerList.length - 2 ?
                                                        { width: '50%', textAlign: 'left' } :
                                                        { width: '10%', textAlign: 'center' }} key={index}>{obj}</TableCell>;
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!this.state.list.length ? this.state.list.map((obj, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell>
                                                    <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'start' }}>{obj.OrderDate}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.OrderNo}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.parse(obj.Products) != null ? JSON.parse(obj.Products).map((obj, index) => {
                                                        return (
                                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 500, }}>{obj.Product}</Typography>
                                                        );
                                                    })
                                                        : null}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'end', fontWeight: 500, }}>{obj.ProductCost}</Typography>
                                                    <Typography style={{ color: '#177BC9', fontSize: '13px', textAlign: 'end', marginTop: '5px', cursor: 'pointer' }}
                                                        onClick={() => this.props.history.push({
                                                            pathname: '/orderdetail', state: { OrderIDDetails: obj.ID, OrderNo: obj.OrderNo }
                                                        })}>View Order Details</Typography>
                                                    {localStorage.getItem("headerMenuAdmin") == 1 &&
                                                        <Typography style={{ color: '#177BC9', fontSize: '13px', textAlign: 'end', marginTop: '5px', cursor: 'pointer' }}
                                                            onClick={() => this.props.history.push({
                                                                pathname: '/ConfirmReport', state: { OrderIDDetails: obj.ID, OrderNo: obj.OrderNo }
                                                            })}>Configure Reports</Typography>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : <TableRow>
                                        <TableCell colSpan={this.state.headerList.length} className="not_found">
                                            {this.state.progress ?
                                                <CircularProgress /> :
                                                <label>No Order Found</label>
                                            }
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </PerfectScrollbar>
                    </div>
                </section>
            </>
        )
    }
}

export default Order;
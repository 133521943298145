/*eslint-disable eqeqeq*/
import React from "react";
import EmailMarketingReportList from "./emailMarketingReportList";
import * as APIController from "../../../../../api/APIController.js";
import Notification from "../../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import * as AppConstants from "../../../../../config/AppConstant";

class EmailMarketingReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 250, 500, 1000, "All"],
        list: [],
        from: 0,
        to: 50,
        downloadlist: [],
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
    };

    componentDidMount() {
        this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", this.state.currentPage, this.state.rowsPerPage);
    }

    wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, PracticeName, CampaignName, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, CurrentPage, RecordPerPage) {
        this.setState({ progress: true, });
        APIController.AssetDownloadReport("6", DownloadedAt, "", ShipToNo, CampaignName, PracticeName,
            "", "", "", "", "", "", "", "", "", "", "", EmailAddress, VTM_Territory, VTM,
            VeevaCode, Version, "", "", "", "", "", "", "", "", CurrentPage, RecordPerPage).then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    let downloaddata = []
                    response.object.data.map((item) =>
                        downloaddata.push({
                            "Accessed At": item.DownloadedAt, "Ship To #": item.ShipToNo, "Practice Name": item.PracticeName,
                            "Campaign": item.CampaignName, "Email Name": item.EmailAddress, "Veeva Code": item.VeevaCode,
                            "Version": item.Version, "VTM #": item.VTM_Territory, "Territory Manager": item.VTM
                        })
                    )
                    this.setState({
                        downloadlist: downloaddata,
                        to: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ list: [], progress: false }) }
            });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>

                    <div className='button-field button-field-country'>
                        <CsvDownload data={this.state.downloadlist} filename="REACH Email Marketing Download Report.csv">Download to CSV</CsvDownload>
                    </div>

                    <EmailMarketingReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        handleApplyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                    {/*  */}
                </section>
            </>
        );
    }
    handleRowsPerPageChange = (value, DownloadedAt, ShipToNo, PracticeName, CampaignName, EmailAddress, VeevaCode, Version, VTM_Territory, VTM) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, PracticeName, CampaignName, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value);
    }

    handlePageChange = (values, DownloadedAt, ShipToNo, PracticeName, CampaignName, EmailAddress, VeevaCode, Version, VTM_Territory, VTM,) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, PracticeName, CampaignName, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, Number(values), this.state.rowsPerPage);
    }

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (DownloadedAt, ShipToNo, PracticeName, CampaignName, EmailAddress, VeevaCode, Version, VTM_Territory, VTM) => {
        this.wsAssetDownloadReport(this.state.from, this.state.to, DownloadedAt, ShipToNo, PracticeName, CampaignName, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, this.state.currentPage, this.state.rowsPerPage);
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default EmailMarketingReport;

/*eslint-disable eqeqeq*/
import React from "react";
import { NavLink, Link } from "react-router-dom";
import authService from "../../../shared/services/auth-service";
import PopoverComponent from "../../../shared/components/popover/popover";
import icon_cart from "../../../assets/images/Icon-cart.svg";
import profile_generic from "../../../assets/images/profile_generic1.png";
import ic_notification from "../../../assets/images/Icon-notifications.svg";
import ic_edit from "../../../assets/images/ic_edit.svg";
import ic_setting from "../../../assets/images/settings.svg";
import ic_contactus from "../../../assets/images/ic_contactus.png";
import ic_location from "../../../assets/images/ic_location.png";
import { ReactComponent as Ic_dashboard } from '../../../assets/images/ic_dashboard.svg';
import { ReactComponent as Ic_order } from '../../../assets/images/ic_orders.svg';
import { ReactComponent as Ic_coin } from "../../../assets/images/coin.svg";
import { ReactComponent as Ic_admin } from '../../../assets/images/ic_admin.svg';
import ic_power_btn from "../../../assets/images/power-button-ic.svg";
// import "./header.css";
import Modal from "../../../shared/components/modal/modal";
import ChangePassword from "../../ChangePassword/component/change-password";
import EditProfile from "../../EditProfile/component/edit-profile";
import ic_miscellenous from "../../../assets/images/ic_miscellenous.svg";
import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import { Typography } from "@material-ui/core";

const Header = (props) => {
	const [dropdown, setDropdown] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [IsModalOpen, setIsModalOpen] = React.useState(false)
	const [IsProfile, setIsProfile] = React.useState(false)
	const [notificationDataSource, setNotificationDataSource] = React.useState([]);
	const [notificationCount, setNotificationCount] = React.useState("");

	const handleClick = (event, tag) => {
		setDropdown(tag);
		setAnchorEl(event.currentTarget);
	};
	const handleClickCoOp = (event, tag) => {
		handleClick(event, tag)
		localStorage.setItem("sideBarMenuAdmin", 1)
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOpenModal = (value) => {
		setIsProfile(value)
		setIsModalOpen(true)
		handleClose()
	};
	const handleCloseModal = () => {
		setIsModalOpen(false)
	};

	React.useEffect(() => {
		wsGetNotifications();
	}, [])

	const wsGetNotifications = () => {
		APIController.GetNotifications('5')
			.then((response) => {
				if (response.error == null) {
					setNotificationDataSource(response.object.data);
					setNotificationCount(response.object.data.length);
				}
				else if (response.error.message && response.error.message.length > 0) {
					// ToastsStore.warning(response.error.message)
				}
			})
	}

	return (
		<header className='site-header' id='site-header'>
			<div className='header-left'>
				<div className='site-logo'>
					<NavLink to={AppConstants.HomepageURL} title='' onClick={props.handleMenu} >
						<img src={AppConstants.HeaderLogo} alt={AppConstants.HeaderLogo} />
					</NavLink>
				</div>
			</div>
			<div className='header-right' >
				{AppConstants.GetMainClassName() == "app-main" && localStorage.getItem("headerMenuAdmin") != 1 &&
					<div className="header-nav">
						<ul>
							<li>
								<NavLink to='/dashboardimg' onClick={() => { localStorage.setItem("sideBarMenuAdmin", 1) }}>
									<Ic_dashboard />dashboard</NavLink>
							</li>
						</ul>
					</div>}

				{localStorage.getItem("headerMenuAdmin") == 1 &&
					<div className="header-nav">
						<ul>
							<li className="second-step">
								<NavLink to='/assetdownloadreport' onClick={() => { localStorage.setItem("sideBarMenuAdmin", 1) }}>
									<Ic_order />Report</NavLink>
							</li>
						</ul>
					</div>}

				<div className="header-nav">
					<ul>
						<li className="second-step">
							<NavLink to='/order' onClick={() => { localStorage.setItem("sideBarMenuAdmin", 1) }}>
								<Ic_order />Orders</NavLink>
						</li>
					</ul>
				</div>

				{AppConstants.GetMainClassName() == "app-main" && localStorage.getItem("headerMenuAdmin") != 1 &&
					<div className="header-nav">
						<ul>
							<li className="second-step">
								<NavLink to='/subscriptions' onClick={() => { localStorage.setItem("sideBarMenuAdmin", 1) }}>
									<Ic_order />Subscriptions</NavLink>
							</li>
						</ul>
					</div>}

				{localStorage.getItem("headerMenuCoOp") == 1 &&
					<div className="header-nav header-nav-no">
						<ul>
							<li>
								<a>
									<Ic_coin />Co-Op: {localStorage.getItem("headerMenuCoOpAmount")}
								</a>
							</li>
						</ul>
					</div>}

				{localStorage.getItem("headerMenuAdmin") == 1 &&
					<div className="header-nav">
						<ul>
							<li>
								<NavLink to='/admin' {...props}
									onClick={() => { localStorage.setItem("sideBarMenuAdmin", 0) }}> <Ic_admin /> admin </NavLink>
							</li>
						</ul>
					</div>}
				<div className='right-icons' >
					<ul>
						{localStorage.getItem("headerMenuAdmin") != 1 ?
							<li>
								<NavLink to='/cart' className="third-step">
									<span className='icon-status'>{AppConstants.GetLocalStorage("localStorageCartCount")}</span>
									<img src={icon_cart} alt='icon' />
								</NavLink>
							</li> : ''}
						<li>
							<a style={{ cursor: 'pointer' }}
								title=''
								onClick={(e) => handleClick(e, "notification")}>
								<span className='icon-status'>{notificationDataSource.length}</span>
								<img src={ic_notification} alt='icon' />
							</a>
						</li>
					</ul>
				</div>
				<div className='right-icons' style={{ padding: '0px', marginRight: '25px' }} />
				<div className='account-info fourth-step ' style={{ padding: '0px', marginRight: '15px', }}>
					<a title='' className='MuiButton-label' onClick={(e) => handleClick(e, "profile")}>
						<img src={profile_generic} alt='profile' />
					</a>
					<PopoverComponent
						anchorEl={anchorEl}
						className={`${dropdown === "profile" ? "" : "notification"}`}
						handleClose={handleClose}
						id={"simple-popover"}>
						{dropdown === "profile"
							? <ul className='profile-ul'>
								<li>
									<a onClick={() => handleOpenModal(true)} title='' >
										{<img src={ic_edit} alt="ic_edit" />}
										{"Edit Profile"}
									</a>
								</li>
								{AppConstants.GetMainClassName() == "app-main" && <li>
									<a onClick={() => handleOpenModal(false)} title='' >
										{<img src={ic_setting} alt='icon' />}
										{'Change Password'}
									</a>
								</li>}
								<li>
									<a href="/contactus" title='' >
										{<img src={ic_contactus} alt='icon' />}
										{'Contact Us'}
									</a>
								</li>
								<li onClick={() => AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ budget: '', duration: '0' }]))}>
									<a href="/digital-product/selection/location-selection" title='' >
										{<img src={ic_location} alt='icon' />}
										{'My Locations'}
									</a>
								</li>
								<li>
									<a href='#!' title='' onClick={handleLogout}>
										{<img src={ic_power_btn} alt='icon' />}
										{'Logout'}
									</a>
								</li>
							</ul>
							: dropdown === "coop" ?
								<h6>Co-Op Amount {localStorage.getItem("headerMenuCoOpAmount")}</h6>
								: <>
									<h6>Notifications ({notificationCount})</h6>
									<ul>
										{notificationDataSource.slice(0, 5).map((obj, index) => {
											return <NavLink to={'/notification'} onClick={() => setAnchorEl(null)} >
												<li>
													<a style={{ cursor: 'pointer' }} title=''>
														<div className='left-img'>
															<img src={ic_miscellenous} alt='icon' />
														</div>
														<div className='right-content'>
															<p style={{ width: '80%' }}>
																<Typography noWrap>{obj.Title}</Typography>
															</p>
															<p>
																<small>{obj.TypeModule}</small>
															</p>
															<span className='noti-time'>{obj.NotificationAt}</span>
														</div>
													</a>
												</li>
											</NavLink>
										})}
									</ul>
								</>}
					</PopoverComponent>
					<Modal
						show={IsModalOpen}
						handleClose={handleCloseModal}>
						{IsProfile ? <EditProfile {...props} history={props.history} closeModal={handleCloseModal} />
							: <ChangePassword {...props} closeModal={handleCloseModal} />}
					</Modal>
				</div>
			</div>
		</header>
	);
};

/**
 *  handleLogout method is used to logout user and navigate to login
 * @param {*} props
 */
const handleLogout = (props) => {
	authService.removeAuthData();
	localStorage.clear()
	sessionStorage.clear()
};

export default Header;

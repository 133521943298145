import React, { Component, createRef } from "react";
import { isMacOs } from "react-device-detect";

import Header from "../../features/header/component/header";
import Sidebar from "../../features/sidebar/component/sidebar";
import Sidebaryomi from "../../features/sidebar/component/sidebaryomi";
import { createMuiTheme } from '@material-ui/core/styles';
import { setRef } from "../../shared/services/reference-service";
import Footer from "../../features/footer/component/footer";
import ApplicationTheme from "../../theme/ApplicationTheme";
import * as AppConsatnt from "../../config/AppConstant";
import Tour from 'reactour'

/**
 * Layout - Base layout of application
 */

const steps = [
	{
		selector: '.first-step',
		content: 'You can checkout Latest Brands & Campaigns and Place an Order',
	},
	{
		selector: '.second-step',
		content: 'View Historical Orders here',
	},
	{
		selector: '.third-step',
		content: 'Visit the Cart, Verify and Place the Order',
	},
	{
		selector: '.fourth-step',
		content: 'Get List of Locations and Manage Profile',
	}
]

const Location = window.location.toString();

class Layout extends Component {

	state = {
		innerWidth: window.innerWidth,
		sidebarOpen: true,
		theme: createMuiTheme(ApplicationTheme(this.props.color, this.props.mode, this.props.direction)),
		isTourOpen: AppConsatnt.GetMainClassName() == "app-main" && Location.indexOf('test') != -1 ? true : false,
	};
	constructor(props) {
		super(props);
		this.containerRef = createRef();
	}

	componentDidMount = () => {
		if (this.containerRef.current) {
			setRef(this.containerRef);
		}
		if (isMacOs) {
			document.getElementsByTagName("body")[0].classList.add("mac_device");
		}
		window.addEventListener("resize", this.handleResize);
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.handleResize);
	};

	closeTour() {
		this.setState({ isTourOpen: false })
	}

	render() {
		window.scrollTo(0, 0);

		return (
			// <MuiThemeProvider theme={this.state.theme}>
			<div className='app-container'>
				<div className='app-main-container'>
					<div className='app-header'>
						<Header
							{...this.props}
							handleMenu={this.handleMenuClick}
						/>
					</div>

					<main ref={this.containerRef} className='app-main-content-wrapper'>
						<div className='app-main-content'>
							<div className={`app-wrapper main-sec-wrap ${this.state.sidebarOpen ? "active-main" : ""}`}>
								{AppConsatnt.GetMainClassName() == "app-main" ?
									<Sidebar
										innerWidth={this.state.innerWidth}
										isSidebarOpen={this.state.sidebarOpen}
										handleMenu={this.handleMenuClick}
										handleMenu1={this.handleMenuClick1}
										closeSidebar={this.closeSidebar} />
									: <Sidebaryomi
										innerWidth={this.state.innerWidth}
										isSidebarOpen={this.state.sidebarOpen}
										handleMenu={this.handleMenuClick}
										handleMenu1={this.handleMenuClick1}
										closeSidebar={this.closeSidebar} />}
								<div
									className='main-content'
									onClick={(e) => {
										e.stopPropagation();
										if (this.state.innerWidth <= 991) {
											this.handleMainClick();
										}
									}}>
									{this.props.children}
								</div>

								<section className='bootom-sec'>
									<Footer {...this.props} />
								</section>
							</div>
						</div>
					</main>
				</div>
				<Tour
					rounded={5}
					steps={steps}
					isOpen={this.state.isTourOpen}
					onRequestClose={this.closeTour.bind(this)} />
			</div>
			// </MuiThemeProvider>
		);
	}

	handleResize = () => {
		this.setState({
			innerWidth: window.innerWidth,
		});
		if (window.innerWidth <= 991) { this.closeSidebar() } else { this.handleMenuClick(); }
	};

	/**
	 * handleMenuClick method is used to toggle menu
	 */
	handleMenuClick = () => {
		this.setState(
			{
				sidebarOpen: !this.state.sidebarOpen,
			},
			() => {
				if (this.state.sidebarOpen) {
					document.querySelector("body").classList.add("sidebar");
				} else {
					document.querySelector("body").classList.remove("sidebar");
				}
			}
		);
	};

	closeSidebar = () => {
		this.setState({ sidebarOpen: false })
		document.querySelector("body").classList.remove("sidebar");
	};

	handleMenuClick1 = () => {
		this.setState(
			{
				sidebarOpen: true,
			}
		);
	};

	/**
	 * handleMainclick every time whenever user click somewhere in body
	 * it will verify that if sidebar is open than close it
	 */
	handleMainClick = () => {
		if (this.state.sidebarOpen) {
			this.setState({
				sidebarOpen: false,
			});
			document.querySelector("body").classList.remove("sidebar");
		}
	};
}

export default Layout;

/*eslint-disable eqeqeq*/
import React from 'react';
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import double_left_arrow from '../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../assets/images/right-arrow.svg';

class NotificationScreen extends React.Component {
	state = {
		headerList: ['Title', "Description", " Date Time", "Type Module"],
		currentPage: 0,
		count: 0,
		rowsPerPage: 5,
		rowsPerPageOptions: [5, 10, 25],
		list: [],
		from: 0,
		to: 5,
		progress: true,
		message: "",
		page: Math.floor(0 / 5) + 1,
	};

	componentDidMount() {
		this.wsGetNotifications();
	}

	wsGetNotifications() {
		this.setState({ progress: true, });
		APIController.GetNotifications('5').then((response) => {
			if (response.error == null) {
				this.setState({
					list: response.object.data,
					count: response.object.data.length,
					page: Math.floor(response.object.data.length / 5) + 1,
					progress: false,
				});
			} else if (response.error.message && response.error.message.length > 0) {
				this.handleNotification(response.error.message, true);
			}
		});
	}

	handleApplyFilter = (tag, values, type) => {
		if (values !== "") {
			let searchCountrys = [];
			searchCountrys = this.state.list.filter(
				(us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
			);
			this.setState({
				list: searchCountrys,
				progress: false,
			});
		} else {
			this.wsGetNotifications();
		}
	};

	handleChangeInCurrentPage = (value) => {
		if (value <= this.state.page && Number(parseInt(value))) {
			this.handlePageChange(Number(value));
		}
	}

	render() {
		const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, from, to, page, } = this.state;
		let countryList = list;
		countryList = list.slice(from, to)

		return (
			<>
				<section className='content-right-main'>
					<h1>View Notifications</h1>
					<div className="table-main-wrap countrys-class">
						<Table>
							<TableHead>
								<TableRow>
									{this.state.headerList.map((obj, index) => {
										return <TableCell style={index == 0 ? { width: '35%' } : index == this.state.headerList.length - 1 ? { width: '25%' } : { width: '15%' }} key={index}>{obj}</TableCell>;
									})}
								</TableRow>
								<TableRow>
									<TableCell style={{ width: '35%' }}>
										<input type="text" name="" placeholder="Search Notification" onChange={(e) => this.handleApplyFilter('Title', e.target.value, 'input')} />
									</TableCell>
									<TableCell style={{ width: '25%' }}>
										<input type="text" name="" placeholder="Search Description" onChange={(e) => this.handleApplyFilter('Description', e.target.value, 'input')} />
									</TableCell>
									<TableCell style={{ width: '25%' }}>
										<input type="text" name="" placeholder="Search Notification Date Time" onChange={(e) => this.handleApplyFilter('NotificationDateTime', e.target.value, 'input')} />
									</TableCell>
									<TableCell style={{ width: '15%' }}>
										<input type="text" name="" placeholder="Search Module" onChange={(e) => this.handleApplyFilter('TypeModule', e.target.value, 'input')} />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!!countryList.length ? countryList.map((obj, index) => {
									return (
										<TableRow key={index}>
											<TableCell>
												{obj.Title}
											</TableCell>
											<TableCell>
												<a href={obj.Description} target="_blank" style={{ color: 'blue', cursor: 'pointer' }} >{obj.Description}</a>
											</TableCell>
											<TableCell>
												{obj.NotificationDateTime}
											</TableCell>
											<TableCell>
												{obj.TypeModule}
											</TableCell>
										</TableRow>
									);
								}) : <TableRow >
									<TableCell colSpan={this.state.headerList.length} className="not_found">
										{this.state.progress ?
											<CircularProgress /> :
											<label>Not Currently Available</label>
										}
									</TableCell>
								</TableRow>}
							</TableBody>
						</Table>
						<div className="table-pagination">
							<div className="pagination-info">
								<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
							</div>
							<div className="pagination-control">
								<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										this.handleChangeInCurrentPage(1)
									}}
								><img src={double_left_arrow} alt="left" /></a>
								<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										this.handleChangeInCurrentPage(currentPage - 1)
									}}
								><img src={left_arrow} alt="single_left" /></a>
								<span>Page</span>
								<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => this.handleChangeInCurrentPage(e.target.value)} />
								<span>of {page}</span>
								<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										this.handleChangeInCurrentPage(currentPage + 1)
									}}
								><img src={right_arrow} alt="right" /></a>
								<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										this.handleChangeInCurrentPage(page)
									}}><img src={double_right_arrow} alt="single_right" /></a>
								<select onChange={(e) => this.handleRowsPerPageChange(e.target.value)} defaultValue={rowsPerPage}>
									{
										rowsPerPageOptions.map((obj, index) => {
											return <option value={obj} key={index}>{obj}</option>
										})
									}
								</select>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}


	/**
	 * handleRowsPerPageChange method is used to update rows per page
	 * it will reset page to 1
	 */
	handleRowsPerPageChange = (value) => {
		let from = 0
		let to = value;
		this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
	}

	/**
	 * handlePageChange method is used to change page
	 */
	handlePageChange = values => {
		let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
		let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
		this.setState({ currentPage: Number(values), to: to, from: from })
	}

}


export default NotificationScreen;
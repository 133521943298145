import React from 'react';
import { NavLink } from 'react-router-dom';
import './footer.css';
import * as AppConsatnt from "../../../config/AppConstant";
const Footer = props => {

    return (
        <>
            <footer className="site-footer" >
                <div className='footer-left'>
                    {/* <p>{AppConsatnt.FooterText.TextMain1}<a href="" onClick={() => window.open(AppConsatnt.FooterText.CobrandMediaWebsite)} >{AppConsatnt.FooterText.FooterEmailText}</a>{AppConsatnt.FooterText.TextMain2}</p> */}
                    <p>{AppConsatnt.FooterText.TextMain1}{AppConsatnt.FooterText.FooterEmailText}{AppConsatnt.FooterText.TextMain2}</p>
                </div>
                <div className='footer-right'>
                    <ul>
                        <li><NavLink style={{ color: '#696969' }} to='/privacypolicy'>{AppConsatnt.FooterText.LinkText1}</NavLink></li>
                        <li><NavLink style={{ color: '#696969' }} to='/privacypolicy'>{AppConsatnt.FooterText.LinkText2}</NavLink></li>
                        <NavLink style={{ color: '#696969' }} to='/contactus'>{AppConsatnt.FooterText.LinkText3}</NavLink>
                    </ul>
                </div>
            </footer>
        </>
    );
}
export default Footer;
/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';

const ConfigurationTab = props => {
    const initialValues = {
        PageURL: props.GetProductData[0].PageURL, MenuPath: props.GetProductData[0].MenuPath, CustomizePath: props.GetProductData[0].CustomizePath, ThemeButtons: props.GetProductData[0].ThemeButtons,
        showaddtocart: props.GetProductData[0].ShowAddToCart, showcopytext: props.GetProductData[0].ShowCopyText,
        required: props.GetProductData[0].ShowAdBuilder ? 1 : 0, showdownload: props.GetProductData[0].ShowDownload ? 1 : 0,
        isprint: props.GetProductData[0].IsPrintProduct ? 1 : 0
    }

    const [IsLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsConfigureProduct = (ID, PageURL, MenuPath, CustomizePath, ThemeButtons, showaddtocart, showcopytext, required, showdownload, isprint) => {
        setIsLoading(true)
        APIController.ConfigureProduct(ID, PageURL, MenuPath, CustomizePath, ThemeButtons, showaddtocart, showcopytext, required, showdownload, isprint)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message)
                    if (response.object.status == 1) {
                        props.history.push({ pathname: '/product', state: { showMessage: response.object.message } })
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    return (
        <>
            <section className="tab-content asset-tab-wrapper">
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateProductForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsConfigureProduct(props.history.location.state.productIdEdit,
                            values.PageURL, values.MenuPath, values.CustomizePath, values.ThemeButtons,
                            values.showaddtocart, values.showcopytext, values.required, values.showdownload, values.isprint)
                    }}
                >
                    {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                        return (
                            <div className="form-main" >
                                <form onSubmit={handleSubmit}>

                                    <div className={`form_field ${errors && touched.PageURL && errors.PageURL && errors.PageURL !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Page URL</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Page URL" name="PageURL" value={values.PageURL} />
                                            {errors && touched.PageURL && errors.PageURL && errors.PageURL !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='PageURL' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.MenuPath && errors.MenuPath && errors.MenuPath !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Menu Path</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Menu Path" name="MenuPath" value={values.MenuPath} />
                                            {errors && touched.MenuPath && errors.MenuPath && errors.MenuPath !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='MenuPath' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field`}>
                                        <div className="field-left"> <label>Customize Path</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Customize Path" name="CustomizePath" value={values.CustomizePath} />
                                        </div>
                                    </div>
                                    <div className={`form_field`}>
                                        <div className="field-left"> <label>Theme Buttons</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Theme Buttons" name="ThemeButtons" value={values.ThemeButtons} />
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Show Add To Cart ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="showaddtocart" id="showaddtocart-yes-text"
                                                    defaultValue={values.showaddtocart}
                                                    defaultChecked={values.showaddtocart != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showaddtocart', 1)} />
                                                <label htmlFor="showaddtocart-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="showaddtocart" id="showaddtocart-no-text"
                                                    defaultValue={values.showaddtocart}
                                                    defaultChecked={values.showaddtocart == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showaddtocart', 0)} />
                                                <label htmlFor="showaddtocart-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Show Copy Text ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="showcopytext" id="showcopytext-yes-text"
                                                    defaultValue={values.showcopytext}
                                                    defaultChecked={values.showcopytext != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showcopytext', 1)} />
                                                <label htmlFor="showcopytext-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="showcopytext" id="showcopytext-no-text"
                                                    defaultValue={values.showcopytext}
                                                    defaultChecked={values.showcopytext == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showcopytext', 0)} />
                                                <label htmlFor="showcopytext-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Required AdBuilder ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="required" id="required-yes-text"
                                                    defaultValue={values.required}
                                                    defaultChecked={values.required != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('required', 1)} />
                                                <label htmlFor="required-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="required" id="required-no-text"
                                                    defaultValue={values.required}
                                                    defaultChecked={values.required == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('required', 0)} />
                                                <label htmlFor="required-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Show Download ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="showdownload" id="download-yes-text"
                                                    defaultValue={values.showdownload}
                                                    defaultChecked={values.showdownload != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showdownload', 1)} />
                                                <label htmlFor="download-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="showdownload" id="download-no-text"
                                                    defaultValue={values.showdownload}
                                                    defaultChecked={values.showdownload == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showdownload', 0)} />
                                                <label htmlFor="download-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Print Product ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="isprint" id="print-yes-text"
                                                    defaultValue={values.isprint}
                                                    defaultChecked={values.isprint != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('isprint', 1)} />
                                                <label htmlFor="print-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="isprint" id="print-no-text"
                                                    defaultValue={values.isprint}
                                                    defaultChecked={values.isprint == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('isprint', 0)} />
                                                <label htmlFor="print-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />

                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                        </div>

                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateProductForm = yup.object().shape({
    PageURL: yup.string().required('Page URL is required'),
    MenuPath: yup.string().required('Menu Path is required'),
});
export default ConfigurationTab;
/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../../../api/APIController.js';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PageTitle from "../../../../config/pageTitle";
import * as AppConsatnt from "../../../../config/AppConstant";
import PerfectScrollbar from "react-perfect-scrollbar";


import monthlycalendar from '../../../../assets/images/monthlycalendar.svg'
import ic_orders from '../../../../assets/images/ic_orders.svg'
import filtericon from '../../../../assets/images/ic_feather_filter.svg'

import Modal from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';
import SubscriptionForm from './subscriptionForm';
import SubscriptionOrderList from './subscriptionOrderList';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const SubscriptionList = (props) => {
	const headerList = ['action', "Subscription No", "Practice Name", "Address", "Subscribed At", "Start Date", "Next Renewal Date", "Cost", "Status"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	countryList = list.slice(from, to)
	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState('');
	const [CalendarId, setCalendarId] = React.useState(0);

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const wsDeleteCountry = (ID) => {
		APIController.DeleteCountry(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						props.handleNotification(response.object.message);
					} else {
						props.handleNotification(response.object.message, true);
					}
					props.handlebind()
					setLoginDialog(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					props.handleNotification(response.error.message)
				}
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}
	return (
		<>
			<h1><PageTitle Title="View Subscription" /></h1> <div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => {
									return <TableCell style={index == 0 ? { minWidth: "10%" } : index == index.length - 1 ? { width: '10%' } : { width: '80%' }} className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow>
								<TableCell> <span><img src={filtericon} alt="delete" /></span> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Subscription No" onChange={(e) => props.applyFilter('SubscriptionNo', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Practice Name" onChange={(e) => props.applyFilter('Practice', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Address" onChange={(e) => props.applyFilter('Address', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Subscribed At" onChange={(e) => props.applyFilter('SubscribedAt', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Start Date" onChange={(e) => props.applyFilter('StartDate', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Next Renewal Date" onChange={(e) => props.applyFilter('NextRenewalDate', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Cost" onChange={(e) => props.applyFilter('PaidAmount', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Status" onChange={(e) => props.applyFilter('SubscriptionStatus', e.target.value, 'input')} /> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: "95px" }}>
											<span>
												<img src={ic_orders} alt="edit" style={{ cursor: 'pointer', marginRight: '10px', width: "17px" }}
													onClick={() => {
														// props.history.push({
														// 	pathname: '/subscription/subscriptionOrders',
														// 	state: { list: countryList, countryNameEdit: obj.Name, countryActiveEdit: obj.IsActiveLABEL === "YES" ? 1 : 0 }
														// })
														props.handleShowList(true)
													}} />
												{/* <img src={monthlycalendar} alt="delete" style={{ cursor: 'pointer' }} onClick={() => { setDeleteId(""); setCalendarId(obj.ID); setLoginDialog(true); }} /> */}
											</span>
										</TableCell>
										<TableCell>{obj.SubscriptionNo}</TableCell>
										<TableCell>{obj.Practice}</TableCell>
										<TableCell style={{ minWidth: "250px" }}>{obj.Address}</TableCell>
										<TableCell>{obj.SubscribedAt}</TableCell>
										<TableCell style={{ minWidth: "180px" }}>{obj.StartDate}</TableCell>
										<TableCell style={{ minWidth: "180px" }}>{obj.NextRenewalDate}</TableCell>
										<TableCell>{obj.PaidAmount}</TableCell>
										<TableCell>{obj.SubscriptionStatus}</TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
			{LoginDialog && (
				<Modal
					show={LoginDialog}
					handleClose={() => setLoginDialog(false)}>

					{/* <SubscriptionOrderList
						{...props}
						history={props.history}
						handlebind={props.handlebind.bind(this)}
						handleChangeRowsPerPage={props.handleRowsPerPageChange}
						handleChangePage={props.handlePageChange}
						applyFilter={props.handleApplyFilter}
						handleNotification={props.handleNotification}
					/> */}

					{/* <SubscriptionForm
						closeModal={() => setLoginDialog(false)}
						campaignAsset={""}
						titleName={"Subscription Orders"} /> */}
				</Modal>
			)}
			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default SubscriptionList;

/*eslint-disable eqeqeq*/
import React from "react";

import * as APIController from '../../../api/APIController.js';

import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";
import Notification from '../../../shared/components/notification/notification';
import CustomCalendar from './CustomCalendar'

import { PayPalButton } from "react-paypal-button-v2";

const CalendarMain = (props) => {

    const [CalendarAssetsData, setCalendarAssetsData] = React.useState([]);
    const [AssetsDataFinal, setAssetsDataFinal] = React.useState([]);
    const [CalendarForPractice, setCalendarForPractice] = React.useState([]);
    const [CalendarBrands, setCalendarBrands] = React.useState([]);
    const [BrandIDs, setBrandIDs] = React.useState('');
    const [AutomationCalendarID, setAutomationCalendarID] = React.useState("");
    const [CalendarYear, setCalendarYear] = React.useState("");
    const [CalendarMonth, setCalendarMonth] = React.useState("");
    const [FBPageData, setFBPageData] = React.useState("");
    const [PMonthData, setPMonthData] = React.useState([]);
    const [AutomationSubscriptionID, setAutomationSubscriptionID] = React.useState(AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"));
    const [PayPalButtonShow, setPayPalButtonShow] = React.useState(false);
    const [PracticeID, setPracticeID] = React.useState(AppConstants.GetLocalStorage("localStoragepracticeId"));
    const [PracticeDetails, setPracticeDetails] = React.useState("");
    const [OrderByName, setOrderByName] = React.useState("");
    const [AddCartBtn, setAddCartBtn] = React.useState(false);
    const [calenderViewFromCart, setCalenderViewFromCart] = React.useState(AppConstants.GetLocalStorage("localStorageCalenderCart"));

    React.useEffect(() => {
        if (AppConstants.GetLocalStorage('localStorageFBPageData') != ""
            && AppConstants.GetLocalStorage('localStorageFBPageData') != undefined
            && AppConstants.GetLocalStorage('localStorageFBPageData') != null) {
            setFBPageData(JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData')))
        }
        if (props.history.location.state.calendarIdEdit != ""
            && props.history.location.state.calendarIdEdit != undefined
            && props.history.location.state.calendarIdEdit != null) {
            setAutomationCalendarID(props.history.location.state.calendarIdEdit)
            wsGetAutomationCalendarAssets(props.history.location.state.calendarIdEdit, props.history.location.state.calendarYearEdit, props.history.location.state.calendarMonthEdit)
        } else {
            setCalendarBrands(AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')))
            wsGetAutomationCalendarForPractice(AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')), PracticeID)
        }
        let Data = []
        AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData'))
            .map((item1) => {
                item1.Checked && Data.push(item1.ID)
                // JSON.parse(item.CalendarAssets).map((itm) => {
                //     item1.ID == itm.BrandID && Data.push(itm);
                // })
            })
        setBrandIDs(Data)
    }, [props])

    const handlebind = (AutomationCalendarID, calendarYearEdit, calendarMonthEdit) => {
        wsGetAutomationCalendarAssets(AutomationCalendarID, calendarYearEdit, calendarMonthEdit);
    }

    const handleAssetsData = (AssetsData) => {
        let arrayData = []
        AssetsData.map((item) => arrayData.push({ "ID": item.ID, "Status": item.Status }))
        setAssetsDataFinal(arrayData)
        setCalendarAssetsData(AssetsData)
    }

    const handleAssetsParMonthData = (currentMonth, currentYear) => {
        let Data = []
        CalendarForPractice.map((item) => {
            CalendarBrands.length && CalendarBrands.map((item1) => {
                item1.Checked &&
                    JSON.parse(item.CalendarAssets).map((itm) => {
                        item1.ID == itm.BrandID && currentMonth == item.CalendarMonth && Data.push(itm);
                    })
            })
            setCalendarMonth(currentMonth)
            setCalendarYear(currentYear)
            setCalendarAssetsData(Data)
            currentMonth == item.CalendarMonth && setAutomationCalendarID(item.CalendarID)
        })
        let arrayData = []
        Data.map((item) => arrayData.push({ "ID": item.ID, "Status": item.Status }))
        setAssetsDataFinal(arrayData)
        // setPayPalButtonShow(false)
    }

    const wsGetAutomationCalendarAssets = (AutomationCalendarID, calendarYearEdit, calendarMonthEdit) => {
        APIController.GetAutomationCalendarAssets(AutomationCalendarID)
            .then((response) => {
                if (response.error == null) {
                    setCalendarAssetsData(response.object.data)
                    setCalendarYear(calendarYearEdit)
                    setCalendarMonth(calendarMonthEdit)
                }
                else {
                    handleNotification(response.object.message, true);
                }
            })
    }

    const wsGetAutomationCalendarForPractice = (BrandsData, PracticeID) => {
        let arrayData = []
        let DATA = []
        let monthData = []
        APIController.GetAutomationCalendarForPractice(PracticeID)
            .then((response) => {
                if (response.object.data.length) {
                    setAutomationCalendarID(response.object.data[0].CalendarID)
                    setCalendarYear(response.object.data[0].CalendarYear)
                    setCalendarMonth(response.object.data[0].CalendarMonth)

                    BrandsData.length && BrandsData.map((item1) => {
                        item1.Checked && JSON.parse(response.object.data[0].CalendarAssets).map((itm) => {
                            item1.ID == itm.BrandID && arrayData.push({ "ID": itm.ID, "Status": itm.Status });
                            item1.ID == itm.BrandID && DATA.push(itm)
                        })
                        setAssetsDataFinal(arrayData)
                        setCalendarAssetsData(DATA)

                    })

                    if (response.object.data.length > 1) {
                        response.object.data.map((item, index) => {
                            monthData.push({ MonthData: item.CalendarMonth })
                        })

                        setPMonthData(monthData)
                        setCalendarForPractice(response.object.data)
                    }
                }
            })
    }

    const wsAddEditAutomationSubscription = (ID, AutomationCalendarID, CampaignID, PracticeID, FacebookID, Facebookusername, FacebookAccess_Token,
        InstagramID, Instagramusername, InstagramAccess_Token, BrandIDs, Assets) => {
        setAddCartBtn(true)
        APIController.AddEditAutomationSubscription(ID, AutomationCalendarID, CampaignID, PracticeID, FacebookID, Facebookusername, FacebookAccess_Token,
            InstagramID, Instagramusername, InstagramAccess_Token, BrandIDs, Assets)
            .then((response) => {
                if (response.object.status == "1") {
                    handleNotification(response.object.message);
                    AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", response.object.data[0].ID)
                    AppConstants.SetLocalStorage("localStorageSubscriptionPracticeID", PracticeID)
                    setTimeout(() => {
                        props.history.push('/cart')
                        setAddCartBtn(false)
                    }, 4000)
                    // setAutomationSubscriptionID(response.object.data[0].ID)
                    // wsGetPracticeByID(PracticeID)
                } else {
                    handleNotification(response.object.message, true);
                    // setPayPalButtonShow(false)
                    setAddCartBtn(false)
                }
            })
    }

    const wsGetPracticeByID = (ID) => {
        let Data = []
        APIController.GetPracticeByID(ID)
            .then((response) => {
                if (response.error == null) {

                    Data = {
                        "name": {
                            "given_name": response.object.data[0].FirstName,
                            "surname": response.object.data[0].LastName
                        },
                        "email_address": response.object.data[0].EmailAddress,
                        "shipping_address": {
                            "name": {
                                "full_name": response.object.data[0].FirstName + " " + response.object.data[0].LastName
                            },
                            "address": {
                                "address_line_1": response.object.data[0].Address1,
                                "address_line_2": response.object.data[0].Address2,
                                "admin_area_2": response.object.data[0].City,
                                "admin_area_1": response.object.data[0].State,
                                "postal_code": response.object.data[0].Postcode,
                                "country_code": "US"
                            }
                        }
                    }
                    setOrderByName(response.object.data[0].FirstName + " " + response.object.data[0].LastName)
                    setPracticeDetails(Data)
                    // setPayPalButtonShow(true)
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            "plan_id": "P-9JP711594E0846536MIBGGVY",
            "custom_id": AutomationSubscriptionID,
            "subscriber": PracticeDetails
        });
    };

    const paypalOnError = (err) => {
        console.log("Error", err)
    }
    const paypalOnApprove = (data, detail) => {
        // call the backend api to store transaction details
        console.log("Payapl approved", data)
        props.history.push({
            pathname: '/paymentSuccess', state: { AutomationSubscriptionID: AutomationSubscriptionID, OrderByName: OrderByName }
        })
    };

    return (
        <section className='content-right-main'>

            <PageTitle Title={AppConstants.ContactDetais.CalendarHeading} />
            <div className="two-col-sec digital-welcome">
                <div style={{ width: '100%', height: "100%" }}>
                    <CustomCalendar
                        CalendarAssetsData={CalendarAssetsData}
                        // CalendarForPractice={CalendarForPractice}
                        CalendarBrands={CalendarBrands}
                        CalendarYear={CalendarYear}
                        CalendarMonth={CalendarMonth}
                        PMonthData={PMonthData}
                        AutomationCalendarID={AutomationCalendarID}
                        handlebind={handlebind.bind(this)}
                        handleAssetsData={handleAssetsData.bind(this)}
                        handleAssetsParMonthData={handleAssetsParMonthData.bind(this)}
                        history={props.history} />

                </div>
                {localStorage.getItem("headerMenuAdmin") != 1 &&

                    // <>{PayPalButtonShow ? <div className='paypal-button-container'>
                    //     <PayPalButton
                    //         amount="0.01"
                    //         options={{
                    //             vault: true
                    //         }}
                    //         currency="USD"
                    //         createSubscription={paypalSubscribe}
                    //         onApprove={paypalOnApprove}
                    //         catchError={paypalOnError}
                    //         onError={paypalOnError}
                    //         onCancel={paypalOnError}
                    //         style={{ shape: 'rect', color: 'blue', layout: 'horizontal', label: 'subscribe', Size: 'medium', height: 43, tagline: false }}
                    //     />
                    // </div> :

                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {calenderViewFromCart == '1' ? <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                            <input type='button' name='button' value={AppConstants.ButtonText.Back} onClick={() => props.history.goBack()} />
                        </div>
                            : <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                                <input type='button' name='button' value={AppConstants.ButtonText.BackToCart} onClick={() => props.history.goBack()} />
                            </div>}
                        {calenderViewFromCart == '1' &&
                            <div className={`form-field button-field ${AddCartBtn ? "disable-btn" : ""}`} style={{ marginBottom: '20px' }}>
                                <input type="button" name="edit-profile" value={AddCartBtn ? "Saving... Subscription" : "Save Subscription"} onClick={() => {
                                    wsAddEditAutomationSubscription(AutomationSubscriptionID == "" ? "0" : AutomationSubscriptionID, AutomationCalendarID, AppConstants.GetLocalStorage('localStorageCampaignID'), PracticeID,
                                        FBPageData.id, FBPageData.name, FBPageData.access_token,
                                        "", "", "", BrandIDs.toString(), AssetsDataFinal)
                                }} />
                            </div>}
                    </div>
                    // }</>
                }
            </div >
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </section >
    );
};

export default CalendarMain;
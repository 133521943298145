/*eslint-disable eqeqeq*/
import React from "react";
import error_ic from "../../../assets/images/error-icon.svg";
import check_ic from "../../../assets/images/check-icon.svg";
import red_close_ic from "../../../assets/images/red-close-ic.svg";
import green_close_ic from "../../../assets/images/green-close-ic.svg";


class Notification extends React.Component {
	render() {
		return (
			<>
				{this.props.showNotification && (
					<div
						className={
							this.props.MessageLocation === "bottom"
								? "form-messages_Bottom"
								: this.props.MessageLocationLogin == "LoginToast"
									? "form-messages_BottomLogin"
									: "form-messages"
						}>
						{this.props.isError ? (
							<div className='messages-text error-msg-text'>
								<p style={{ marginRight: "20px" }}>
									<img src={error_ic} alt='icon' />
									{/* <strong>Error:</strong> */}
									 {this.props.message}
								</p>
								<img
									src={red_close_ic}
									className='alert-close-ic'
									alt='close-ic'
									onClick={this.props.closeNotification}
								/>
							</div>
						) : (
								<div className='messages-text success-msg-text'>
									<p style={{ marginRight: "20px" }}>
										<img src={check_ic} alt='icon' />
										{/* <strong>Success:</strong>  */}
										{this.props.message}
									</p>
									<img
										src={green_close_ic}
										className='alert-close-ic'
										alt='close-ic'
										onClick={this.props.closeNotification}
									/>
								</div>
							)}
					</div>
				)}
			</>
		);
	}
}

export default Notification;

import React from "react";

import PageTitle from "../config/pageTitle";

import Loginlogo from "../assets/images/main.png";
import Logo from "../assets/images/logo.svg";
import VSPPremier from "../assets/images/vsp_premier_logo.png";
import VisionSource from "../assets/images/vision_source_logo.png";

import Loginlogoyomi from "../assets/images/logoyomi.png";
import LogoYomi from "../assets/images/logo1yomi.png";

import slider_img1 from "../assets/images/slider-img1.jpg";
import slider_img2 from "../assets/images/slider-img2.jpg";
import slider_img3 from "../assets/images/slider-img3.jpg";
import slider_img4 from "../assets/images/slider-img4.jpg";
import slider_img5 from "../assets/images/slider-img5.jpg";

import slider_setpassword_img1 from "../assets/images/slider-img.png";

import home_img from "../assets/images/WelcomtoREACHgraphic.jpg";
import home_img1 from "../assets/images/welcomerightimage.png";

import slider_imgymoi1 from "../assets/images/slider-imgyomi1.png";
import slider_imgyomi2 from "../assets/images/slider-imgyomi2.png";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfigCobrand = {
    apiKey: "AIzaSyC2t9CdE-1AnmULws5AduWetVuJCHNSROw",
    authDomain: "cobrandportal.firebaseapp.com",
    databaseURL: "https://cobrandportal.firebaseio.com",
    projectId: "cobrandportal",
    storageBucket: "cobrandportal.appspot.com",
    messagingSenderId: "950530478013",
    appId: "1:950530478013:web:0e19032ec2ea34d5ad4cd4",
    measurementId: "G-N5JPRL2RF1"
};
const firebaseConfigYomi = {
    apiKey: "AIzaSyA-olU2FDw_uqQOIh5LtLXitBbUZPIMKVU",
    authDomain: "yomi-connect.firebaseapp.com",
    projectId: "yomi-connect",
    storageBucket: "yomi-connect.appspot.com",
    messagingSenderId: "70035989202",
    appId: "1:70035989202:web:2738483bef74a29c44c30b",
    measurementId: "G-MGC375956E"
};

export const initializeAnalytics = () => {
    const app = GetMainClassName() == "app-main" ? initializeApp(firebaseConfigCobrand) : initializeApp(firebaseConfigYomi);
}
export const LogEvent = (EventName, Params) => {
    const app = GetMainClassName() == "app-main" ? initializeApp(firebaseConfigCobrand) : initializeApp(firebaseConfigYomi);
    const analytics = getAnalytics();
    logEvent(analytics, EventName, Params);
}

export const CreateLogEventObject = (Practice, Brand, Campaign, Product, Location, Theme, Category, Subcategory, FreeAsset, EmailMarketing, SocialAutomation, Amount) => {
    return {
        'practice': Practice,
        'brand': Brand,
        'campaign': Campaign,
        'product': Product,
        'location': Location,
        'theme': Theme,
        'category': Category,
        'subcategory': Subcategory,
        'freeasset': FreeAsset,
        'emailmarketing': EmailMarketing,
        'socialautomation': SocialAutomation,
        'amount': Amount,
    }
}

export const GetMainClassName = () => {
    if (window.location.origin.indexOf("mybauschreach.com") > 0 || window.location.origin == "http://localhost:3001" || window.location.origin == "http://192.168.1.8:3001") {
        return 'app-main'
    }
    else if (window.location.origin.indexOf("yomiconnect.com") > 0 || window.location.origin == "http://localhost:3000" || window.location.origin == "http://192.168.1.8:3000") {
        return 'app-yomi'
    }
}

export const SetLocalStorage = (Key, Value) => {
    localStorage.setItem(Key, Value)
}

export const GetLocalStorage = (Key) => {
    return localStorage.getItem(Key) || '';
}

export const GetPageTitle = () => {
    var PageTitle = GetPageTitleFromURL(GetMainClassName().replace('app-', ''), window.location.pathname);
    if (PageTitle.indexOf('#') != -1) {
        PageTitle = PageTitle.replace('#CampaignName#', GetLocalStorage('localStorageCampaignName'));
        PageTitle = PageTitle.replace('#ProductName#', GetLocalStorage('localStorageProductName'));
        PageTitle = PageTitle.replace('#BrandName#', GetLocalStorage('localStorageBrandName'));
    }
    return PageTitle;
}

export const GetPageTitleFromURL = (TenantName, PageURL) => {
    if (TenantName == 'main') {
        switch (PageURL) {
            case '/digital-product/selection':
                return '#BrandName# #CampaignName#';
            case '/digital-product/selection/digital-bundle':
                return '#CampaignName# #ProductName#';
            case '/digital-product/selection/campaign':
                return 'Step 1: Select your Campaign Theme';
            default:
                break;
        }
    } else if (TenantName == 'yomi') {
        switch (PageURL) {
            case '/digital-product/selection':
                return 'Select a Product to Get Started:';
            case '/digital-product/selection/campaign':
                return 'Step 1: Select your Campaign Theme';
            default:
                break;
        }
    }
}

export const ShowPageTitle = () => {
    return (<PageTitle Title={GetPageTitle()} />)
}

export const LoginLogo = GetMainClassName() == "app-main" ? window.location.toString().split('/').pop() == "vsp" ? VSPPremier : window.location.toString().split('/').pop() == "vs" ? VisionSource : Loginlogo : LogoYomi;
export const HeaderLogo = GetMainClassName() == "app-main" ? Logo : LogoYomi;
export const VSPPremierLogo = GetMainClassName() == "app-main" ? Loginlogo : Loginlogoyomi;
export const HomepageURL = GetMainClassName() == "app-main" ? "/home" : "/digital-product/selection";

export const BLLoginSlider = GetMainClassName() == "app-main" ?
    {
        HeadingText: 'Welcome to Bausch + Lomb Reach', PtagText: "A practice marketing platform with tools to help you reach patients through innovative technology",
        Images: [{ ImageUrl: slider_img5 }, { ImageUrl: slider_img2 }, { ImageUrl: slider_img3 }, { ImageUrl: slider_img4 }]
        // Images: [{ ImageUrl: slider_img5 }]
    } : { Images: [{ ImageUrl: slider_imgymoi1 }, { ImageUrl: slider_imgyomi2 }] }
export const BLSetPasswordSlider = GetMainClassName() == "app-main" ?
    {
        HeadingText: 'Welcome to Bausch + Lomb Reach', PtagText: "A practice marketing platform with tools to help you reach patients through innovative technology",
        Images: [{ ImageUrl: slider_setpassword_img1 }, { ImageUrl: slider_setpassword_img1 }, { ImageUrl: slider_setpassword_img1 }]
        // Images: [{ ImageUrl: slider_img5 }]
    } : { Images: [{ ImageUrl: slider_imgymoi1 }, { ImageUrl: slider_imgyomi2 }] }

export const BLHomeSlider = { Images: [{ ImageUrl: home_img }, { ImageUrl: home_img1 }] }

export const ContactDetais = GetMainClassName() == "app-main" ? {
    ViewCountries: 'View Countries',
    HomePageTitle: 'Welcome to Bausch + Lomb REACH',
    Step1Select: 'Step 1: Select your Campaign Theme',
    Step1Choose: 'Step 1: Choose Your Postcard',
    Step2Select: 'Step 2: Select your Budget and Campaign Duration',
    Step2Confirm: 'Step 2: Select Location(s) for Your Campaign',
    Step2Upload: 'Step 4: Upload Your Mailing List',
    Step3Select: 'Step 3: Select Location(s) for Your Campaign',
    Step3Confirm: 'Step 3: Confirm Your Practice Info for Your Postcard',
    MyLocationTitle: 'My Locations',
    ConfirmLocationTitle: 'Select Your Main Location',
    CartLocationTitle: 'Select Location(s) for Your Campaign',
    Step3Artwork: 'Step 3: Approve Your Campaign Artwork',
    Step4Artwork: 'Step 4: Approve Your Campaign Artwork',
    YourCart: 'Your Cart',
    YourOrders: 'Your Orders',
    OrderDetails: 'Order Details - #',
    NoOrderFound: 'No Order Found',
    HowToReachUs: 'How To Reach Us',
    PrivacyPolicyTitle: 'COBRAND MEDIA ONLINE TERMS AND CONDITIONS',
    CalendarHeading: 'Social Media Calendar',
    AssetDownloadReport: 'Asset Download Report',
    ContestSummaryReport: 'Contest Summary Report',
    RegistrationsReport: 'Registrations Report',
    SocialAutomationReport: 'Social Automation Report',
    CampaignOrdersReport: 'Campaign Orders Report',
    EmailMarketingReport: 'Email Marketing Report'
} : {
    Step1Select: "Step 1: Select ",
    MyLocationTitle: 'My Locations',
    Step2locationYomi: 'Step 2: Customize Your Postcard:',
    Step2locationAssetYomi: 'Step 2: Customize Your Asset',
    Step3ADYomi: 'Step 3: Approve Your Campaign Artwork:',
    Step3Yomi: 'Step 3: Approve your Asset',
    Step2Upload: 'Step 4: Select a Direct Mail Postcard:',
    YourCart: 'Your Cart',
    YourOrders: 'Your Orders',
    AssetDownloadReport: 'Asset Download Report',
    ContestSummaryReport: 'Contest Summary Report',
    RegistrationsReport: 'Registrations Report',
    SocialAutomationReport: 'Social Automation Report',
    CampaignOrdersReport: 'Campaign Orders Report',
    EmailMarketingReport: 'Email Marketing Report'
}

export const CommanText = GetMainClassName() == "app-main" ? {
    ContactUsLine1: "Kindly contact your Bausch + Lomb sales representative for more information about the marketing services available to your practice.",
    ContactUsTerritory: "Vision Care Territory Manager",
    ContactUsLine2: "For questions regarding access and use of the Bausch + Lomb REACH portal, including technical support, please contact: ",
    ContactUsLine3: "Customer Support ",
    ContactUsEmail1: "support@bauschreach.com"
} : {
    ContactUsLine1: "Kindly contact your Yomi Clinical Sales Representative for more information about the clinical services available to your practice.",
    ContactUsTerritory: "Clinical Sales Representative",
    ContactUsLine2: "For questions regarding access and use of the Yomi Connect portal, please contact: ",
    ContactUsLine3: "For Yomi Connect portal technical support, please contact: ",
    ContactUsEmail1: "yomi_portal@neocis.com",
    ContactUsEmail2: "support@yomiconnect.com",
}

export const FooterText = GetMainClassName() == "app-main" ? {
    TextMain1: "Copyright © " + new Date().getFullYear(),
    TextMain2: ". All Rights Reserved. VCCM.0030.USA.22",
    FooterEmailText: " Bausch + Lomb",
    LinkText1: "Terms of Use",
    LinkText2: "Privacy Policy",
    LinkText3: "Contact Us",
    CobrandMediaWebsite: "https://cobrandmedia.com"
} : {
    TextMain1: "Copyright © " + new Date().getFullYear(),
    TextMain2: ". All Rights Reserved.",
    FooterEmailText: " Neocis",
    LinkText1: "Terms of Use",
    LinkText2: "Privacy Policy",
    LinkText3: "Contact Us",
    CobrandMediaWebsite: "https://www.neocis.com/"
}

export const ButtonText = {
    GetStarted: "Get Started",
    Back: 'Back',
    Exit: 'Exit',
    Order: 'Order',
    Customize: 'Customize',
    Preview: 'Preview',
    Continue: 'Continue',
    Delete: 'Delete',
    Upload: 'Upload',
    ConfirmUpdates: 'Confirm Updates',
    AddToCart: 'Add To Cart',
    AddingtoCart: 'Adding to Cart...',
    BackToCart: 'Back To Cart',
    UpdateCart: 'Update Cart',
    UpdatingCart: 'Updating Cart...',
    CopyHTML: 'Copy HTML',
    Done: 'Done',
    CopytoClipboard: 'Copy to Clipboard',
    Download: 'Download',
    Downloading: 'Downloading ...',
    CopyText: 'Copy Text',
    DownloadPostcard: 'Download Postcard',
    SendToPrinter: 'Send To Printer',
}

export const PageHeading = GetMainClassName() == "app-main" ? { HeadingText: "" } : { HeadingText: "" }
import React from "react";
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import "./welcome-screen.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenuItem from '@material-ui/core/MenuItem';
import Slider from "react-slick";
import * as AppConstants from "../../../config/AppConstant";
import Timezone from './Timezone.json'
import ProductTabSelection from './product-tab-selection';

import { Formik, Field } from "formik";
import TextField from "../../../shared/components/TextField/TextField";

import FacebookLogin from 'react-facebook-login';
// import { InstagramLogin } from "@amraneze/react-instagram-login";
import { InstagramLogin } from "react-instagram-oauth";


import ModalComponent from "../../../shared/components/modal/modal";
import close_icon from "../../../assets/images/close-ic.svg";

const SocialAutomation = (props) => {
	const [Description, setDescription] = React.useState('');
	const [Loading, setLoading] = React.useState(true)
	const [ProductImages, setProductImages] = React.useState([]);
	const [GetBrands, setGetBrands] = React.useState([]);
	const [ChildrenTabs, setChildrenTabs] = React.useState([]);
	const [duration, setDuration] = React.useState('');
	const [FacebookPageName, setFacebookPageName] = React.useState('');
	const [InstagramPageName, setInstagramPageName] = React.useState('');
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [facebookverified, setfacebookverified] = React.useState(false);
	const [facebookPopup, setfacebookPopup] = React.useState(false);
	const [instagramverified, setinstagramverified] = React.useState(false);
	const [SelectedBrands, setSelectedBrands] = React.useState("");
	const [SelectFBPageID, setSelectFBPageID] = React.useState([]);
	const [facebookPages, setFacebookPages] = React.useState([]);
	// const [FBPageData, setFBPageData] = React.useState(JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData')));


	React.useEffect(() => {
		let data = AppConstants.GetLocalStorage("localStorageDuration") != '' && JSON.parse(AppConstants.GetLocalStorage("localStorageDuration"));
		wsGetProductByID(AppConstants.GetLocalStorage('localStorageProductID'))
		setChildrenTabs(JSON.parse(AppConstants.GetLocalStorage('localStorageChildren')))

		setDuration(data != '' ? data[0].Duration : '')
		if (AppConstants.GetLocalStorage('localStorageFBPageData') != ""
			&& AppConstants.GetLocalStorage('localStorageFBPageData') != undefined
			&& AppConstants.GetLocalStorage('localStorageFBPageData') != null) {
			setfacebookverified(true)
			setFacebookPageName(JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData')).name)
		}
	}, [props]);

	const timezonedata = Timezone.map((item) => {
		return (
			<MenuItem value={item.offset}>{item.text}</MenuItem>
		)
	})

	const wsGetProductByID = (ID) => {
		APIController.GetProductByID(ID)
			.then((response) => {
				if (response.error == null) {
					setDescription(response.object.data[0].Description)
					wsGetProductImages(response.object.data[0].ID, AppConstants.GetLocalStorage('localStorageBrandID'))
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message);
				}
			})
	}

	const wsGetProductImages = (ID, BrandID) => {
		APIController.GetProductImages(ID, BrandID)
			.then((response) => {
				if (response.error == null) {
					setProductImages(response.object.data)
					wsGetBrand()
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message, true);
				}
			})
	}

	const wsGetBrand = () => {
		APIController.GetBrand("1", "AutomationOrderNo", "ASC")
			.then((response) => {
				if (response.error == null) {
					let finaldata = response.object.data
					let Temp = []
					finaldata.map((item) => {
						Temp.push({ 'Checked': true, ID: item.ID, Name: item.Name })
					})
					setGetBrands(Temp)
				}
				setLoading(false)
			})
	}

	const handleCheckList = (ID) => {
		setGetBrands(GetBrands.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked }))
	};

	const settings = {
		dots: false,
		infinite: true,
		speed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		nav: false,
		arrows: false,
		autoplay: true,
		customPaging: i => (<div style={{ height: 10, width: 10, borderRadius: 50, backgroundColor: '#3232' }}></div>)
	};
	const responseFacebook = (response) => {
		console.log('response:', response)
		// https://graph.facebook.com/v12.0/me/accounts?access_token=
		// https://graph.facebook.com/v12.0/106069031986459?fields=page_token&access_token=
		if (response.status == "unknown") {
			setIsModalOpen(false)
			setfacebookverified(false)
			setfacebookPopup(false)
		} else {
			APIController.DevelopersFacebook(response.accessToken)
				.then((response) => {
					setIsModalOpen(true)
					setFacebookPages(response.object.data)
				})
		}
	}

	const responseInstagram = (response) => {
		console.log('response:', response)
		response.status == "unknown" && setinstagramverified(false)
	}

	const authHandler = (err, data) => {
		console.log("err :", err + "data" + data);
	};

	return (
		<section className='content-right-main'>
			{/* {AppConstants.ShowPageTitle()} */}
			<div className="content-right-heading">
				{AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[0]}
				{AppConstants.GetLocalStorage('localStorageCampaignName').match(/®/g) ? <sup>&reg;</sup> : ''}
				{AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[1]}{': '}
				{AppConstants.GetLocalStorage('localStorageProductName')}
			</div>
			{ChildrenTabs.length > 1 &&
				<ProductTabSelection history={props.history} ChildrenTabs={ChildrenTabs} INDEX={props.history.location.state != undefined ? props.history.location.state.Index : 0} />}

			<div className='two-col-sec digital-welcome'>
				{Loading ? <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></div> :
					<>
						<div className='sec-left'>
							<div style={{ letterSpacing: '0px' }}>
								<div dangerouslySetInnerHTML={{ __html: Description }} />
							</div>

							<label>Select the Brands You'd Like to Include in Your Social Posts</label>
							{GetBrands.length && GetBrands.map((item, index) => (
								<div className='custom-checkbox' style={{ marginTop: '10px' }} >
									<input type='checkbox'
										name=''
										id={`${item.ID}`}
										defaultChecked={true}
										value={SelectedBrands}
										onChange={(e) => { handleCheckList(item.ID); }}
									/>
									<label htmlFor={`${item.ID}`} style={{ paddingLeft: '40px', display: 'flex', alignContent: 'center' }}>
										{item.Name}</label>
								</div>
							))}


							<div className='white-box'>
								<div className='box-head'>
									<h4>Social Media Credentials</h4>
								</div>

								<div className='box-body'>
									<div className='table-body'>

										<div className='field-full'>
											{/* <input
												label='Facebook Username'
												type='text'
												name='socialMedia.facebookUrl'
												placeholder='Facebook Link'
												value={""}
											/> */}
											<div style={{ display: 'flex' }}>
												<span>Facebook Page Name: &nbsp;</span>
												<label>{FacebookPageName}</label>
											</div>
										</div>

										<div className='custom-checkbox' >
											<input type='checkbox'
												name='facebookverified'
												id={"FacebookCredentials"}
												value={facebookverified}
												defaultChecked={facebookverified}
												onChange={(e) => { setfacebookPopup(e.target.checked) }}
											// onClick={() => { !facebookverified && setfacebookPopup(true) }}
											/>
											<label htmlFor="FacebookCredentials" style={facebookverified ? { paddingLeft: '40px', display: 'flex', alignContent: 'center' } : { paddingLeft: '40px', display: 'flex', alignContent: 'center', color: '#f00' }}>
												{facebookverified ? "Facebook Credentials Verified" : "Please Verify Facebook Credentials"}</label>
										</div>

										{facebookPopup && <FacebookLogin
											appId="307760978095007"
											// appId="1049074015636713"
											// appId="461840625404091"
											autoLoad={true}
											fields="email,picture"
											scope="public_profile,user_friends,user_actions.books"
											callback={responseFacebook}
											cssClass="my-facebook-button-class"
											icon="fa-facebook"
										/>}

										<div className='field-full' style={{ marginTop: '10px' }}>
											{/* <input as={TextField}
												label='Instagram Username'
												type='text'
												name='socialMedia.instagramUrl'
												placeholder='Instagram Link'
												value={""}
											/> */}
											<div style={{ display: 'flex' }}>
												<span>Instagram Page Name: &nbsp;</span>
												<label>{InstagramPageName}</label>
											</div>
										</div>
										<div className='custom-checkbox' >
											<input type='checkbox'
												name=''
												id={"InstagramCredentials"}
												value={instagramverified}
												onChange={(e) => { setinstagramverified(e.target.checked) }}
											/>
											<label htmlFor="InstagramCredentials" style={instagramverified ? { paddingLeft: '40px', display: 'flex', alignContent: 'center' } : { paddingLeft: '40px', display: 'flex', alignContent: 'center', color: '#f00' }}>
												{instagramverified ? "Instagram Credentials Verified" : "Please Verify Instagram Credentials"}</label>
										</div>

										{/* {instagramverified && <InstagramLogin
											clientId="512355427202416"
											onSuccess={responseInstagram}
											onFailure={responseInstagram}
											redirectUri="https://test.mybauschreach.com/"
										>
											{/* <span> Login with Instagram</span>
										</InstagramLogin>} */}

										{instagramverified && <InstagramLogin
											authCallback={authHandler}
											appId="512355427202416"
											appSecret="8ff3227e9e046e4a9da7c0e01490b996"
											redirectUri="https://test.mybauschreach.com/"
										/>}

									</div>
								</div>
							</div>

							<div className={`button-field`} style={{ marginTop: "40px", marginLeft: "-1%" }}>
								<button onClick={() => {
									props.history.push("/digital-product/selection/location-selection")
									AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ "budget": 1, "duration": duration }]))
									AppConstants.SetLocalStorage("localStorageAutomation", "YES")
									AppConstants.SetLocalStorage("localStorageBrandsData", JSON.stringify(GetBrands))
								}}
								>{AppConstants.ButtonText.GetStarted}</button>
							</div>
						</div>
						<div className="sec-right" style={{ padding: '0px', margin: '0px', zIndex: '0' }} >
							<div className='image-slider' >
								<Slider  {...settings}>
									{!!ProductImages.length ? ProductImages.map((obj, index) => {
										return (
											<div className='image-slider'>
												<img style={{ maxHeight: '700px', width: '100%', resize: 'vertical' }} src={obj.ImageURL} alt='slider-2' />
											</div>
										)
									}) : ''}
								</Slider>
							</div>
						</div>
					</>}
			</div>
			{isModalOpen &&
				<ModalComponent
					show={isModalOpen}
				// handleClose={() => setIsModalOpen(false)}
				>
					<div className="popup-box forgot-popup-box" style={{ minWidth: '700px' }} >
						<div className="box-heading">
							{/* <img src={forgot_user_ic} alt="icon" /> */}
							<h2 className="h1">{'Select Page'}</h2>
							<button className="close-btn"><img src={close_icon} alt="icon" onClick={() => { setIsModalOpen(false); setfacebookverified(false) }} /></button>
						</div>
						<div className="popup-wrap" style={{ margin: 0, padding: '0 20px', display: 'grid' }}>
							{facebookPages.map((item) =>
								<div className='location-inner-content' style={{ padding: '15px 0' }}>
									<div className='location-name'>
										<div className={'custom-radio'} >
											<input
												type={'radio'}
												name='location'
												id={`${item.id}`}
												// checked={!!selectedLocations.length && selectedLocations.find((ob) => ob.id === item.id)}
												// onChange={(e) => {
												// 	if (e.target.checked) { onSelectFBPageID(locationDetail); }
												// 	else { onDeSelectAddress(locationDetail); }
												// }}
												onClick={(e) => { setSelectFBPageID(item) }}
											/><label htmlFor={`${item.id}`} style={{ fontWeight: 400 }}>
												{item.name}
											</label>
										</div>
									</div>
								</div>
							)}

							<div className={`form-field button-field`} style={{ textAlign: 'center', margin: '20px 0', justifySelf: 'flex-end' }} >
								<input type="submit" name="edit-profile" value="Select"
									onClick={() => {
										AppConstants.SetLocalStorage("localStorageFBPageData", JSON.stringify(SelectFBPageID));
										setIsModalOpen(false);
										setfacebookverified(true)
									}} />
							</div>
						</div>
					</div>
				</ModalComponent>}
		</section >
	);
};

export default SocialAutomation;

/*eslint-disable eqeqeq*/
import React from "react";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Notification from '../../../shared/components/notification/notification';
import file_upload_ic from "../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../assets/images/close_icon.svg";
import uuid from 'react-uuid';
import "./registration.css";
import * as APIController from '../../../api/APIController.js';
import { ClickAwayListener } from '@material-ui/core';
import { AspectRatio } from 'react-aspect-ratio';
import TextField from "../../../shared/components/TextField/TextField";

class ClinicRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            territoryManager: '',
            practiceBillTo: '',
            email: "",
            logo: "",
            imagename: "",
            iscontactVisible: true,
            imageconvert: "",
            phoneNumber: '',
            visionSourceMember: false,
            vspPremierMember: false,
            anotherAddress: false,
            termCondition: false,
            SearchPostcodestext: '',
            recaptcha: '',
            practiceShipTo: '',
            practiceZipCode: '',
            IsPrimaryPractice: 0,
            verifyCode: true,
            counter: 0,
            showMessage: '',
            SearchPostcodes: [],
            list: [],
            openZIPCodeSuggestion: false,
            ShipTo: '',
            PracticeName: '',
            Address1: '',
            EmailAddress: '',
            website: "",
            appointmentURL: "",
            firstName: "",
            lastName: "",
            Address2: '',
            PostCode: '',
            City: '',
            State: '',
            MobileNo: '',
            VSMP: false,
            VPMP: false,
            IsLoading: false,
            IsSubmitLoading: false,
            IsDuplicateShipTo: '',
            IsDuplicateZipcode: '',
            ThankYouMsg: 1,
            touched: false,//Just Add this line For Validation Purpose
        }
    }

    YourReCaptchaComponent = () => {
        return (<GoogleReCaptchaProvider reCaptchaKey="6Ld6phEaAAAAAEILFE6dyZBQofVDwkWNQFAMqVPJ" >
            <GoogleReCaptcha onVerify={token => this.setState({ "recaptcha": token })} />
        </GoogleReCaptchaProvider>)
    }

    SetInfo(dataSource) {
        let ListData = (dataSource && dataSource.data) || []
        let TempList = []
        for (let i = 0; i < ListData.length; i++) {
            let data = ListData[i]
            if (data) {
                TempList.push({
                    PracticeName: data.PracticeName,
                    ShipToNo: data.ShipTo || this.state.IsDuplicateShipTo,
                    Address1: data.Address1,
                    Address2: data.Address2,
                    Postcode: data.ZIPCode,
                    PhoneNo: '',//no fill
                    State: data.State,
                    City: data.City,
                    VisionSource: data.VTM == 'Vision Source' ? 1 : 0,
                    VSP: (data.VSP == 'Yes' ? 1 : 0),
                    IsPrimaryPractice: '1'
                });
            }
        }
        this.setState({ list: TempList })
    }
    AddMoreAddress = (data, ZipCode) => {
        var TempList = []
        let oldList = this.state.list || []
        for (let i = 0; i < oldList.length; i++) {
            TempList.push(oldList[i])
        }
        TempList.push({
            PracticeName: data.PracticeName,
            ShipToNo: data.ShipTo || this.state.IsDuplicateShipTo,
            Address1: data.Address1,
            Address2: data.Address2,
            Postcode: data.ZIPCode || ZipCode,
            PhoneNo: '',
            State: data.State,
            City: data.City,
            VisionSource: data.VTM == 'Vision Source' ? 1 : 0,
            VSP: (data.VSP == 'Yes' ? 1 : 0),
            IsPrimaryPractice: '0'

        });
        this.setState({ list: TempList })
    };

    wsVerifyClinicFrist = (ShipToNo, ZipCode) => {
        this.setState({ IsLoading: true, touched: false })
        // while (ShipToNo.charAt(0) === '0') {
        //     ShipToNo = ShipToNo.substring(1);
        // }
        APIController.VerifyClinic(ShipToNo, ZipCode)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ showMessage: response.object.message, IsLoading: false })
                    if (response.object.status == "0") {
                        this.handleNotification(response.object.message, true)
                        this.setState({ counter: this.state.counter + 1 })
                        if (this.state.counter == 3) {
                            this.setState({ IsDuplicateShipTo: this.state.practiceShipTo, IsDuplicateZipcode: this.state.practiceZipCode, ThankYouMsg: 0, counter: 0 })
                            this.setState({ verifyCode: false, showMessage: 'The practice information cannot be verified. Please enter your practice information below.  Practices will receive login credentials  to the REACH Portal via email upon verfication by Bausch + Lomb.' })
                            this.SetInfo(response.object)
                            this.state.list.map((item, index) => {
                                return (
                                    this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, Postcode: ZipCode }) }))
                            })
                            this.setState({ verifyCode: false, practiceShipTo: '', practiceZipCode: '' })
                        }
                    } else if (response.object.status == "1") {
                        this.handleNotification(response.object.message)
                        this.setState({ IsDuplicateShipTo: this.state.practiceShipTo, IsDuplicateZipcode: this.state.practiceZipCode })
                        this.setState({ verifyCode: false, practiceShipTo: '', practiceZipCode: '' })
                        this.SetInfo(response.object)
                    } else if (response.object.status == "2") {
                        this.handleNotification(response.object.message)
                    } else {
                        this.handleNotification(response.object.message, true)
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true)
                }
            })
    }

    wsVerifyClinic(ShipToNo, ZipCode) {
        this.setState({ IsLoading: true, touched: true })
        if (this.state.IsDuplicateShipTo == this.state.practiceShipTo && this.state.IsDuplicateZipcode == this.state.practiceZipCode) {
            this.handleNotification("Duplicate Practice Address", true)
            this.setState({ IsLoading: false })
        }
        else {
            APIController.VerifyClinic(ShipToNo, ZipCode)
                .then((response) => {
                    this.setState({ IsLoading: false })
                    if (response.object.status == '0') {
                        this.handleNotification(response.object.message, true)
                        this.setState({ counter: this.state.counter + 1 })
                        if (this.state.counter == 3) {
                            this.setState({ IsDuplicateShipTo: this.state.practiceShipTo, IsDuplicateZipcode: this.state.practiceZipCode, counter: 0 })
                            this.setState({ anotherAddress: false, verifyCode: false, showMessage: response.object.message, })
                            this.AddMoreAddress(response.object.data[0], ZipCode)
                            this.setState({ practiceShipTo: '', practiceZipCode: '', touched: false })

                        }
                    } else if (response.object.status == '1') {
                        this.setState({ IsDuplicateShipTo: this.state.practiceShipTo, IsDuplicateZipcode: this.state.practiceZipCode })
                        this.setState({ anotherAddress: false, verifyCode: false, showMessage: response.object.message, })
                        this.handleNotification(response.object.message)
                        this.AddMoreAddress(response.object.data[0])
                        this.setState({ practiceShipTo: '', practiceZipCode: '', touched: false })
                    }

                    else if (response.object.status == "2") {
                        this.handleNotification(response.object.message)
                    }
                })
        }
    }
    wsSearchPostcodes(postcode) {
        APIController.SearchPostcodes(postcode)
            .then((response) => {
                this.setState({ SearchPostcodes: response.object.data })
            })
    }

    wsSubmitData() {
        this.Validation(1)
    }

    wsRegisterPractice(data) {
        if (this.state.recaptcha == "") {
            this.handleNotification('Please Verify Captcha Code', true)
        } else if (this.state.termCondition == false) {
            this.handleNotification('Please check Terms & Conditions box to confirm your agreement', true)
        } else {
            this.setState({ IsSubmitLoading: true })
            APIController.RegisterPractice(this.state.website, this.state.appointmentURL, this.state.imagename, this.state.logo, this.state.firstName, this.state.lastName,
                this.state.EmailAddress, this.state.phoneNumber, this.state.list)
                .then((response) => {
                    this.setState({ IsSubmitLoading: false })
                    if (response.object.status == 0) {
                        this.handleNotification(response.object.message, true)
                    }
                    else if (response.object.status == 1) {
                        this.props.history.push({
                            pathname: '/thank-you',
                            state: { ThankYouMsg: this.state.ThankYouMsg }
                        })
                    }
                })
        }
    }

    SelectZipcode(item1, index) {
        if (item1 != undefined) {
            this.setState({
                list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, Postcode: item1.Postcode, City: item1.City, State: item1.State }),
                openZIPCodeSuggestion: false,
            })
        }
    }
    SearchPostcode(e, index) {
        this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, Postcode: e.target.value, City: '', State: '' }) })
        if (e.target.value != '') {
            this.setState({ openZIPCodeSuggestion: true })
            this.wsSearchPostcodes(e.target.value)
        }
        else if (e.target.value == '') {
            this.setState({ openZIPCodeSuggestion: false })
        }
    }

    RemovePracticeAddress(index) {
        var temp = this.state.list;
        if (index > 0) { temp.splice(index, 1); }
        this.setState({ list: temp })
        this.handleNotification('Your Practice Address Delete Succssfullly')
    }
    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })
        setTimeout(() => {
            this.handleClearNotification();
        }, 6000)
    }

    handleClearNotification = () => {
        this.setState({
            message: '',
            showNotification: false,
            isError: false
        })
    }

    Validation(data) {
        this.handleValidateScroll(data)
    }

    ListValidorNot() {
        var IsValid = 0;
        this.state.list.map((item, index) => {
            if (this.state.list[index].PhoneNo.length < 10) {
                this.handleNotification('Please enter a valid 10- digit phone number.', true)
                IsValid = 0
            }
            else {
                IsValid = 1
            }
        })
        return IsValid
    }

    handleClickAway = (data) => {
        if (data == 0) {
            this.setState({ openRepresentSuggestion: false })
        } else if (data == 1) {
            this.setState({ openZIPCodeSuggestion: false })
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange1 = (e) => {
        this.setState({ practiceShipTo: e });
    }

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    handleValidateScrollFirst = (IsFirstTime) => {
        this.setState({ touched: true })
        let id;
        if (this.state.practiceShipTo == '') {
            id = 'this.state.practiceShipTo'
        }
        else if (this.state.practiceZipCode == '') {
            id = 'this.state.practiceZipCode'
        }
        else {
            IsFirstTime ? this.wsVerifyClinicFrist(this.state.practiceShipTo, this.state.practiceZipCode) : this.wsVerifyClinic(this.state.practiceShipTo, this.state.practiceZipCode)
        }

        var element = document.getElementById(id);
        id != undefined && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    }


    handleValidateScroll = (data) => {
        this.setState({ touched: true })
        let id = undefined;
        var res = this.state.website.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        this.state.list.map((item, index) => {
            if (this.state.website == '') {
                id = 'this.state.website'
            } else if (res == null) {
                id = 'this.state.website'
                this.handleNotification('Please Enter Valid Website', true)
            } else if (this.state.appointmentURL == '') {
                id = 'this.state.appointmentURL'
            } else if (this.state.firstName == '') {
                id = 'this.state.firstName'
            } else if (this.state.lastName == '') {
                id = 'this.state.lastName'
            } else if (this.state.EmailAddress == '') {
                id = 'this.state.EmailAddress'
            } else if (!this.state.EmailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                id = 'this.state.EmailAddress'
                this.handleNotification('Please Enter Valid Email Id', true)
            } else if (this.state.list[index].PhoneNo == '' || this.state.list[index].PhoneNo == undefined) {
                id = `this.state.list[${index}].PhoneNo`
            } else if (this.state.list[index].PhoneNo.length < 10) {
                id = `this.state.list[${index}].PhoneNo`
                this.handleNotification('Please enter a valid 10- digit phone number.', true)
            } else if (this.state.list[index].PracticeName == '' || this.state.list[index].PracticeName == undefined) {
                id = `this.state.list[${index}].PracticeName`
            } else if (this.state.list[index].Address1 == '' || this.state.list[index].Address1 == undefined) {
                id = `this.state.list[${index}].Address1`
            } else if (this.state.list[index].Postcode == '') {
                id = `this.state.list[${index}].Postcode`
            } else if (this.state.list[index].City == '' || this.state.list[index].City == undefined) {
                id = `this.state.list[${index}].City`
            } else if (this.state.list[index].State == '' || this.state.list[index].State == undefined) {
                id = `this.state.list[${index}].State`
            }
            else if (this.ListValidorNot() == 1) {
                data == 0 ? this.setState({ anotherAddress: true, touched: false }) : this.wsRegisterPractice(data)
            }
        })
        var element = document.getElementById(id);
        id != undefined && id != null && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    MessageLocation={'bottom'}
                    closeNotification={this.handleClearNotification}
                />
                <section className='inner-banner'>
                    <h1>practice Registration Form</h1>
                    <p> Enter your practice info below to be setup in the Bausch + Lomb REACH Marketing Portal </p>
                </section>
                <section className='registration-form-sec'>
                    <div className='registration-form-main'>
                        <form>
                            <div className='form_field'>
                                <label style={{ color: '#0079c2', textAlign: 'center' }} >{this.state.showMessage}</label>
                            </div>
                            {this.state.verifyCode == true ?
                                <div className='registration-form-wrap'>
                                    <div style={{ position: 'relative' }} className="field-field-row-wrap" >
                                        <div className='field-field-col1' style={{ textAlign: 'center', width: '50%' }}>
                                            <TextField error={this.state.practiceShipTo == '' && this.state.touched ? true : false} label='Practice Ship To' type="text" id={'this.state.practiceShipTo'} name="practiceShipTo" value={this.state.practiceShipTo} onChange={this.handleChange} maxLength="8" />
                                            {/* {this.state.practiceShipTo == '' && this.state.touched && <span className={'error-show'}>Practice Ship To is required</span>} */}
                                        </div>
                                        <div className='field-field-col1' style={{ textAlign: 'center', width: '50%' }}>
                                            <TextField error={this.state.practiceZipCode == '' && this.state.touched ? true : false} label='Practice Zip Code' type="text" id={'this.state.practiceZipCode'} name="practiceZipCode" value={this.state.practiceZipCode} onChange={this.handleChange} maxLength="6" />
                                            {/* {this.state.practiceZipCode == '' && this.state.touched && <span className={'error-show'}>Practice Zip Code is required</span>} */}
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        <div className='field-right'>
                                            <div className={this.state.IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type='button' disabled={this.state.IsLoading} value={this.state.IsLoading ? 'Verifying..' : 'Click to Verify'}
                                                    onClick={() => { this.handleValidateScrollFirst(true) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='registration-form-wrap'>
                                    {this.state.list.map((item, index) => {
                                        return (
                                            <>
                                                <div style={{ position: 'relative' }}>
                                                    {index > 0 && <a title="" style={{ height: 25, width: 25, position: 'absolute', top: 80, right: -50 }}><img src={close_icon} alt="close-ic" onClick={() => this.RemovePracticeAddress(index)} /></a>}
                                                </div>
                                                {index > 0 && <div className='form_field'>
                                                    <label style={{ color: '#0079c2', marginTop: '15px', textAlign: 'center' }}>{this.state.showMessage}</label>
                                                </div>}

                                                <div className='business-info-wrap'>
                                                    <div className='info-col-left'>
                                                        <div className='white-box'>
                                                            <div className='box-head'>
                                                                <h4>Practice info</h4>
                                                            </div>
                                                            <div className='box-body'>
                                                                <div className='field-field-row'>
                                                                    <TextField label='Ship To #: (8 digits - add leading zeroes)' type="text" name="ShipToNo" value={item.ShipToNo == undefined ? this.state.practiceShipTo : item.ShipToNo} maxLength="8" />
                                                                </div>

                                                                <div id={`this.state.list[${index}].PracticeName`} className={`field-field-row ${this.state.list[index].PracticeName == '' || this.state.list[index].PracticeName == undefined && this.state.touched ? 'error' : ''}`}>
                                                                    <TextField
                                                                        error={this.state.list[index].PracticeName == '' || this.state.list[index].PracticeName == undefined && this.state.touched ? true : false}
                                                                        label='Practice Name' type='text' placeholder='Practice Name' name='PracticeName' value={item.PracticeName} maxLength="50"
                                                                        onChange={(e) => { this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, PracticeName: e.target.value }) }) }} />
                                                                </div>

                                                                <div id={`this.state.list[${index}].Address1`} className={`field-field-row ${this.state.list[index].Address1 == '' || this.state.list[index].Address1 == undefined && this.state.touched ? 'error' : ''}`}>
                                                                    <TextField label='Address Line 1' type='text' placeholder='Address Line 1' name='Address1' value={item.Address1} maxLength="50"
                                                                        onChange={(e) => { this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, Address1: e.target.value }) }) }}
                                                                        error={this.state.list[index].Address1 == '' || this.state.list[index].Address1 == undefined && this.state.touched ? true : false}
                                                                    />
                                                                </div>
                                                                <div id={`this.state.list[${index}].Address2`} className='field-field-row'>
                                                                    <TextField label='Address Line 2' type='text' placeholder='Address Line 2' name='Address2' value={item.Address2} maxLength="50"
                                                                        onChange={(e) => { this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, Address2: e.target.value }) }) }} />
                                                                </div>
                                                                <div id={`this.state.list[${index}].City`} style={{ position: 'relative' }} className={`field-field-row ${this.state.list[index].City == '' || this.state.list[index].City == undefined && this.state.touched ? 'error' : ''}`}>

                                                                    <TextField disabled label='City' type='text' placeholder='City' name='City'
                                                                        value={item.City} maxLength="50"
                                                                        onChange={(e) => { this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, City: e.target.value }) }) }}
                                                                        error={this.state.list[index].City == '' || this.state.list[index].City == undefined && this.state.touched ? true : false} />
                                                                </div>

                                                                <div id={`this.state.list[${index}].State`} style={{ position: 'relative' }} className={`field-field-row ${this.state.list[index].State == '' || this.state.list[index].State == undefined && this.state.touched ? 'error' : ''}`}>
                                                                    <TextField disabled label='State' type='text' placeholder='State' name='State'
                                                                        value={item.State} maxLength="50"
                                                                        onChange={(e) => {
                                                                            this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, State: e.target.value }) })
                                                                        }}
                                                                        error={this.state.list[index].State == '' || this.state.list[index].State == undefined && this.state.touched ? true : false}
                                                                    />
                                                                </div>

                                                                <div style={{ position: 'relative' }} className={`field-field-row ${this.state.list[index].Postcode == '' && this.state.touched ? 'error' : ''}`}>
                                                                    <ClickAwayListener onClickAway={() => this.handleClickAway(1)}>
                                                                        <div id={`this.state.list[${index}].Postcode`} style={{ position: 'relative' }}>
                                                                            <TextField label='Postcode' type='text' placeholder='Postcode' name='Postcode'
                                                                                value={item.Postcode} maxLength="6"
                                                                                onChange={(e) => {
                                                                                    this.SearchPostcode(e, index)
                                                                                }}
                                                                                error={this.state.list[index].Postcode == '' && this.state.touched ? true : false}
                                                                            />
                                                                            {/* {this.state.list[index].Postcode == '' && this.state.touched && <span className={'error-msg'}>Postcode is required</span>} */}
                                                                            {this.state.openZIPCodeSuggestion ? (
                                                                                <div className='Suggetion-wrap' style={{ maxHeight: '250px', overflow: 'scroll' }}>
                                                                                    {this.state.SearchPostcodes.length == 0 ?
                                                                                        <div >
                                                                                            {'No Suggetion Found'}
                                                                                        </div>
                                                                                        :
                                                                                        this.state.SearchPostcodes.map((item) => {
                                                                                            return (
                                                                                                <div onClick={() => this.SelectZipcode(item, index)} style={{ cursor: 'pointer', padding: '5px' }}>
                                                                                                    {item.Postcode}
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </ClickAwayListener>
                                                                </div>

                                                                <div id={`this.state.list[${index}].PhoneNo`} className={`field-field-row ${this.state.list[index].PhoneNo == undefined || this.state.list[index].PhoneNo == '' && this.state.touched ? 'error' : ''}`}>
                                                                    <TextField label='Practice Phone Number' type='text' placeholder='Practice Phone Number' name='PracticeNumber'
                                                                        value={this.formatPhoneNumber(item.PhoneNo)} maxLength="14"
                                                                        onChange={(e) => {
                                                                            this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, PhoneNo: e.target.value }) })
                                                                        }}
                                                                        error={this.state.list[index].PhoneNo == undefined || this.state.list[index].PhoneNo == '' && this.state.touched ? true : false}
                                                                    />
                                                                    {/* {this.state.list[index].PhoneNo == '' && this.state.touched && <span style={{ top: 60 }} className={'error-msg'}>Phone Number is required</span>}
                                                                    {this.state.list[index].PhoneNo == undefined && this.state.touched && <span style={{ top: 60 }} className={'error-msg'}>Phone Number is required</span>} */}
                                                                </div>

                                                                <div className='field-field-row-wrap'>
                                                                    <div className='field-field-col'>
                                                                        <label style={{ fontSize: '15px' }}>Vision Source Member Practice</label>
                                                                        <div className='field-field-row-wrap'>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="VisionSource" id={`yes-textvision${index}`}
                                                                                    value={item.VisionSource}
                                                                                    defaultChecked={item.VisionSource == 0 ? false : true}
                                                                                    onClick={(e) => this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, VisionSource: 1 }) })} />
                                                                                <label htmlFor={`yes-textvision${index}`}>Yes</label>
                                                                            </div>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="VisionSource" id={`no-textvision${index}`}
                                                                                    value={item.VisionSource}
                                                                                    defaultChecked={item.VisionSource == 0 ? true : false}
                                                                                    onClick={(e) => this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, VisionSource: 0 }) })} />
                                                                                <label htmlFor={`no-textvision${index}`}>No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='field-field-col'>
                                                                        <label style={{ fontSize: '15px' }}>VSP Premier Member Practice</label>
                                                                        <div className='field-field-row-wrap'>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="VSP" id={`yes-textvsp${index}`}
                                                                                    value={item.VSP}
                                                                                    defaultChecked={item.VSP == 0 ? false : true}
                                                                                    onClick={(e) => this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, VSP: 1 }) })} />
                                                                                <label htmlFor={`yes-textvsp${index}`}>Yes</label>
                                                                            </div>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="VSP" id={`no-textvsp${index}`}
                                                                                    value={item.VSP}
                                                                                    defaultChecked={item.VSP == 0 ? true : false}
                                                                                    onClick={(e) => this.setState({ list: this.state.list.map((item, idx) => idx !== index ? item : { ...item, VSP: 0 }) })} />
                                                                                <label htmlFor={`no-textvsp${index}`}>No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='info-col-right' >
                                                        {
                                                            index == 0 && <>
                                                                <div className='white-box'>
                                                                    <div className='box-head'>
                                                                        <h4>Additional Info</h4>
                                                                    </div>
                                                                    <div className='box-body'>
                                                                        <div id={'this.state.website'} style={{ position: 'relative' }} className={`field-field-row ${this.state.website == '' && this.state.touched ? 'error' : ''}`} >
                                                                            <TextField label='Website URL' type='text' placeholder='Website URL' name='website'
                                                                                value={this.state.website} maxLength="50"
                                                                                onChange={this.handleChange}
                                                                                error={this.state.website == '' && this.state.touched ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div id={'this.state.appointmentURL'} style={{ position: 'relative' }} className={`field-field-row ${this.state.appointmentURL == '' && this.state.touched ? 'error' : ''}`} >
                                                                            <TextField label='Appointment URL' type='text' placeholder='Appointment URL' name='appointmentURL'
                                                                                value={this.state.appointmentURL} maxLength="50"
                                                                                onChange={this.handleChange}
                                                                                error={this.state.appointmentURL == '' && this.state.touched ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div id={'this.state.firstName'} style={{ position: 'relative' }} className={`field-field-row ${this.state.firstName == '' && this.state.touched ? 'error' : ''}`}>
                                                                            <TextField label='Practice Contact First Name' type='text' placeholder='Practice Contact First Name' name='firstName'
                                                                                value={this.state.firstName} maxLength="50"
                                                                                onChange={this.handleChange}
                                                                                error={this.state.firstName == '' && this.state.touched ? true : false}
                                                                            />
                                                                            {/* {this.state.firstName == '' && this.state.touched && <span style={{ top: 30 }} className={'error-msg'}>First Name is required</span>} */}
                                                                        </div>
                                                                        <div id={'this.state.lastName'} style={{ position: 'relative' }} className={`field-field-row ${this.state.lastName == '' && this.state.touched ? 'error' : ''}`}>
                                                                            <TextField label='Practice Contact Last Name' type='text' placeholder='Practice Contact Last Name' name='lastName'
                                                                                value={this.state.lastName} maxLength="50"
                                                                                onChange={this.handleChange}
                                                                                error={this.state.lastName == '' && this.state.touched ? true : false}
                                                                            />
                                                                            {/* {this.state.lastName == '' && this.state.touched && <span style={{ top: 30 }} className={'error-msg'}>Last Name is required</span>} */}
                                                                        </div>

                                                                        <div id={'this.state.EmailAddress'} style={{ position: 'relative' }} className={`field-field-row ${this.state.EmailAddress == '' && this.state.touched ? 'error' : ''}`}>
                                                                            {/* <input type='text' name='EmailAddress' value={this.state.EmailAddress} onChange={this.handleChange} maxLength="50" /> */}
                                                                            <TextField label='Email Address' type='text' placeholder='Email Address' name='EmailAddress'
                                                                                value={this.state.EmailAddress} maxLength="50"
                                                                                onChange={this.handleChange}
                                                                                error={this.state.EmailAddress == '' && this.state.touched ? true : false}
                                                                            />
                                                                            {/* {this.state.EmailAddress == '' && this.state.touched && <span style={{ top: 60 }} className={'error-msg'}>Email Address is required</span>} */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='white-box'>
                                                                    <div className='box-head'>
                                                                        <h4>Practice Logo</h4>
                                                                    </div>
                                                                    <div className='box-body'>
                                                                        <div className='field-field-row' style={{ textAlign: 'center' }}>
                                                                            {/* <div className='field-field-label'>Upload Practice Logo (Optional) </div> */}
                                                                            <div className='form_field'>
                                                                                <div className='form-file-input'>
                                                                                    <input
                                                                                        className='input-file'
                                                                                        id='fileupload'
                                                                                        name='files'
                                                                                        type='file'
                                                                                        onChange={(e) => {
                                                                                            const file = e.target.files[0];
                                                                                            let img = new Image()
                                                                                            img.src = window.URL.createObjectURL(file)
                                                                                            img.onload = () => {
                                                                                                if (file && (file.type.includes("svg") ||
                                                                                                    file.type.includes("png") ||
                                                                                                    file.type.includes("jpeg") ||
                                                                                                    file.type.includes("jpg"))
                                                                                                ) {
                                                                                                    this.setState({ imageconvert: file });
                                                                                                    file['preview'] = URL.createObjectURL(file);

                                                                                                    this.setState({ "imagename": uuid() + '.' + file.name.split('.')[1] });

                                                                                                    const image2base64 = require('image-to-base64');
                                                                                                    image2base64(file.preview).then((response) => {
                                                                                                        this.setState({ "logo": response });
                                                                                                    }).catch((error) => { })
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {this.state.imageconvert == '' ?
                                                                                        <label htmlFor='fileupload' style={{ paddingLeft: '20px', paddingRight: '20px' }} className='input-file-trigger'>
                                                                                            <img src={file_upload_ic} alt='file-uplaod' />
                                                                                            {' Drag and drop or Click here to upload file'}
                                                                                        </label> :
                                                                                        <div className='logo-preview' style={{ alignSelf: 'center', width: '400px', border: '2px solid #f5f7fa', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                                                                                            <img src={close_icon} alt="close-ic" className="close-ic" onClick={() => this.setState({ imageconvert: '', logo: '', imagename: '' })} style={{ cursor: 'pointer', marginRight: '3px' }} />
                                                                                            <AspectRatio ratio="3/4" style={{ maxWidth: '400px' }} >
                                                                                                <img style={{ maxHeight: '200px' }} src={window.URL.createObjectURL(new Blob([this.state.imageconvert], { type: this.state.imageconvert.type }))} alt='file-uplaod' />
                                                                                            </AspectRatio>
                                                                                        </div>}
                                                                                </div>
                                                                            </div>
                                                                            <p> Logo Size should be 400X200. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    }
                                    )}

                                    <div className='form_field'>
                                        <div className='field-left'>
                                            <div className='field-field-label'> Do you want to add another location to this account? </div>
                                        </div>
                                        <div className='field-right'>
                                            <div className='field-field'>
                                                <div className="custom-radio">
                                                    <input type="radio" name="anotherAddress" id="yes-textaddress"
                                                        defaultChecked={this.state.anotherAddress == false ? false : true}
                                                        defaultValue={this.state.anotherAddress}
                                                        checked={this.state.anotherAddress == false ? false : true}
                                                        onClick={() => this.Validation(0)}
                                                    />
                                                    <label htmlFor="yes-textaddress">Yes</label>
                                                </div>
                                                <div className="custom-radio">
                                                    <input type="radio" name="anotherAddress" id="no-textaddress"
                                                        defaultChecked={this.state.anotherAddress == false ? true : false}
                                                        defaultValue={this.state.anotherAddress}
                                                        checked={this.state.anotherAddress == false ? true : false}
                                                        onClick={() => this.setState({ anotherAddress: false, touched: true })}
                                                    />
                                                    <label htmlFor="no-textaddress">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.anotherAddress == true ?
                                        <div className='registration-form-wrap' style={{ textAlign: 'center' }}>
                                            <div style={{ position: 'relative' }} className="field-field-row-wrap" >
                                                <div className='field-field-col1' style={{ textAlign: 'center', width: '50%' }}>
                                                    <TextField error={this.state.practiceShipTo == '' && this.state.touched ? true : false}
                                                        label='Practice Ship To' type="text" id={'this.state.practiceShipTo'} name="practiceShipTo"
                                                        value={this.state.practiceShipTo}
                                                        onChange={this.handleChange} maxLength="8" />
                                                    {/* {this.state.practiceShipTo == '' && this.state.touched && <span className={'error-show'}>Practice Ship To is required</span>} */}
                                                </div>
                                                <div className='field-field-col1' style={{ textAlign: 'center', width: '50%' }}>
                                                    {/* <label>Practice Zip Code <span>*</span></label> */}
                                                    <TextField error={this.state.practiceZipCode == '' && this.state.touched ? true : false}
                                                        label='Practice Zip Code' type="text" id={'this.state.practiceZipCode'} name="practiceZipCode"
                                                        value={this.state.practiceZipCode}
                                                        onChange={this.handleChange} maxLength="6" />
                                                    {/* {this.state.practiceZipCode == '' && this.state.touched && <span className={'error-show'}>Practice Zip Code is required</span>} */}
                                                </div>
                                            </div>
                                            <div className='form_field'>
                                                <div className='field-right'>
                                                    <div className={this.state.IsLoading ? "button-field disable-btn" : "button-field"}>
                                                        <input type='button' disabled={this.state.IsLoading} value={this.state.IsLoading ? 'Verifying..' : 'Click to Verify'}
                                                            onClick={() => { this.handleValidateScrollFirst(false) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        : <div />}

                                    {this.YourReCaptchaComponent()}

                                    <div className='form_field'>
                                        {/* <div className='field-right'> */}
                                        <div className='custom-checkbox'>
                                            <input
                                                type='checkbox'
                                                name='termCondition'
                                                id='term-condi'
                                                checked={this.state.termCondition === true}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        this.setState({ "termCondition": true });
                                                    } else {
                                                        this.setState({ "termCondition": false });
                                                    }
                                                }}
                                            />
                                            <label htmlFor='term-condi'>
                                                Please confirm that you agree to the <a title='terms & conditions'> terms & conditions. </a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form_field'>
                                        {/* <div className='field-right'> */}
                                        <div className={this.state.IsSubmitLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type='button' value={this.state.IsSubmitLoading ? 'Checking..' : 'submit'}
                                                onClick={() => this.wsSubmitData()} />
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </section>
                <footer />
            </>
        );
    }
}

export default ClinicRegistration;

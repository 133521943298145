/*eslint-disable eqeqeq*/
import React from "react";
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";
import { Formik, Field } from 'formik';
import { Grid } from '@material-ui/core';
import TextFieldOld from "@material-ui/core/TextField";
import uuid from 'react-uuid';
import close_icon from "../../../../assets/images/close_icon.svg";
import close_ic from '../../../../assets/images/close-ic.svg';
import forgot_user_ic from '../../../../assets/images/forgot-username-ic.svg';
import Editor from '../../../campaign/components/editor';

const AddEditCalendarPopup = (props) => {
    const [IsLoading, setIsLoading] = React.useState(false);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const [CurrentMonthName, setCurrentMonthName] = React.useState(monthNames[props.CalendarMonth - 1]);

    const initialValues = {
        BrandID: "", DateNo: new Date().getDate(), SchedulerDate: "08:00", ImageName: "", ImageData: "", PreviewName: "",
        PreviewData: "", ImageURL: "", PreviewURL: "", VeevaCode: "", AssetName: ""
    }
    const [description, setDescription] = React.useState('');
    const [BrandsData, setBrandsData] = React.useState([]);
    const [dateData, setDateData] = React.useState([]);
    const [OriginalImageName, setOriginalImageName] = React.useState('');

    React.useEffect(() => {
        props.EditID == 0 ? wsGetBrandForDropdown() : wsGetAutomationCalendarAssetByID(props.EditID)

        var dt = new Date();
        var month = dt.getMonth() + 1;
        var year = dt.getFullYear();
        var daysInMonth = new Date(year, month, 0).getDate();

        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) { pairs.push({ ID: i + 1 }); }
        setDateData(pairs)
    }, []);

    const wsGetBrandForDropdown = () => {
        APIController.GetBrandForDropdown()
            .then((response) => {
                // setLoading(false)
                if (response.error == null) {
                    setBrandsData(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetAutomationCalendarAssetByID = (ID) => {
        setIsLoading(true)
        APIController.GetAutomationCalendarAssetByID(ID)
            .then((response) => {
                if (response.error == null) {
                    setBrandsData(JSON.parse(response.object.data[0].Brands))
                    setDescription(response.object.data[0].Description)
                    initialValues.BrandID = response.object.data[0].BrandID
                    initialValues.VeevaCode = response.object.data[0].VeevaCode
                    initialValues.AssetName = response.object.data[0].AssetName
                    initialValues.DateNo = parseInt(response.object.data[0].AssetDate.split("T")[0].split("-")[2])
                    initialValues.SchedulerDate = response.object.data[0].SchedulerDate.split("T")[1].split(":")[0] + ":" + response.object.data[0].SchedulerDate.split("T")[1].split(":")[1]
                    initialValues.ImageName = response.object.data[0].ImageName
                    initialValues.ImageData = response.object.data[0].ImageURL
                    initialValues.ImageURL = response.object.data[0].ImageURL
                    initialValues.PreviewName = response.object.data[0].PreviewName
                    initialValues.PreviewData = response.object.data[0].PreviewURL
                    initialValues.PreviewURL = response.object.data[0].PreviewURL

                    setBrandsData(JSON.parse(response.object.data[0].Brands));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                } setIsLoading(false)
            })
    }

    const wsAddEditAutomationCalendarAsset = (ID, AutomationCalendarID, BrandID, AssetDate, SchedulerDate, Description, ImageName, ImageData, ImageURL, PreviewName, PreviewData, PreviewURL, VeevaCode, AssetName) => {
        setIsLoading(true)
        APIController.AddEditAutomationCalendarAsset(ID, AutomationCalendarID, BrandID, AssetDate, SchedulerDate, Description, ImageName, ImageData, ImageURL, PreviewName, PreviewData, PreviewURL, VeevaCode, AssetName)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        setTimeout(() => {
                            props.history.push({
                                pathname: "/calendar", state: {
                                    calendarIdEdit: AutomationCalendarID,
                                    calendarYearEdit: props.CalendarYear,
                                    calendarMonthEdit: props.CalendarMonth
                                }
                            })
                            props.closeModal()
                        }, 4000);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message);
        setShowNotification(true);
        setIsError(isError);

        setTimeout(() => {
            handleClearNotification();
        }, 4000);
    };

    const handleClearNotification = () => {
        setMessage("");
        setShowNotification(false);
        setIsError(false);
    };

    const { EditID, titleName, CalendarId, CalendarYear, CalendarMonth } = props;

    return (
        <div className="popup-box forgot-popup-box">
            <div className="box-heading">
                <img src={forgot_user_ic} alt="icon" />
                {/* {props.history.location.state.countryIdEdit == 0 ? <h1>Add Country</h1> : <h1>Edit Country</h1>} */}
                <h2 className="h1">{EditID == 0 ? titleName : "Calendar " + CalendarYear + ", " + monthNames[CalendarMonth - 1]}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div style={{ padding: '5%', width: '100%' }}>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validateAssetForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditAutomationCalendarAsset(
                            EditID, CalendarId, values.BrandID,
                            CalendarMonth + "-" + values.DateNo + "-" + CalendarYear, values.SchedulerDate, description,
                            values.ImageName, values.ImageData, values.ImageURL,
                            values.PreviewName, values.PreviewData, values.PreviewURL,
                            values.VeevaCode, values.AssetName)
                        // props.closeModal()
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => (
                        <form onSubmit={handleSubmit} >
                            <div className='form_field'>
                                <div className="field-left">
                                    <label>Brand</label>
                                </div>
                                <div className="field-right">
                                    <select
                                        name="BrandID"
                                        defaultValue={values.BrandID}
                                        value={values.BrandID}
                                        onChange={(e) => {
                                            setFieldValue('BrandID', e.target.value)
                                        }}>
                                        <option value="">Select Brand</option>
                                        {BrandsData != null && BrandsData.length && BrandsData.map((obj, index) => {
                                            return <option selected={obj.ID == values.BrandID ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.Name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='form_field'>
                                <div className="field-left">
                                    <label>Date</label>
                                </div>
                                <div className="field-right">
                                    <select
                                        id={'DateNo'}
                                        name="DateNo"
                                        defaultValue={values.DateNo}
                                        value={values.DateNo == "0" ? "Select Date" : values.DateNo}
                                        onChange={(e) => setFieldValue('DateNo', e.target.value)}>
                                        <option value="0">Select Date</option>
                                        {dateData != null && dateData.length && dateData.map((obj, index) => {
                                            return <option selected={obj.ID == values.DateNo ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}  {CurrentMonthName + " " + props.CalendarYear}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='form_field'>
                                <div className="field-left"> <label>Veeva Code</label> </div>
                                <div className="field-right">
                                    <Field name="VeevaCode" type="text" placeholder="Veeva Code" value={values.VeevaCode} />
                                </div>
                            </div>
                            <div className='form_field'>
                                <div className="field-left"> <label>Asset Name</label> </div>
                                <div className="field-right">
                                    <Field name="AssetName" type="text" placeholder="Asset Name" value={values.AssetName} />
                                </div>
                            </div>

                            <div className='form_field'>
                                <label style={{ marginBottom: '-40px' }}>Description</label>
                                {!IsLoading && <div className="asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                    <Editor content={description} setContent={setDescription} />
                                </div>}
                            </div>

                            <div className='form_field'>
                                <div className="field-left">
                                    <label>Time</label>
                                </div>
                                <div className="field-right table-body">
                                    <div className='table-data table-width-2'>
                                        <TextFieldOld
                                            id='time'
                                            label=''
                                            type='time'
                                            InputLabelProps={{ shrink: true, }}
                                            value={values.SchedulerDate}
                                            onChange={(e) => { setFieldValue("SchedulerDate", e.target.value); }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Grid container direction='row' style={{ width: '100%', justifyContent: 'space-between', }}>
                                <Grid container direction='row' style={{ width: '50%', }}>
                                    <Grid className="form_field">
                                        <div className='form-file-input'>
                                            <label>Select Asset</label>
                                            <input className='input-file' id='fileupload' name='files' type='file' onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
                                                    let file1 = e.target.files[0]
                                                    file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                    let extension = file.type.split('/')[1].match("x-zip-compressed") ? "zip" : file.type.split('/')[1]

                                                    setOriginalImageName(file1.name)
                                                    // setFileExtension(extension)
                                                    setFieldValue("ImageName", values.ImageName == "" ?
                                                        uuid() + "." + extension :
                                                        values.ImageName.split('.')[0] + "." + extension);
                                                    const image2base64 = require('image-to-base64');
                                                    image2base64(file1.preview).then((response) => { setFieldValue('ImageData', response) }).catch((error) => { })

                                                    setFieldValue('ImageURL', file1.preview);
                                                }
                                            }} />
                                            {values.ImageURL == '' ?
                                                <label htmlFor='fileupload' className='input-file-trigger'>
                                                    <img src={file_upload_ic} alt='file-uplaod' /> Drag and drop your file here </label> :
                                                <div className='logo-preview'>
                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('ImageURL', '', 'ImageData', '') }} /></a>
                                                    <img src={values.ImageURL} style={{ height: '170px', width: '230px' }} alt='' />
                                                </div>}
                                        </div>
                                        <p> Image size: 800X800 px. Accepted files : .jpg, .jpeg, .png, .svg</p>
                                    </Grid>
                                </Grid>

                                <Grid container direction='row' style={{ width: '50%' }}>
                                    <Grid className="form_field" style={{ justifyContent: 'flex-end', }} >
                                        <div className='form-file-input'>
                                            <label> Select Thumbnail Image </label>
                                            <input className='input-file' id='fileupload' name='files' type='file' onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
                                                    let file1 = e.target.files[0]
                                                    file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                    setFieldValue("PreviewName", values.PreviewName == "" ?
                                                        uuid() + "." + file.type.split('/')[1] :
                                                        values.PreviewName.split('.')[0] + "." + file.type.split('/')[1]);

                                                    const image2base64 = require('image-to-base64');
                                                    image2base64(file1.preview).then((response) => { setFieldValue('PreviewData', response) }).catch((error) => { })
                                                    setFieldValue('PreviewURL', file1.preview);
                                                }
                                            }} />
                                            {values.PreviewURL == '' ?
                                                <label htmlFor='fileupload' className='input-file-trigger'>
                                                    <img src={file_upload_ic} style={{ padding: '10px' }} alt='file-uplaod' /> Drag and drop your file here </label> :
                                                <div className='logo-preview'>
                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('PreviewURL', '', 'PreviewData', '') }} /></a>
                                                    <img src={values.PreviewURL} style={{ height: '170px', width: '230px' }} alt='' />
                                                </div>}
                                        </div>
                                        <p> Image size: 250X250 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' style={{ width: '100%', justifyContent: 'center', }}>
                                <div className={IsLoading ? 'form-field button-field disable-btn' : 'form-field button-field'} style={{ textAlign: 'center', }} >
                                    <input type="submit" name="edit-profile" value="Save" />
                                </div>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div >

    );
};

export default AddEditCalendarPopup;

/*eslint-disable eqeqeq*/
import React from "react";
import file_upload_ic from "../../../assets/images/file-upload-ic.svg";
import * as APIController from "../../../api/APIController.js";
import Notification from "../../../shared/components/notification/notification";
import MyiFrame from "./myiFrame.js";

const UploadAsset = (props) => {
    const [UploadAssetId, setuploadAssetId] = React.useState("");
    const [CampaignId, setCampaignId] = React.useState("");
    const [AssetTypeId, setAssetTypeId] = React.useState("");
    const [AssetSizeId, setAssetSizeId] = React.useState("");
    const [campaignThemesId, setCampaignThemesId] = React.useState("");
    const [content, setcontent] = React.useState("");
    const [DirectMail, setDirectMail] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setuploadAssetId(props.uploadAssetIdEdit);
        setCampaignId(props.campaignIdEdit);
        setAssetTypeId(props.assetTypeIdEdit);
        setAssetSizeId(props.assetSizeIdEdit);
        setCampaignThemesId(props.campaignThemesId);
        if (props.uploadAssetIdEdit == 1) {
            setcontent(props.DirectMailHTMLData)
            setDirectMail(true)
        } else if (props.uploadAssetIdEdit != 0) {
            wsGetCampaignAssetByID(props.uploadAssetIdEdit);
        }
    }, [props]);

    const wsGetCampaignAssetByID = (ID) => {
        APIController.GetCampaignAssetByID(ID).then((response) => {
            if (response.error == null) {
                setcontent(response.object.data[0].AdContent);
            } else if (response.error.message && response.error.message.length > 0) {
                handleNotification(response.error.message, true);
            }
        });
    };

    const wsAddEditCampaignAsset = (ID, CampaignID, AssetTypeID, AdContent, AssetSizeID, ImageName, ImageURL, ImageData, CampaignThemeID) => {
        setIsLoading(true);
        APIController.AddEditCampaignAsset(ID, CampaignID, AssetTypeID, AdContent, AssetSizeID, ImageName, ImageURL, ImageData, CampaignThemeID, props.isPrintProductEdit)
            .then((response) => {
                setIsLoading(false);
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        setTimeout(() => {
                            props.closeModal();
                        }, 4000);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            });
    };

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message);
        setShowNotification(true);
        setIsError(isError);

        setTimeout(() => {
            handleClearNotification();
        }, 4000);
    };

    const handleClearNotification = () => {
        setMessage("");
        setShowNotification(false);
        setIsError(false);
    };

    return (
        <>
            {/* <input type={ 'file' } name="file-upload" onChange={ (e) => {
            const file = e.target.files[0];
            if (file) {
                props.setValue(file);
                props.closeModal();
            }
        } } /> */}

            {/* <div className="popup-box forgot-popup-box">  */}
            <section className="content-right-main campaign-page">
                {DirectMail ? <h1>Upload DirectMail Asset : {props.titlefieldName}</h1> : <h1>Upload Asset : {props.titlefieldName}</h1>}
                <div className="upload-asset-main">

                    {DirectMail ?
                        <>
                            <div className="form_field" >
                                <div className="form-file-input asset-button">
                                    <input className="input-file" id="fileupload" name="files" type="file" onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && file.type === "text/html") {
                                            const reader = new window.FileReader();
                                            reader.onload = (data) => {
                                                setcontent(data.target.result);
                                                {/* SetMainContent(data.target.result); */ }
                                            };
                                            reader.readAsText(file);
                                        }
                                    }}
                                    />
                                    <label htmlFor="fileupload" className="input-file-trigger">
                                        <img src={file_upload_ic} alt="file-uplaod" />
                                        Drag and drop your file here
                                    </label>
                                </div>

                                <div className="asset-full">
                                    <MyiFrame HTMLContent={content} />
                                </div>

                                <div className="asset-button">
                                    <div className={IsLoading ? "button-field disable-btn" : "button-field"} >
                                        <input type="submit" value="upload" name="upload" onClick={() => props.DirectMailData(content)} />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {/* <div className="asset-left">
                <label>Asset URL</label>
                <input type="text" name="Paste-Asset-URL" placeholder="Paste Asset URL" />

                <div className="button-field">
                  <input type="submit" name="upload-asset" value="upload asset" />
                </div>
              </div> */}
                            <div className="form_field">
                                {/* <div className="left-or-text">
                  <span>or</span>
                </div> */}
                                <div className="form-file-input asset-button">
                                    <input className="input-file" id="fileupload" name="files" type="file" onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && file.type === "text/html") {
                                            const reader = new window.FileReader();
                                            reader.onload = (data) => {
                                                setcontent(data.target.result);
                                                {/* SetMainContent(data.target.result); */ }
                                            };
                                            reader.readAsText(file);
                                        }
                                    }}
                                    />
                                    <label htmlFor="fileupload" className="input-file-trigger">
                                        <img src={file_upload_ic} alt="file-uplaod" /> Drag and drop your file here </label>
                                </div>
                                <div className="asset-full">
                                    {/* <Editor content={content} setContent={setcontent} /> */}
                                    <MyiFrame HTMLContent={content} />
                                </div>
                                <div className="asset-button">
                                    <div className={IsLoading ? "button-field disable-btn" : "button-field"} >
                                        <input type="submit" value="upload" name="upload" onClick={() => {
                                            wsAddEditCampaignAsset(UploadAssetId, CampaignId, AssetTypeId, content, AssetSizeId, "ImageName", "", "ImageData", campaignThemesId);
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
                <Notification
                    isError={isError}
                    message={message}
                    showNotification={showNotification}
                    clearNotification={handleClearNotification.bind(this)}
                    closeNotification={handleClearNotification.bind(this)}
                />
            </section>
            {/* </div> */}
        </>
    );
};

export default UploadAsset;

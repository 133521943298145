/*eslint-disable eqeqeq*/
import React from 'react';
import * as APIController from "../../../api/APIController.js";
import ic_ordersuccess from "../../../assets/images/ic_ordersuccess.png";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import { Typography, Grid } from '@material-ui/core';

class PaymentSuccess extends React.Component {
    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 10, 5],
        list: [],
        order: [],
        headerList: ["Order Date", "Order #", "Product(s) Ordered", "Product Cost"],
        from: 0,
        to: 25,
        progress: false,
        message: "",
        showNotification: false,
        isError: false,
        OrderBy: this.props.history.location.state.OrderByName,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        SubscriptionDate: '',
        SubscriptionNo: '',
        Cost: '',
    };

    componentDidMount() {
        this.wsGetAutomationSubscriptionByID(this.props.history.location.state.AutomationSubscriptionID);
    }

    wsGetAutomationSubscriptionByID(ID) {
        this.setState({ progress: true, });
        APIController.GetAutomationSubscriptionByID(ID).then((response) => {
            if (response.error == null) {
                this.setState({
                    SubscriptionDate: response.object.data.SubscriptionDetails[0].SubscriptionDate,
                    SubscriptionNo: response.object.data.SubscriptionDetails[0].SubscriptionNo,
                    Cost: response.object.data.SubscriptionDetails[0].Cost,
                    progress: false,
                });
            }
        });
    }

    render() {
        return (
            <>
                <section className='content-right-main'>
                    <h1>Thank you for your order!</h1>
                    <div className="table-main-wrap countrys-class">
                        <Grid container direction='row' justify='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                            <Grid style={{ width: '10%' }}> <img src={ic_ordersuccess} alt='icon' /> </Grid>
                            <Grid container direction='column' justify='flex-start' alignItems='flex-start' style={{ width: '80%', marginLeft: '3%' }}>
                                <Typography style={{ color: '#0A0A0A', fontSize: '34px', fontWeight: 700, }}>Hey {this.state.OrderBy},</Typography>
                                <Typography style={{ color: '#0A0A0A', fontSize: '18px', fontWeight: 400, marginTop: '1%' }}>Thank You. Your Subscription has been Activated.</Typography>
                            </Grid>
                        </Grid>
                        <Typography style={{ color: '#177BC9', fontSize: '16px', fontWeight: 700, textAlign: 'start', marginBottom: '1%' }}>ORDER DETAILS</Typography>
                        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                            <Typography style={{ color: '#0A0A0A', fontSize: '16px', fontWeight: 700, textAlign: 'start', marginBottom: '1%' }}>Subscription Date :&nbsp;</Typography>
                            <Typography style={{ color: '#0A0A0A', fontSize: '16px', fontWeight: 400, textAlign: 'start', marginBottom: '1%' }}>{this.state.SubscriptionDate}</Typography>
                        </Grid>
                        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                            <Typography style={{ color: '#0A0A0A', fontSize: '16px', fontWeight: 700, textAlign: 'start', marginBottom: '1%' }}>Subscription No :&nbsp;</Typography>
                            <Typography style={{ color: '#0A0A0A', fontSize: '16px', fontWeight: 400, textAlign: 'start', marginBottom: '1%' }}>{this.state.SubscriptionNo}</Typography>
                        </Grid>
                        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                            <Typography style={{ color: '#0A0A0A', fontSize: '16px', fontWeight: 700, textAlign: 'start', marginBottom: '1%' }}>Cost :&nbsp;</Typography>
                            <Typography style={{ color: '#0A0A0A', fontSize: '16px', fontWeight: 400, textAlign: 'start', marginBottom: '1%' }}>{this.state.Cost}</Typography>
                        </Grid>
                    </div>
                </section>
            </>
        )
    }
}

export default PaymentSuccess;
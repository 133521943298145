import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
// import "./App.css";
import * as AppConstants from "./config/AppConstant"

import Layout from "./hoc/layout/layout.component";
import AuthService from "./shared/services/auth-service";

import Login from "./features/login/container/login";
import VerifySSO from "./features/login/component/sso";
import Home from "./features/home/container/home";
import Clinic from "./features/clinic/container/clinic";
import UserContainer from "./features/user/container/user";
import SetPassword from "./features/login/component/setpassword";
import ContactUs from "../src/features/footer/component/contactus";
import PrivacyPolicy from "../src/features/footer/component/PrivacyPolicy";

import AdminUserContainer from "./features/admin/container/containerUsers/users";
import AdminUsersForm from "./features/admin/component/componentUsers/usersForm";

import CalendarList from "./features/admin/container/calendar/automationCalendar";
import AutomationCalendarForm from "./features/admin/container/calendar/automationCalendarForm";

import CalendarMain from "./shared/components/Calendar/CalendarMain";
import PaymentSuccess from "./shared/components/Calendar/PaymentSuccess";

import Subscriptions from "./features/subscriptions/subscriptions";
import SubscriptionsForm from "./features/subscriptions/subscriptionsForm";

import Subscription from "./features/admin/container/subscription/subscription";
import SubscriptionForm from "./features/admin/container/subscription/subscriptionForm";
import SubscriptionOrders from "./features/admin/container/subscription/subscriptionOrderList";

import CountryContainer from "./features/admin/container/containerCountry/country";
import CountryForm from "./features/admin/component/componentCountry/countryForm";

import PaypalSubscriptionPlanContainer from "./features/admin/container/containerPaypalSubscriptionPlan/paypalsubscriptionplan";
import PaypalSubscriptionPlanForm from "./features/admin/component/componentPaypalSubscriptionPlan/paypalsubscriptionplanForm";

import BrandContainer from "./features/admin/container/containerBrand/brand";
import BrandForm from "./features/admin/component/componentBrand/brandForm";

import ProductContainer from "./features/admin/container/containerProduct/product";
import ProductForm from "./features/admin/container/containerProduct/product-form";

import StateContainer from "./features/admin/container/containerState/state";
import StateForm from "./features/admin/component/componentState/stateForm";

import CityContainer from "./features/admin/container/containerCity/city";
import CityForm from "./features/admin/component/componentCity/cityForm";

import PostcodeContainer from "./features/admin/container/containerPostcode/postcode";
import PostcodeForm from "./features/admin/component/componentPostcode/postcodeForm";

import DepartmentContainer from "./features/admin/container/containerDepartment/department";
import DepartmentForm from "./features/admin/component/componentDepartments/departmentForm";

import BranchContainer from "./features/admin/container/containerBranches/branch";
import BranchForm from "./features/admin/component/componentBranches/branchForm";

import PositionContainer from "./features/admin/container/containerPositions/position";
import PositionForm from "./features/admin/component/componentPositions/positionForm";

import PracticesContainer from "./features/admin/container/containerPractices/practices";
import PracticesForm from "./features/admin/component/componentPractices/practicesForm";

import Admin from "./features/admin/admin";
import UploadExcelSheet from "./features/admin/UploadExcelSheet";
import UploadForm from "./features/admin/component/componentUploadExcelSheet/UploadExcelSheetForm";

import EditProfile from "./features/EditProfile/component/edit-profile";

import ChangePassword from "./features/ChangePassword/component/change-password";

import UserForm from "./features/user/component/userForm";
import Workflow from "./features/workflowConfiguration/container/workflow";
import ClinicRegistrationTemp from "./features/clinic/container/ImportPracticesTemp";
import ClinicRegistration from "./features/clinic/container/ClinicRegistration";

import ThankYouPage from "./features/clinic/component/thank-you";
// import DigitalProduct from "./features/digitalProduct/container/digitalProduct";
import WelcomeScreen from "./features/digitalProduct/component/welcome-screen";
import CampaignDetail from "./features/digitalProduct/component/campaignDetail";
import BudgetCampaignDuration from "./features/digitalProduct/component/budgetDetail";
import LocationSelectionComponent from "./features/digitalProduct/component/location-selection";
import LocationValidationComponent from "./features/digitalProduct/component/location-validation";
// import ResourceContainer from "./features/ecp_resources/container/resource";
import FreeAssets from "./features/ecp_resources/component/free-assets/free-assets";
import ProductSelection from "./features/digitalProduct/component/product-selection";
import DigitalBundle from "./features/digitalProduct/component/welcome-screen";
import SocialAutomation from "./features/digitalProduct/component/social-automation";
import LocationSelectionController from "./features/digitalProduct/container/location-selection";
import BudgetDetailController from "./features/digitalProduct/container/budgetDetail";
import CampaignDetailController from "./features/digitalProduct/container/campaignDetail";
import AdBreak from "./features/adBreaks/container/adBreak";
import Dashboard from "./features/home/container/dashboard";
import DashboardImg from "./features/home/container/dashboardimg";
import GoogleAds from "./features/bioTrue/container/family-campaign/google-ads";
import SocialMedia from "./features/bioTrue/container/family-campaign/social-media";

import DirectMail from "./features/bioTrue/container/family-campaign/direct-mail";
import EmailMarketing from "./features/bioTrue/container/family-campaign/email-marketing";
import CustomPrinting from "./features/bioTrue/container/family-campaign/custom-printing";

import BiotrueController from "./features/bioTrue/container/bioTrue";

import CampaginListing from "./features/campaign/container/campaigns";
import CampaignFormController from "./features/campaign/container/campaign-form";

import AdminImportedPracticesContainer from "./features/admin/container/containerImportedPractices/importedpractices";
import ImportedPracticesForm from "./features/admin/component/componentImportedPractices/importedpracticesForm";

import AdminAreasContainer from "./features/admin/container/containerAreas/Areas";
import AdminAreasForm from "./features/admin/component/componentAreas/AreasForm";

import AdminRegionsContainer from "./features/admin/container/containerRegions/Regions";
import AdminRegionsForm from "./features/admin/component/componentRegions/RegionsForm";

import AdminTerritoriesContainer from "./features/admin/container/containerTerritories/Territories";
import AdminTerritoriesForm from "./features/admin/component/componentTerritories/TerritoriesForm";

import AdminAssetTypesContainer from "./features/campaigns/container/containerAssetTypes/AssetTypes";
import AdminAssetTypesForm from "./features/campaigns/component/componentAssetTypes/AssetTypesForm";

import AdminAssetSizesContainer from "./features/campaigns/container/containerAssetSizes/AssetSizes";
import AdminAssetSizesForm from "./features/campaigns/component/componentAssetSizes/AssetSizesForm";

import Segments from "./features/campaigns/container/containerSegments/Segments";
import SegmentsForm from "./features/campaigns/component/componentSegments/SegmentsForm";

import UploadAsset from './features/campaign/components/upload';
import UploadFreeAsset from './features/campaign/components/uploadAsset';
import Campaigns from "./features/campaigns/campaigns";

import NotificationScreen from "./shared/components/notification/NotificationScreen";
import Cart from "./features/admin/component/componentCart/Cart";
import Order from "./features/admin/component/componentOrder/Order";
import OrderDetail from "./features/admin/component/componentOrder/OrderDetail";
import OrderSuccess from "./features/admin/component/componentOrder/OrderSuccess";
import OrderFail from "./features/admin/component/componentOrder/OrderFail";
import ConfirmReport from "./features/order/componentOrder/ConfirmReport";

import AssetDownloadReport from "./features/admin/component/componentAssetReport/AssetDownloadReport";
import ContestSummaryReport from "./features/admin/component/Reports/ContestSummaryReport/contestSummaryReport";
import RegistrationsReport from "./features/admin/component/Reports/RegistrationsReport/registrationsReport";
import SocialAutomationReport from "./features/admin/component/Reports/SocialAutomationReport/socialAutomationReport";
import CampaignOrdersReport from "./features/admin/component/Reports/CampaignOrdersReport/campaignOrdersReport";
import EmailMarketingReport from "./features/admin/component/Reports/EmailMarketingReport/emailMarketingReport";

import ImportPracticesUsers from './features/admin/container/containerImportPracticesUsers/ImportPracticesUsers'

class App extends Component {

    //     componentDidMount() {
    //         let favicon = document.getElementById("favicon");
    // favicon.href = "https://www.google.com/favicon.ico";
    //     }

    render() {
        const RememberMe = localStorage.getItem("RememberMe")
        if (RememberMe != null) {
            let response = JSON.parse(RememberMe)
            sessionStorage.setItem("Access_Token", response.access_token);
            AuthService.setAuthData(response);
        }

        const isLoggedIn = AuthService.checkLogin();
        if (isLoggedIn) {
            return (
                <Layout>
                    <Switch>
                        <Route path="/dashboard" exact component={Dashboard} />
                        <Route path="/dashboardimg" exact component={DashboardImg} />
                        {AppConstants.GetMainClassName() == "app-main" ?
                            <Route path="/home" exact component={Home} /> :
                            <Route path="/home" exact component={ProductSelection} />}
                        <Route path="/ultra/clinic" exact component={Clinic} />
                        <Route path="/ultra/configure" exact component={Workflow} />
                        <Route path="/contactus" exact component={ContactUs} />
                        <Route path="/privacypolicy" exact component={PrivacyPolicy} />

                        <Route path="/users" exact component={UserContainer} />
                        <Route path="/users/add" exact component={UserForm} />
                        <Route path="/users/edit/:id" exact component={UserForm} />

                        <Route path="/digital-product" exact component={BiotrueController} />
                        <Route path="/digital-product/selection" exact component={ProductSelection} />
                        <Route path="/digital-product/selection/digital-bundle" exact component={DigitalBundle} />
                        <Route path="/digital-product/selection/social-automation" exact component={SocialAutomation} />
                        <Route path="/digital-product/selection/free-assets" exact component={FreeAssets} />
                        <Route path="/digital-product/selection/location-selection" exact component={LocationSelectionController} />
                        <Route path="/digital-product/selection/budget-duration" exact component={BudgetDetailController} />
                        <Route path="/digital-product/selection/campaign" exact component={CampaignDetail} />
                        <Route path="/digital-product/selection/adbreak" exact component={AdBreak} />
                        <Route path="/digital-product/selection/google-ads" exact component={GoogleAds} />
                        <Route path="/digital-product/selection/social-media" exact component={SocialMedia} />
                        <Route path="/digital-product/selection/direct-mail" exact component={DirectMail} />
                        <Route path="/digital-product/selection/email-marketing" exact component={EmailMarketing} />
                        <Route path="/digital-product/selection/custom-printing" exact component={CustomPrinting} />

                        <Route path="/campaigns" exact component={CampaginListing} />
                        <Route path="/campaigns/add" exact component={CampaignFormController} />
                        <Route path="/campaigns/edit/:id" exact component={CampaignFormController} />

                        <Route path="/campaigns/upload-asset/:fieldName/:dimension" exact component={UploadAsset} />
                        <Route path="/campaigns/upload-asset/:fieldName/:dimension" exact component={UploadFreeAsset} />

                        {/* <Route path="/resource/listing" exact component={ResourceContainer} /> */}

                        <Route path="/calendar/automationCalendar" component={CalendarList} />
                        <Route path="/automationcalendar/add" exact component={AutomationCalendarForm} />

                        <Route path="/calendar" exact component={CalendarMain} />
                        <Route path="/paymentSuccess" exact component={PaymentSuccess} />

                        <Route path="/subscriptions" component={Subscriptions} />
                        <Route path="/subscriptions/add" exact component={SubscriptionsForm} />

                        <Route path="/subscription/subscription" component={Subscription} />
                        <Route path="/subscription/add" exact component={SubscriptionForm} />
                        <Route path="/subscription/subscriptionOrders" exact component={SubscriptionOrders} />

                        <Route path="/workflow/clinic" component={Clinic} />

                        <Route path="/changepassword" exact component={ChangePassword} />

                        <Route path="/editprofile" exact component={EditProfile} />

                        <Route path="/admin" exact component={Admin} />

                        <Route path="/campaigns" exact component={Campaigns} />
                        <Route path="/campaigns/AssetTypes" exact component={AdminAssetTypesContainer} />
                        <Route path="/AssetTypes/add" exact component={AdminAssetTypesForm} />
                        <Route path="/AssetTypes/edit/:id" exact component={AdminAssetTypesForm} />
                        <Route path="/campaigns/AssetSizes" exact component={AdminAssetSizesContainer} />
                        <Route path="/AssetSizes/add" exact component={AdminAssetSizesForm} />
                        <Route path="/AssetSizes/edit/:id" exact component={AdminAssetSizesForm} />

                        <Route path="/admin/segments" exact component={Segments} />
                        <Route path="/segments/add" exact component={SegmentsForm} />

                        <Route path="/admin/users/add" exact component={AdminUsersForm} />
                        <Route path="/admin/users/edit/:id" exact component={AdminUsersForm} />
                        <Route path="/users" exact component={UserContainer} />
                        <Route path="/users/add" exact component={UserForm} />
                        <Route path="/users/edit/:id" exact component={UserForm} />
                        <Route path="/clinic/clinic" component={Clinic} />
                        <Route path="/resource/users" component={UserContainer} />

                        <Route path="/clinic/importedpractices" component={AdminImportedPracticesContainer} />

                        <Route path="/resource/areas" component={AdminAreasContainer} />
                        <Route path="/admin/areas/add" exact component={AdminAreasForm} />
                        <Route path="/admin/areas/edit/:id" exact component={AdminAreasForm} />

                        <Route path="/resource/regions" component={AdminRegionsContainer} />
                        <Route path="/admin/regions/add" exact component={AdminRegionsForm} />
                        <Route path="/admin/regions/edit/:id" exact component={AdminRegionsForm} />

                        <Route path="/resource/territories" component={AdminTerritoriesContainer} />
                        <Route path="/admin/territories/add" exact component={AdminTerritoriesForm} />
                        <Route path="/admin/territories/edit/:id" exact component={AdminTerritoriesForm} />

                        <Route path="/admin/users" component={AdminUserContainer} />
                        <Route path="/resource/brand" component={BrandContainer} />
                        <Route path="/resource/product" component={ProductContainer} />

                        <Route path="/clinic/practices" exact component={PracticesContainer} />
                        <Route path="/resource/department" exact component={DepartmentContainer} />
                        <Route path="/resource/branches" exact component={BranchContainer} />
                        <Route path="/resource/positions" exact component={PositionContainer} />
                        <Route path="/admin/uploadexcelsheet" exact component={UploadExcelSheet} />
                        <Route path="/admin/uploadexcelsheet/add" exact component={UploadForm} />

                        {AppConstants.GetMainClassName() == "app-yomi" &&
                            <Route path="/admin/importPracticesUsers" exact component={ImportPracticesUsers} />}

                        <Route path="/admin/importedpractices/add" exact component={ImportedPracticesForm} />
                        <Route path="/admin/importedpractices/edit/:id" exact component={ImportedPracticesForm} />

                        <Route path="/notification" exact component={NotificationScreen} />
                        <Route path="/cart" exact component={Cart} />
                        <Route path="/order" exact component={Order} />
                        <Route path="/ConfirmReport" exact component={ConfirmReport} />
                        <Route path="/orderdetail" exact component={OrderDetail} />
                        <Route path="/ordersuccess" exact component={OrderSuccess} />
                        <Route path="/orderfail" exact component={OrderFail} />

                        <Route path="/assetdownloadreport" exact component={AssetDownloadReport} />

                        <Route path="/contestSummaryReport" exact component={ContestSummaryReport} />
                        <Route path="/registrationsReport" exact component={RegistrationsReport} />
                        <Route path="/socialAutomationReport" exact component={SocialAutomationReport} />
                        <Route path="/campaignOrdersReport" exact component={CampaignOrdersReport} />
                        <Route path="/emailMarketingReport" exact component={EmailMarketingReport} />

                        <Route path="/brand" exact component={BrandContainer} />
                        <Route path="/brand/add" exact component={BrandForm} />
                        <Route path="/brand/edit/:id" exact component={BrandForm} />

                        <Route path="/product" exact component={ProductContainer} />
                        <Route path="/product/add" exact component={ProductForm} />
                        <Route path="/product/edit/:id" exact component={ProductForm} />

                        <Route path="/admin/country" component={CountryContainer} />
                        <Route path="/country" exact component={CountryContainer} />
                        <Route path="/country/add" exact component={CountryForm} />
                        <Route path="/country/edit/:id" exact component={CountryForm} />

                        <Route path="/admin/paypalsubscriptionplan" component={PaypalSubscriptionPlanContainer} />
                        <Route path="/paypalsubscriptionplan" exact component={PaypalSubscriptionPlanContainer} />
                        <Route path="/paypalsubscriptionplan/add" exact component={PaypalSubscriptionPlanForm} />
                        <Route path="/paypalsubscriptionplan/edit/:id" exact component={PaypalSubscriptionPlanForm} />

                        <Route path="/admin/state" exact component={StateContainer} />
                        <Route path="/state" exact component={StateContainer} />
                        <Route path="/state/add" exact component={StateForm} />
                        <Route path="/state/edit/:id" exact component={StateForm} />

                        <Route path="/admin/city" exact component={CityContainer} />
                        <Route path="/city" exact component={CityContainer} />
                        <Route path="/city/add" exact component={CityForm} />
                        <Route path="/city/edit/:id" exact component={CityForm} />

                        <Route path="/admin/postcode" exact component={PostcodeContainer} />
                        <Route path="/postcode" exact component={PostcodeContainer} />
                        <Route path="/postcode/add" exact component={PostcodeForm} />
                        <Route path="/postcode/edit/:id" exact component={PostcodeForm} />

                        <Route path="/practices" exact component={PracticesContainer} />
                        <Route path="/practices/add" exact component={PracticesForm} />
                        <Route path="/practices/edit/:id" exact component={PracticesForm} />

                        <Route path="/department" exact component={DepartmentContainer} />
                        <Route path="/department/add" exact component={DepartmentForm} />
                        <Route path="/department/edit/:id" exact component={DepartmentForm} />

                        <Route path="/branches" exact component={BranchContainer} />
                        <Route path="/branches/add" exact component={BranchForm} />
                        <Route path="/branches/edit/:id" exact component={BranchForm} />

                        <Route path="/positions" exact component={PositionContainer} />
                        <Route path="/positions/add" exact component={PositionForm} />
                        <Route path="/positions/edit/:id" exact component={PositionForm} />

                        <Route path="/thank-you" component={ThankYouPage} />
                        <Route path="/workflow/configure" exact component={Workflow} />
                        {/* <Route path="/digital-product/selection" exact component={DigitalProduct} /> */}
                        <Route path="/digital-product/welcome" exact component={WelcomeScreen} />
                        <Route path="/digital-product/campaignDetail" exact component={CampaignDetailController} />
                        <Route path="/digital-product/budget" exact component={BudgetCampaignDuration} />
                        <Route path="/digital-product/location-selection" exact component={LocationSelectionComponent} />
                        <Route path="/digital-product/location-validation" exact component={LocationValidationComponent} />

                        <Redirect to="/home" from="" />
                    </Switch>
                </Layout>
            );
        }
        return (
            <>
                <Switch>
                    <Route path="/login" component={Login} />
                    {AppConstants.GetMainClassName() == "app-main" && <Route path="/vs" component={Login} />}
                    {AppConstants.GetMainClassName() == "app-main" && <Route path="/vsp" component={Login} />}
                    <Route path="/SSO" component={VerifySSO} />
                    <Route path="/setpassword" exact component={SetPassword} />
                    <Route path="/clinic-registrationTemp" component={ClinicRegistrationTemp} />
                    <Route path="/clinic-registration" component={ClinicRegistration} />
                    <Route path="/thank-you" component={ThankYouPage} />
                    <Redirect to="/login" from="" />
                </Switch>
            </>
        );
    }
}

export default withRouter(App);

/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../../../api/APIController.js';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PageTitle from "../../../../config/pageTitle";
import * as AppConsatnt from "../../../../config/AppConstant";
import PerfectScrollbar from "react-perfect-scrollbar";


import monthlycalendar from '../../../../assets/images/monthlycalendar.svg'
import ic_orders from '../../../../assets/images/ic_orders.svg'
import filtericon from '../../../../assets/images/ic_feather_filter.svg'

import Modal from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';
import SubscriptionForm from './subscriptionForm';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const SubscriptionOrderList = (props) => {

	const [TimeZone, setTimeZone] = React.useState([
		{
			"value": -12,
			"text": "(GMT -12:00) Eniwetok, Kwajalein"
		},
		{
			"value": -11,
			"text": "(GMT -11:00) Midway Island, Samoa"
		},
		{
			"value": -10,
			"text": "(GMT -10:00) Hawaii"
		},
		{
			"value": -9,
			"text": "(GMT -9:00) Alaska"
		},
		{
			"value": -8,
			"text": "(GMT -8:00) Pacific Time (US & Canada)"
		},
		{
			"value": -7,
			"text": "(GMT -7:00) Mountain Time (US & Canada)"
		},
		{
			"value": -6,
			"text": "(GMT -6:00) Central Time (US & Canada), Mexico City"
		},
		{
			"value": -5,
			"text": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"
		},
		{
			"value": -4,
			"text": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"
		},
		{
			"value": -3.5,
			"text": "(GMT -3:30) Newfoundland"
		},
		{
			"value": -3,
			"text": "(GMT -3:00) Brazil, Buenos Aires, Georgetown"
		},
		{
			"value": -2,
			"text": "(GMT -2:00) Mid-Atlantic"
		},
		{
			"value": -1,
			"text": "(GMT -1:00) Azores, Cape Verde Islands"
		},
		{
			"value": 0,
			"text": "(GMT) Western Europe Time, London, Lisbon, Casablanca"
		},
		{
			"value": 1,
			"text": "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris"
		},
		{
			"value": 2,
			"text": "(GMT +2:00) Kaliningrad, South Africa"
		},
		{
			"value": 3,
			"text": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
		},
		{
			"value": 3.5,
			"text": "(GMT +3:30) Tehran"
		},
		{
			"value": 4,
			"text": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
		},
		{
			"value": 4.5,
			"text": "(GMT +4:30) Kabul"
		},
		{
			"value": 5,
			"text": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
		},
		{
			"value": 5.5,
			"text": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi"
		},
		{
			"value": 5.75,
			"text": "(GMT +5:45) Kathmandu"
		},
		{
			"value": 6,
			"text": "(GMT +6:00) Almaty, Dhaka, Colombo"
		},
		{
			"value": 7,
			"text": "(GMT +7:00) Bangkok, Hanoi, Jakarta"
		},
		{
			"value": 8,
			"text": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong"
		},
		{
			"value": 9,
			"text": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
		},
		{
			"value": 9.5,
			"text": "(GMT +9:30) Adelaide, Darwin"
		},
		{
			"value": 10,
			"text": "(GMT +10:00) Eastern Australia, Guam, Vladivostok"
		},
		{
			"value": 11,
			"text": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"
		},
		{
			"value": 12,
			"text": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka"
		}]);

	const headerList = ['action', "Order Date", "Amount", "Payment Status", "Practice", "Address", "Campaign", "Year", "Month"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;

	countryList = list.slice(from, to)
	const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}
	return (
		<>
			<h1><PageTitle Title="View Subscription" /></h1> <div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => {
									return <TableCell style={index == 0 ? { minWidth: "10%" } : index == index.length - 1 ? { width: '10%' } : { width: '80%' }} className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow>
								<TableCell>
									<span><img src={filtericon} alt="delete" /></span>
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Order Date" onChange={(e) => props.applyFilter('{SubscribedAt', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Amount" onChange={(e) => props.applyFilter('PaidAmount', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Payment Status" onChange={(e) => props.applyFilter('SubscriptionStatus', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Practice" onChange={(e) => props.applyFilter('Practice', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Address" onChange={(e) => props.applyFilter('Address', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Campaign" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Year" onChange={(e) => props.applyFilter('StartDate', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Month" onChange={(e) => props.applyFilter('StartDate', e.target.value, 'input')} />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: "95px" }}>
											<span>
												<img src={monthlycalendar} alt="delete" style={{ cursor: 'pointer', }}
													onClick={() => {
														props.history.push({
															pathname: "/calendar",
															state: { calendarIdEdit: obj.AutomationCalendarID, calendarYearEdit: obj.StartDate.split("-")[0], calendarMonthEdit: obj.StartDate.split("-")[1] }
														})
													}} />
											</span>
										</TableCell>
										<TableCell>{obj.SubscribedAt}</TableCell>
										<TableCell>{obj.PaidAmount}</TableCell>
										<TableCell>{obj.SubscriptionStatus}</TableCell>
										<TableCell>{obj.Practice}</TableCell>
										<TableCell>{obj.Address}</TableCell>
										<TableCell>{obj.Name}</TableCell>
										<TableCell>{obj.StartDate.split("-")[0]}</TableCell>
										<TableCell>{obj.StartDate.split("-")[1]}</TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);

};

export default SubscriptionOrderList;

/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './countryForm.css'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import Dropdown from '../../../../shared/components/Dropdown/Dropdown';
import Notification from '../../../../shared/components/notification/notification';

import { CircularProgress } from '@material-ui/core';

const AutomationCalendarForm = props => {
    // calendarIdEdit: obj.ID, calendarCampaignEdit: obj.CampaignName, calendarYearEdit: obj.CalendarYear, calendarMonthEdit: obj.CalendarMonth, calendarCostEdit: obj.Cost
    const initialValues = { campaignId: '', yearId: '', monthId: '', cost: '', paypalSubscriptionPlanId: '' }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [YearData, setYearData] = React.useState([]);
    const [MonthData, setMonthData] = React.useState([{ "ID": 1, "Name": "January" }, { "ID": 2, "Name": "February" }, { "ID": 3, "Name": "March" }, { "ID": 4, "Name": "April" }, { "ID": 5, "Name": "May" }, { "ID": 6, "Name": "June" }, { "ID": 7, "Name": "July" }, { "ID": 8, "Name": "August" }, { "ID": 9, "Name": "September" }, { "ID": 10, "Name": "October" }, { "ID": 11, "Name": "November" }, { "ID": 12, "Name": "December" }]);
    const [PaypalSubscriptionPlanData, setPaypalSubscriptionPlanData] = React.useState([]);
    const [Loading, setLoading] = React.useState(false)
    const [DisabledSelect, setDisabledSelect] = React.useState(false)

    React.useEffect(() => {
        var pairs = [];
        var FullYear = (new Date().getFullYear() - 1);
        for (let i = 0; i < 10; i++) {
            pairs.push({ ID: FullYear + i, Name: FullYear + i });
        }
        setYearData(pairs)

        props.history.location.state.calendarIdEdit != 0 ? wsGetAutomationCalendarByID(props.history.location.state.calendarIdEdit) :
            wsGetPaypalSubscriptionPlanForDropdown()
    }, [props])


    const wsGetPaypalSubscriptionPlanForDropdown = () => {
        APIController.GetPaypalSubscriptionPlanForDropdown()
            .then((response) => {
                if (response.error == null) {
                    setPaypalSubscriptionPlanData(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetAutomationCalendarByID = (ID) => {
        APIController.GetAutomationCalendarByID(ID)
            .then((response) => {
                if (response.error == null) {
                    initialValues.campaignId = response.object.data[0].CampaignID
                    initialValues.yearId = response.object.data[0].CalendarYear
                    initialValues.monthId = response.object.data[0].CalendarMonth
                    initialValues.cost = response.object.data[0].Cost
                    initialValues.paypalSubscriptionPlanId = response.object.data[0].PaypalSubscriptionPlanID

                    setPaypalSubscriptionPlanData(JSON.parse(response.object.data[0].PaypalSubscriptionPlans));
                    setDisabledSelect(true)
                } else {
                    handleNotification(response.error.message)
                }
            })
        // wsGetPaypalSubscriptionPlanForDropdown()
    }

    const wsAddEditAutomationCalendar = (ID, CalendarMonth, CalendarYear, PaypalSubscriptionPlanID) => {
        setIsLoading(true)
        APIController.AddEditAutomationCalendar(ID, CalendarMonth, CalendarYear, PaypalSubscriptionPlanID)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.replace({
                            pathname: '/calendar/automationCalendar',
                            state: { showMessage: response.object.message }
                        })
                    }
                    else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => { handleClearNotification(); }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.calendarIdEdit == 0 ? <h1>Add Automation Calendar</h1> : <h1>Edit Automation Calendar</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateCalendarForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditAutomationCalendar(props.history.location.state.calendarIdEdit, values.monthId, values.yearId, values.paypalSubscriptionPlanId)
                    }} >
                    {({ values, handleSubmit, setFieldValue, errors, touched, }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors && touched.yearId && errors.yearId && errors.yearId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Year</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='yearId'
                                                    disabled={DisabledSelect}
                                                    defaultValue={values.yearId}
                                                    value={values.yearId}
                                                    onChange={(e, index) => { setFieldValue('yearId', e.target.value); }}>
                                                    <option value={''}>Select Year</option>
                                                    {YearData.map((obj, index) => {
                                                        return <option key={index} selected={obj.ID == values.yearId ? obj.ID : obj.Name} value={obj.ID}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.yearId && errors.yearId && errors.yearId !== '' && (<span className='error-msg'>
                                                <ErrorMessage name='yearId' render={(err) => { return err; }} />
                                            </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.monthId && errors.monthId && errors.monthId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Month</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='monthId'
                                                    disabled={DisabledSelect}
                                                    defaultValue={values.monthId}
                                                    value={values.monthId}
                                                    onChange={(e, index) => { setFieldValue('monthId', e.target.value); }}>
                                                    <option value={''}>Select Month</option>
                                                    {MonthData.map((obj, index) => {
                                                        return <option key={index} selected={obj.ID == values.monthId ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.monthId && errors.monthId && errors.monthId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='monthId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.paypalSubscriptionPlanId && errors.paypalSubscriptionPlanId && errors.paypalSubscriptionPlanId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Paypal Subscription Plan</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='paypalSubscriptionPlanId'
                                                    disabled={DisabledSelect}
                                                    defaultValue={values.paypalSubscriptionPlanId}
                                                    value={values.paypalSubscriptionPlanId}
                                                    onChange={(e, index) => { setFieldValue('paypalSubscriptionPlanId', e.target.value); }}>
                                                    <option value={''}>Select Paypal Subscription Plan</option>
                                                    {PaypalSubscriptionPlanData.map((obj, index) => {
                                                        return <option key={index} selected={obj.ID == values.paypalSubscriptionPlanId ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.paypalSubscriptionPlanId && errors.paypalSubscriptionPlanId && errors.paypalSubscriptionPlanId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='paypalSubscriptionPlanId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    {/* <div className={`form_field ${errors && touched.cost && errors.cost && errors.cost !== '' ? 'error' : ''}`}>                                        <div className="field-left">
                                        <label>Cost/Month</label>
                                    </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Cost" name="cost" value={values.cost} maxLength="50" id={'cost'} />
                                            {errors && touched.cost && errors.cost && errors.cost !== '' && (<span className='error-msg'>
                                                <ErrorMessage name='cost' render={(err) => { return err; }} />
                                            </span>)}
                                        </div>
                                    </div> */}
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => { setButtonValue(true) }} />
                                        </div>
                                        {props.history.location.state.calendarIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateCalendarForm = yup.object().shape({
    // campaignId: yup.string().required('Campaign is required'),
    yearId: yup.string().required('Year is required'),
    monthId: yup.string().required('Month is required'),
    paypalSubscriptionPlanId: yup.string().required('paypal Subscription Plan is required'),
});


export default AutomationCalendarForm;
/*eslint-disable eqeqeq*/
import React from 'react';
import forgot_user_ic from '../../../assets/images/forgot-username-ic.svg';
import { Formik, ErrorMessage, Field } from 'formik';
import * as yup from 'yup';
import close_ic from '../../../assets/images/close-ic.svg';
import * as APIController from '../../../../src/api/APIController';
import authService from "../../../shared/services/auth-service";
import Notification from '../../../shared/components/notification/notification';

import { useHistory } from "react-router-dom";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

import TextField from "../../../shared/components/TextField/TextField";

const EditProfile = props => {
    let history = useHistory();

    const [IsLoading, setIsLoading] = React.useState(false);
    const [LoadingData, setLoadingData] = React.useState(true);
    const [ChakeEmail, setChakeEmail] = React.useState(true);

    const initialValues = {
        FirstName: '',
        LastName: '',
        PhoneNo: '',
        EmailAddress: '',
    }
    React.useEffect(() => {
        wsGetMyProfile()
    }, [props])

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }
    // EmailAddress
    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const handleLogout = () => {
        authService.removeAuthData();
        localStorage.clear()
        setTimeout(() => {
            props.closeModal()
            history.push("/login")
        }, 4000)
    };

    const wsGetMyProfile = () => {
        APIController.GetMyProfile()
            .then((response) => {
                if (response.error == null) {
                    if (response.object.data == '') {
                        props.closeModal()
                    } else {
                        initialValues.FirstName = response.object.data[0].FirstName
                        initialValues.LastName = response.object.data[0].LastName
                        initialValues.PhoneNo = response.object.data[0].PhoneNo
                        initialValues.EmailAddress = response.object.data[0].EmailAddress
                        setChakeEmail(response.object.data[0].EmailAddress)
                        setLoadingData(false)
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true)
                }
            })
    }

    const wsEditProfile = (FirstName, LastName, PhoneNo, EmailAddress) => {

        setIsLoading(true)
        APIController.EditProfile(FirstName, LastName, PhoneNo, EmailAddress)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        if (ChakeEmail == EmailAddress) {
                            setTimeout(() => {
                                props.closeModal()
                            }, 4000)
                        } else {
                            handleLogout()
                        }
                    }
                    else {
                        handleNotification(response.object.message, true);
                        setTimeout(() => {
                            props.closeModal()
                        }, 4000)
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true)
                }
            })
    }


    return (
        <div className="popup-box forgot-popup-box">
            <div className="box-heading">
                <img src={forgot_user_ic} alt="icon" />
                <h2 className="h1">Edit Profile</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div className="box-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateChangepwdForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsEditProfile(values.FirstName, values.LastName, values.PhoneNo, values.EmailAddress)
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        errors,
                        touched,
                    }) => (
                        <form onSubmit={handleSubmit} >
                            {LoadingData ?
                                <CircularProgress /> :
                                <div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.FirstName &&
                                            errors.FirstName &&
                                            errors.FirstName !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        {/* <div className="field-left">
                                            <label>First Name</label>
                                        </div> */}
                                        <div className="field-right" style={{ width: '100%' }}>
                                            <Field as={TextField}
                                                error={touched.FirstName &&
                                                    errors.FirstName &&
                                                    errors.FirstName !== "" ? true : false}
                                                label='Practice Contact First Name' type="text" placeholder='Practice Contact First Name' name="FirstName" value={values.FirstName} maxLength="50" />
                                            {errors &&
                                                touched.FirstName &&
                                                errors.FirstName &&
                                                errors.FirstName !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='FirstName'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.LastName &&
                                            errors.LastName &&
                                            errors.LastName !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        {/* <div className="field-left">
                                            <label>Last Name</label>
                                        </div> */}
                                        <div className="field-right" style={{ width: '100%' }}>
                                            <Field as={TextField}
                                                error={touched.LastName &&
                                                    errors.LastName &&
                                                    errors.LastName !== "" ? true : false}
                                                label='Practice Contact Last Name' type="text" placeholder='Practice Contact Last Name' name="LastName" value={values.LastName} maxLength="50" />
                                            {errors &&
                                                touched.LastName &&
                                                errors.LastName &&
                                                errors.LastName !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='LastName'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.EmailAddress &&
                                            errors.EmailAddress &&
                                            errors.EmailAddress !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        {/* <div className="field-left">
                                            <label>Email Address</label>
                                        </div> */}
                                        <div className="field-right" style={{ width: '100%' }}>
                                            <Field as={TextField}
                                                error={touched.EmailAddress &&
                                                    errors.EmailAddress &&
                                                    errors.EmailAddress !== "" ? true : false}
                                                label='Practice Contact Email Address' type="text" placeholder="Practice Contact Email Address" name="EmailAddress" value={values.EmailAddress} maxLength="50" />
                                            {errors &&
                                                touched.EmailAddress &&
                                                errors.EmailAddress &&
                                                errors.EmailAddress !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='EmailAddress'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${errors &&
                                            touched.PhoneNo &&
                                            errors.PhoneNo &&
                                            errors.PhoneNo !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        {/* <div className="field-left">
                                            <label>Phone No</label>
                                        </div> */}
                                        <div className="field-right" style={{ width: '100%' }}>
                                            <Field as={TextField}
                                                error={touched.PhoneNo &&
                                                    errors.PhoneNo &&
                                                    errors.PhoneNo !== "" ? true : false}
                                                label='Practice Contact Phone Number' type="text" placeholder="Practice Contact Phone Number" name="PhoneNo" value={values.PhoneNo} maxLength="10" />
                                            {errors &&
                                                touched.PhoneNo &&
                                                errors.PhoneNo &&
                                                errors.PhoneNo !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='PhoneNo'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className={IsLoading ? 'form-field button-field disable-btn' : 'form-field button-field'}>
                                        <input type="submit" name="edit-profile"
                                            value={IsLoading ? "Updating..." : "Update Profile"} />
                                    </div>
                                </div>
                            }
                        </form>
                    )}
                </Formik>
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div>
    )
}
const validateChangepwdForm = yup.object().shape({
    FirstName: yup.string().required('First Name is required'),
    LastName: yup.string().required('Last Name is required'),
    PhoneNo: yup.string().required('Phone No is required'),
    EmailAddress: yup.string().required('Email Address is required'),
});

export default EditProfile;
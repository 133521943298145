/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from "../../../../api/APIController";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import ModalComponent from "../../../../shared/components/modal/modal";
import close_icon from "../../../../assets/images/gray-close-ic.svg";
import Notification from '../../../../shared/components/notification/notification';
import TabComponent from "../../../../shared/components/tab/tab";
import PerfectScrollbar from "react-perfect-scrollbar";
import MyiFrame from "../../../../features/campaign/components/myiFrame";
import * as AppConstants from "../../../../config/AppConstant";

import ProductTabSelection from '../../../digitalProduct/component/product-tab-selection';

const FreeAssets = (props) => {
    const [CampaignName, setCampaignName] = React.useState(AppConstants.GetLocalStorage('localStorageCampaignName'));
    const [ProductName, setProductName] = React.useState(AppConstants.GetLocalStorage('localStorageProductName'));
    const [BrandName, setBrandName] = React.useState(AppConstants.GetLocalStorage('localStorageBrandName'));
    const [CampaignID, setCampaignID] = React.useState(localStorage.getItem("localStorageCampaignID"));

    const [tab, setTab] = React.useState(0);
    const [selectValue, setSelectValue] = React.useState('Standard');
    const [FreeAssets, setFreeAssets] = React.useState([]); //store free asset type
    const [CampaignFreeAssets, setCampaignFreeAssets] = React.useState([]); //store free assettype Id
    const [Loading, setLoading] = React.useState(false);
    const [downloadloading, setdownloadloading] = React.useState(false);
    const [modal, setmodal] = React.useState(null);
    const [modalDec, setmodalDec] = React.useState(null);

    const [AssetsType, setAssetsType] = React.useState('');

    const [SegmentShow, setSegmentShow] = React.useState(false);
    const [SegmentStandard, setSegmentStandard] = React.useState(false);
    const [SegmentVS, setSegmentVS] = React.useState(false);
    const [SegmentVSP, setSegmentVSP] = React.useState(false);

    const [ChildrenTabs, setChildrenTabs] = React.useState([]);

    const handleTabChange = (index, value) => {
        setTab(index);
        wsGetCampaignFreeAssets(CampaignID, value, AppConstants.GetLocalStorage('localStorageProductID'));
        setSegmentShow(false)
        setSegmentStandard(false)
        setSegmentVS(false)
        setSegmentVSP(false)
    };

    const handleSelectChange = (value) => {
        setSelectValue(value)
    };

    React.useEffect(() => {
        wsGetAssetTypeForDropdown(AppConstants.GetLocalStorage('localStorageProductID'));
        AppConstants.GetLocalStorage('localStorageChildren') != "" && setChildrenTabs(JSON.parse(AppConstants.GetLocalStorage('localStorageChildren')));
    }, [props]);

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => { handleClearNotification(); }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsGetAssetTypeForDropdown = (ProductID) => {
        setLoading(true);
        APIController.GetAssetTypeForDropdown(ProductID).then((response) => {
            setLoading(false);
            if (response.object.data.length) {
                setFreeAssets(response.object.data);
                wsGetCampaignFreeAssets(CampaignID, response.object.data[0].ID, ProductID);
            }
        })
    }

    const wsGetCampaignFreeAssets = (CampaignID, AssetTypeID, ProductID) => {
        let assetsType = [];
        setLoading(true);
        APIController.GetCampaignFreeAssets(CampaignID, AssetTypeID, ProductID).then(
            (response) => {
                if (response.object.status == '0') {
                    setCampaignFreeAssets(response.object.data);
                    response.object.data.length && setSegmentShow(true)
                    response.object.data.map((obj, index) => {
                        if (obj.Segment == "Standard") { setSegmentStandard(true); assetsType.push("Standard") }
                        if (obj.Segment == "Vision Source") { setSegmentVS(true); assetsType.push("Vision Source") }
                        if (obj.Segment == "VSP Premier") { setSegmentVSP(true); assetsType.push("VSP Premier") }
                    })

                    setSelectValue("Standard")

                    const uniqueNames = assetsType.filter((val, id, array) => {
                        return array.indexOf(val) == id;
                    });
                    setAssetsType(uniqueNames)
                }
                setLoading(false);
            }
        );
    };

    const wsDownloadFile = (FileURL, Name, CampaignID) => {

        // var element = document.createElement('a');
        // element.href = FileURL;
        // element.setAttribute('href', 'data:video/mp4;charset=utf-8,' + (FileURL));

        // element.setAttribute('download', Name);

        // element.style.display = 'none';
        // document.body.appendChild(element);

        // element.click();

        // document.body.removeChild(element);

        setdownloadloading(true)
        APIController.DownloadFile(FileURL, Name, CampaignID)
            .then((response) => {
                console.log("response", response)
                setdownloadloading(false)
                AppConstants.LogEvent('Download_Asset', AppConstants.CreateLogEventObject("", BrandName, CampaignName, ProductName, "", "", AppConstants.GetLocalStorage('localStorageAssetTabName'), Name, "", "", "", ""))
            }).catch((err) => console.log(err))
    }
    const CopyClipboard = (Description, Subcategory) => {
        setmodalDec({
            isModalOpen: true,
            content: { Description: Description, Subcategory: Subcategory },
        })
    }

    const CopyToClipboard = (Description, Subcategory) => {
        setmodalDec(null)
        navigator.clipboard.writeText(Description)
        handleNotification("Content Copied to Clipboard");
        AppConstants.LogEvent('Copy Text', AppConstants.CreateLogEventObject("", BrandName, CampaignName, ProductName, "", "", AppConstants.GetLocalStorage('localStorageAssetTabName'), Subcategory, "FreeAsset", "", "", ""))
    }

    return (
        <section className="content-right-main ecp_resource">
            <div className="content-right-heading">{CampaignName + ": " + ProductName}</div>
            {ChildrenTabs.length > 1 &&
                <ProductTabSelection history={props.history} ChildrenTabs={ChildrenTabs} INDEX={props.history.location.state != undefined ? props.history.location.state.Index : 0} />}
            <div className="content-heading-view" style={{ justifyContent: 'flex-end' }}>
                {FreeAssets.length > 1 &&
                    <div style={{ marginTop: '10px', width: '100%' }}>
                        <TabComponent
                            tabs={FreeAssets}
                            setTab={handleTabChange}
                            classes={{ root: "tab-listing" }}
                            activeTab={tab} />
                    </div>}
                {/* {Segments.map((obj, index) => {
                            return <option selected={obj.ID} value={obj.ID} key={index}>{obj.Name}</option>
                        })} */}

                {SegmentShow && AssetsType.length > 1 &&
                    <div className="select-style">
                        <select
                            className="select1"
                            id={'segment'}
                            name="reportType"
                            defaultValue={selectValue}
                            value={selectValue}
                            onChange={(e) => handleSelectChange(e.target.value)}>

                            {SegmentStandard && <option value='Standard'>Standard</option>}
                            {SegmentVS && <option value='Vision Source'>Vision Source</option>}
                            {SegmentVSP && <option value='VSP Premier'>VSP Premier</option>}
                        </select>
                    </div>}

            </div>

            <div className="main_tab_content">
                {Loading ? (
                    <CircularProgress />
                ) : CampaignFreeAssets.length == 0 ? (
                    <div className="center-div">
                        <label>Not currently available for this category</label>
                    </div>
                ) : (
                    CampaignFreeAssets.map((obj, index) => {
                        return (
                            <>{obj.Segment == selectValue &&
                                <div className="content-box free-asset-content-box" key={index}>
                                    <div className="box-image free-asset-image-box" style={{ backgroundImage: `url(${obj.PreviewURL} )` }}>
                                        <div className="button-hover">
                                            <div className="button-field">
                                                <button onClick={() => setmodal({
                                                    isModalOpen: true,
                                                    content: { image: obj.ImageURL, PreviewURL: obj.PreviewURL, AdContent: obj.AdContent },
                                                })}>{AppConstants.ButtonText.Preview}</button>
                                            </div>
                                            {obj.ShowDownload == 1 && <div className={downloadloading ? "button-field disable-btn" : "button-field"}>
                                                <button onClick={(e) => wsDownloadFile(obj.ImageURL, obj.Name, obj.ID)}>{AppConstants.ButtonText.Download}</button>
                                            </div>}
                                            {obj.IsCustomizable && <div className="button-field">
                                                <button onClick={(e) => {
                                                    props.history.push(obj.CustomizePath)
                                                    AppConstants.SetLocalStorage("localStorageFreeAssetID", obj.ID)
                                                    AppConstants.SetLocalStorage("localStorageBudgetDetail", obj.CustomizePath == "/digital-product/selection/location-selection" ? JSON.stringify([{ "budget": 1, "duration": 1 }]) : JSON.stringify([{ "budget": 1, "duration": "" }]))
                                                }}>{AppConstants.ButtonText.Customize}</button>
                                            </div>}
                                            {AppConstants.GetLocalStorage('localStorageShowCopyText') == "1" && obj.Description != "" && obj.Description != null &&
                                                <div className="button-field">
                                                    <button onClick={(e) => CopyClipboard(obj.Description, obj.Name)}>{AppConstants.ButtonText.CopyText}</button>
                                                </div>}
                                            {obj.ExternalURL != "" && obj.ExternalURL != null && obj.ExternalURL != undefined &&
                                                <div className="button-field">
                                                    <button onClick={(e) => window.open(obj.ExternalURL, "_blank")}>{"Visit"}</button>
                                                </div>}
                                        </div>
                                    </div>
                                    {AppConstants.GetMainClassName() == "app-main" ?
                                        <div className="box-content-info" style={{ padding: '5px 5px' }}>
                                            {BrandName != undefined && <p>
                                                {BrandName.split('®')[0]}
                                                {BrandName.match(/®/g) ? <sup>&reg;</sup> : ''}
                                                {BrandName.split('®')[1]}
                                            </p>}
                                            <h4>{obj.Name.replaceAll("<br />", '\n')}</h4>
                                        </div> :
                                        <div className="box-content-info" >
                                            <p>{obj.Name}</p>
                                            <label className="box-lable">{obj.Description.replaceAll(/<[^>]+>/g, '')}</label>
                                        </div>}
                                </div>}
                            </>
                        );
                    })
                )}
            </div>

            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />

            {modal && modal.isModalOpen && (
                <ModalComponent show={modal.isModalOpen} handleClose={() => setmodal(null)}>
                    <div className="popup-wrap">
                        {modal.content.image == null || modal.content.image == undefined || modal.content.image == "" ?
                            <img src={modal.content.PreviewURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} alt="place" />
                            : modal.content.image.split('.').pop() == "zip" && modal.content.PreviewURL == "" ?
                                <div><h5>This is .zip File, Please download and open</h5></div>
                                : modal.content.image.split('.').pop() == "mp4" || modal.content.image.split('.').pop() == "pdf"
                                    ? <MyiFrame isFacbook={true} Height={'500px'} Width={'950px'} src={modal.content.image} /> :
                                    modal.content.image.split('.').pop() == "gif"
                                        ? <img src={modal.content.image + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} alt="place" style={{ height: '550px' }} />
                                        : <img src={modal.content.PreviewURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} alt="place" />
                        }
                        <a title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => setmodal(null)} />
                        </a>
                    </div>
                </ModalComponent>)}

            {modalDec && modalDec.isModalOpen && (
                <ModalComponent show={modalDec.isModalOpen} handleClose={() => setmodalDec(null)}>
                    <div className="popup-wrap" style={{ paddingBottom: '10px', display: 'grid' }}>
                        <div style={{ marginBottom: '10px', padding: '10px', alignSelf: 'center', height: '450px', width: '900px', border: '1px solid #cccccc', justifyContent: 'center', alignItems: 'center', }}>
                            <PerfectScrollbar> <div dangerouslySetInnerHTML={{ __html: modalDec.content.Description.replaceAll("\n", '<br />') }} /> </PerfectScrollbar>
                        </div>
                        <a title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => setmodalDec(null)} />
                        </a>
                        <div style={{ textAlign: 'center' }}>
                            <button className="button-field" onClick={(e) => CopyToClipboard(modalDec.content.Description.replaceAll(/<[^>]+>/g, ''), modalDec.content.Subcategory)}>{AppConstants.ButtonText.CopytoClipboard}</button>
                        </div>
                    </div>
                </ModalComponent>)}
        </section >
    );
};

export default FreeAssets;
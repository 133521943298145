/*eslint-disable eqeqeq*/
import React from 'react';
import Table from "../../../../shared/components/table/table";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import * as APIController from "../../../../api/APIController.js";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import { Typography, Grid, Divider, } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { ReactComponent as Ic_coin } from "../../../../assets/images/coin.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PageTitle from "../../../../config/pageTitle";
import * as AppConsatnt from "../../../../config/AppConstant";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

class OrderDetail extends React.Component {
    state = {
        currentPage: 0,
        count: [],
        rowsPerPage: 25,
        orderNo: 0,
        rowsPerPageOptions: [25, 10, 5],
        list: [],
        order: [],
        from: 0,
        to: 25,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        isLocationExpanded: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
    };

    componentDidMount() {
        this.wsGetOrderDetails(this.props.history.location.state.OrderIDDetails);
    }

    wsGetOrderDetails(ID) {
        this.setState({ progress: true, });
        APIController.GetOrderDetails(ID).then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data.OrderDetails,
                    order: response.object.data.Order,
                    count: response.object.data.length,
                    progress: false,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    onLocationExpand(bool, index) {
        this.setState({
            isLocationExpanded: bool, isLocationExpandedIndex: index
        })
    }

    render() {
        return (
            <>
                <section className='content-right-main'>
                    <PageTitle Title={AppConsatnt.ContactDetais.OrderDetails + this.props.history.location.state.OrderNo} />
                    <div className="table-main-wrap countrys-class">
                        <Table>
                            <TableBody>
                                {this.state.list != undefined && !!this.state.list.length ? this.state.list.map((obj, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                <Grid container direction={'row'} justify={'space-between'} alignItems={'flex-start'} style={{ width: '100%', }}>
                                                    <Grid container direction={'row'} justify={'flex-start'} alignItems={'flex-start'} style={{ width: '5%' }}>
                                                        <img src={obj.ProductImageURL} alt='icon' />
                                                    </Grid>
                                                    <Grid direction='column' style={{ width: '93%', }}>
                                                        <Grid container style={window.innerWidth <= 920 ? { width: '100%', } : { width: '100%' }} direction='row' alignItem='center' justify='space-between'>
                                                            <Grid container style={{ width: '50%' }}>
                                                                <Typography style={{ color: '#0A0A0A', fontSize: '20px' }}>{obj.Product}</Typography>
                                                            </Grid>
                                                            <Grid container style={{ width: '50%' }} justify='flex-end'>
                                                                <Typography style={{ color: '#0A0A0A', fontSize: '20px' }}>{obj.Budget}</Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Typography style={{ color: '#0A0A0A', fontSize: '13px' }}>Campaign : {obj.CampaignName}</Typography>
                                                        <Typography style={{ color: '#0A0A0A', fontSize: '13px' }}>Campaign Budget : {obj.TotalAmount}/month</Typography>
                                                        <Typography style={{ color: '#0A0A0A', fontSize: '13px' }}>Duration : {obj.CampaignDurationInMonths}</Typography>
                                                        <Grid container direction='row' alignItem='center' justify='space-between' style={{ marginTop: '5px' }}>
                                                            <Grid container direction='row' alignItems='center' style={{ width: '50%' }}>
                                                                <Typography style={{ color: '#177BC9', fontSize: '13px', }}>View Art Proof(s)</Typography>
                                                                <ExpandMoreIcon style={{ color: '#177BC9', fontSize: '13px', textAlign: 'center', }} />
                                                            </Grid>
                                                        </Grid>
                                                        {this.state.isLocationExpanded == false ?
                                                            <Grid container alignItems='center' direction='row' style={{ width: '50%' }} onClick={() => this.onLocationExpand(true, index)}>
                                                                <Typography style={{ color: '#177BC9', fontSize: '13px' }}>Show Location Details</Typography>
                                                                <ExpandMoreIcon style={{ color: '#177BC9', fontSize: '13px', textAlign: 'center', }} />
                                                            </Grid> :
                                                            <Grid container direction='row' alignItems='center' style={{ width: '50%' }} onClick={() => this.onLocationExpand(false, index)}>
                                                                <Typography style={{ color: '#177BC9', fontSize: '13px' }}>Show Location Details</Typography>
                                                                <ExpandLessIcon style={{ color: '#177BC9', fontSize: '13px', textAlign: 'center', }} />
                                                            </Grid>}
                                                        {this.state.isLocationExpanded == true && this.state.isLocationExpandedIndex == index &&
                                                            JSON.parse(obj.Locations) != null ? JSON.parse(obj.Locations).map((obj, index) => {
                                                                return (
                                                                    <Grid container direction='row' alignItem='center' style={{ marginTop: '5px' }}>
                                                                        <LocationOnIcon style={{ fontSize: '18px' }} />&nbsp;
                                                                        <Typography style={{ color: '#646464', fontSize: '13px', textAlign: 'center' }}>{obj.Location}</Typography>
                                                                    </Grid>
                                                                );
                                                            })
                                                            : <Grid></Grid>}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    );
                                }) : <TableRow >
                                    <TableCell colSpan={this.state.list != undefined} className="not_found">
                                        {this.state.progress ?
                                            <CircularProgress /> :
                                            <label>Not Currently Available</label>
                                        }
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                        <Divider style={{ width: '100%', height: '1px', borderRadius: '100px', }} orientation="vertical" />
                        {this.state.order != undefined ? this.state.order.map((obj, index) => {
                            return (
                                <Grid container style={{ width: '100%', marginTop: '1%' }} alignItems='center' justify='flex-end'>
                                    {obj.CoOpAmount != "$0.00" ? <>
                                        <Grid container direction='row' style={{ width: '100%', marginTop: '1%' }} alignItems='center' justify='center'>
                                            <Ic_coin />&nbsp;&nbsp;&nbsp;
                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 700 }}>{obj.PaymentMode}</Typography>
                                        </Grid>
                                        {/* {this.setState({orderNo:obj.OrderNo})} */}
                                        <Grid container style={{ width: '100%', marginTop: '0.5%', }} alignItems='flex-start' justify='center'>
                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 400, }}>Visa : XXXX - XXXX - 1234</Typography>
                                        </Grid>
                                        <Divider style={window.innerWidth <= 920 ? { width: '100%', height: '1px', borderRadius: '100px', marginTop: '1%' } : { width: '60%', height: '1px', borderRadius: '100px', marginTop: '1%' }} orientation="vertical" />
                                    </> : <></>}
                                    <Grid container direction='row' style={window.innerWidth <= 920 ? { width: '100%', marginTop: '1%' } : { marginLeft: '43%', marginTop: '1%' }} alignItems='flex-start' justify='space-between'>
                                        <Typography style={{ color: '#0A0A0A', fontSize: '18px', fontWeight: 700, }}>Subtotal :</Typography>
                                        <Typography style={{ color: '#0A0A0A', fontSize: '18px', fontWeight: 700, textAlign: 'end' }}>{obj.TotalAmount}</Typography>
                                    </Grid>
                                    {obj.CoOpAmount != "$0.00" ?
                                        <Grid container direction='row' style={window.innerWidth <= 920 ? { width: '100%', marginTop: '0.5%' } : { marginLeft: '43%', marginTop: '0.5%' }} alignItems='flex-start' justify='space-between'>
                                            <Typography style={{ color: '#0A0A0A', fontSize: '18px', fontWeight: 700, }}>Less Co-Op :</Typography>
                                            <Typography style={{ color: '#0A0A0A', fontSize: '18px', fontWeight: 700, textAlign: 'end' }}>- {obj.CoOpAmount}</Typography>
                                        </Grid> : <></>}

                                    <Divider style={window.innerWidth <= 920 ? { width: '100%', height: '1px', borderRadius: '100px', marginTop: '1%' } : { width: '60%', height: '1px', borderRadius: '100px', marginTop: '1%' }} orientation="vertical" />
                                    <Grid container direction='row' style={window.innerWidth <= 920 ? { width: '100%', marginTop: '1%' } : { marginLeft: '43%', marginTop: '1%' }} alignItems='flex-start' justify='space-between'>
                                        <Typography style={{ color: '#177BC9', fontSize: '18px', fontWeight: 700, }}>Gross Total :</Typography>
                                        <Typography style={{ color: '#177BC9', fontSize: '18px', fontWeight: 700, textAlign: 'end' }}>{obj.NetAmount}</Typography>
                                    </Grid>
                                </Grid>
                            );
                        })
                            : <Grid></Grid>}
                    </div>
                </section>
            </>
        )
    }
}

export default OrderDetail;